@import '../../../resources/theme/Common.scss';

.errMsgModal-main-container {
    width: 100%;
    max-width: 686px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    position: relative;
    height: 100%;
    justify-content: center;
}

.errMsgModal-container {
    width: 100%;
    background: $dialog-bg;
    border: $dialog-border;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 32px 50px;
    gap: 11px;
    width: 468px;
}

.errMsgModal-content-container {
    max-height: 30vh;
    overflow: auto;
    display: flex;
    flex-direction: column;
}

.errMsgModal-title {
    font-size: 32px;
    font-weight: 700;
    line-height: 39px;
    text-align: center;
    margin-bottom: 8px;
    color: $dialog-title-txt;
    word-wrap: break-word;
    white-space: wrap;
}
  
.errMsgModal-content {
    padding-bottom: 32px;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: $dialog-content-txt;
}
  
.errMsgModal-btn-container {
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
}
  
.errMsgModal-btn {
    height: 48px;
    cursor: pointer;
}
  
.errMsgModal-btn-txt {
    color: $dialog-btn-txt;
}

.errMsgModal-error-bg-btn {
    background: $dialog-err;
}