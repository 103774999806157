@import '../../resources/theme/Common.scss';

.forgotPassword-container {
    width: 100%;
    padding-bottom: 32px;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
}

.forgotPassword-style {
    flex-direction: column;
    width: 100%;
    max-width: 468px;
    justify-content: flex-start;
    display: flex;
    flex-direction: column;
}

.forgotPassword-title-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 49px;
    padding-bottom: 39px;
    gap: 39px;
}
  
.forgotPassword-title {
    color: $verifyMobile-title;
    font-family: $fontFamily;
    font-size: $fontSize24;
    font-weight: $fontWeight700;
}

.forgotPassword-form-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
}


.forgotPassword-btn-section-container {
    display: flex;
    flex-direction: column;
    padding-top: 32px;
    gap: 16px;
}
