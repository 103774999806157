@import '../../../resources/theme/Common.scss';

.contactus-container {
    
}

    .contactus-title {
        color: #FFF;
        font-family: $fontFamily;
        font-size: $fontSize16;
        font-style: $fontStyleNormal;
        font-weight: $fontWeight700;
        line-height: $fontStyleNormal;
        margin: 0px 0px 8px 17px;
        opacity: 0.5;
    }

    .contactus-icons-container {
        // display: flex;
        // align-items: flex-start;
        // justify-content: space-evenly;
        // flex-wrap: wrap;
        // background: $game-container-bg;
        // border-radius: 12px;
        display: grid;
        grid-template-columns: repeat(5, minmax(42px,84px)); // set 5col x 1row grid layout
        align-items: center;
        justify-content: space-evenly;
        background: $game-container-bg;
        border-radius: 12px;

        /* small device */
        @media screen and (max-width: 430px), screen and (max-height: 500px) {
            grid-template-columns: repeat(3, minmax(42px,84px));
        }
    }

    .contactus-icons-container img {
        padding: 15px 22px 15px 22px;
    }




