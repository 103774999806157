
@import '../../../resources/theme/Common.scss';

.back-nav-container {
    padding: 32px 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    width: 100%;
    max-width: 468px;
    background: $back-nav-bg;
}

//reuseable class
.back-nav-title {
    flex: 1;
    color: $backNav-title;
    text-align: center;
    font-family: $fontFamily;
    font-size: $fontSize24;
    font-weight: $fontWeight700;
}