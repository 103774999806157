@import '../../../resources/theme/Common.scss';

.pinTextField-container {
    display: flex;
    flex-direction: column;
}

.pinTextField-input-container {
    display: flex;
    flex-direction: row;
    gap: 8px;
}

.pinTextField-err-msg {
    color: $pinTextField-err-txt;
    font-family: $fontFamily;
    font-size: $fontSize12;
    font-weight: $fontWeight400;
    line-height: normal;
}

//PinItem
.pinItem-container {
    border-radius: 10px;
    background: $pinItem-bg;
    padding: 17px 0px;
    border: $pinItem-normal-border;
}

.pinItem-error-container {
    border: $pinItem-err-border;
}

.pinItem-focus-container {
    border: $pinItem-focus-border;
}

.pinItem-input {
    background: transparent;
    font-family: $fontFamily;
    font-size: $fontSize20;
    font-weight: $fontWeight700;
    color: $pinItem-txt;
    text-align: center;
    border: none;
    padding: 0;
    margin: 0;
    width: 100%;
}