@import '../../../resources/theme/Common.scss';
:root {
    --game-card-height: 150px;
}

.game-card-perspective-container {
    perspective: 500px;
}

.flipped-game-container {
    transform: rotateY(180deg);
}

.game-card-main-container {
    position: relative;
    height: var(--game-card-height);
    transform-style: preserve-3d;
    transition: transform 0.5s;
}

.game-info-img {
    box-shadow: $game-info-shadow;
}

.game-item-container {
    overflow: hidden;
    box-shadow: $game-provider-btn-shadow;
    border-radius: 10px;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
    backface-visibility: hidden;
}

.game-item-image {
    width: 100%;
    height: 100%;
}

.game-info-close-img, .game-info-img {
    transform: translateZ(0); //for Hardware Acceleration, if not in safari will overlap by background
    position: absolute;
    right: 4px;
    top: 4px;
    height: 30px;
    width: 30px;
    object-fit: fill;
    border-radius: 50%;
}

.game-info-close-img {
    box-shadow: $game-flipped-close-shadow;
}

.game-info-container {
    padding: 7px;
    background: $game-flipped-bg;
    box-shadow: $game-provider-btn-shadow;
    border-radius: 10px;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
    backface-visibility: hidden;
    transform: rotateY(180deg);
    overflow: hidden;
}

.game-info-scrollable-container {
    width: 100%;
    height: 100%;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    gap: 12px;
    background: $game-flipped-bg; //if remove this background, using mouse is unable to scroll still donno why
}

.game-info-scrollable-container::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

.game-info-small-logo {
    width: 41px;
    height: 48px;
    min-height: 48px;
    object-fit: contain;
    border-radius: 6px;
    overflow: hidden;
    box-shadow: $game-flipped-small-logo-shadow;
}

.game-info-display-container {
    display: flex;
    flex-direction: column;
    gap: 4px;
}