@import '../../../resources/theme/Common.scss';

.introDownloadApp-container {
    gap: 44px;
    max-width: 686px;
    width: 100%;
    display: flex;
    align-items: flex-end;
    position: relative;
  }
  
  .introDownloadApp-background-container {
    background: $introDownloadApp-background;
    width: 100%;
    height: 80%;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    border-radius: 10px;
    z-index: -1;
  }
  
  .introDownloadApp-image-container {
    width: 40%;
  }
  
  .introDownloadApp-image {
    width: 100%;
    object-fit: contain;
  }
  
  .introDownloadApp-content {
    flex: 1;
    word-break: break-all;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 18px 16px 18px 0px;
  }
  
  .introDownloadApp-description {
    color: $introDownloadApp-description-txt;
    font-family: $fontFamily;
    font-size: $fontSize16;
    font-weight: $fontWeight700;
  }

  .introDownloadApp-btn {
    background: $introDownloadApp-btn;
    box-shadow: $introDownloadApp-btn-shadow;
    display: flex;
    flex-direction: row;
    gap: 15px;
    padding: 11px 8px;
    border-radius: 10px;
    align-items:  center;
  }

  .introDownloadApp-btn-leftImg {
    width: 49px;
    height: 16px;
  }

  .introDownloadApp-btn-txt {
    color: $introDownloadApp-btn-txt;
    font-family: $fontFamily;
    font-size: $fontSize16;
    font-weight: $fontWeight700;
    text-align: center;
  }