@import '../../../resources/theme/Common.scss';

.gamesLog-item-container {
    padding: 15px 16px;
    display: flex;
    flex-direction: row;
    gap: 8px;
    border-radius: 10px;
    justify-content: space-between;
    width: 100%;
    word-break: break-all;
}

.gamesLog-item-container-odd-theme {
    background: $gamesLog-item-odd-bg;
}

.gamesLog-item-container-even-theme {
    background: $gamesLog-item-even-bg;
}

.gamesLog-item-left-container {
    display: flex;
    flex-direction: column;
    gap: 3px;
}

.gamesLog-item-right-container {
    display: flex;
    flex-direction: column;
    gap: 3px;
}

.gamesLog-item-title {
    color: $gamesLog-title-txt;
    font-family: $fontFamily;
    font-size: $fontSize16;
    font-weight: $fontWeight700;
}

.gamesLog-item-date {
    color: $gamesLog-date-txt;
    font-family: $fontFamily;
    font-size: $fontSize16;
    font-weight: 400;
}

.gamesLog-item-turnover-container {
    display: flex;
    flex-direction: row;
    gap: 9px;
}

.gamesLog-item-turnover-title {
    color: $gamesLog-turnover-title;
    font-family: $fontFamily;
    font-size: $fontSize16;
    font-weight: 400;
}

.gamesLog-item-turnover-value {
    color: $gamesLog-turnover-txt;
    font-family: $fontFamily;
    font-size: $fontSize16;
    font-weight: 400;
}

.gamesLog-item-winlost-container {
    display: flex;
    flex-direction: row;
    gap: 9px;
}

.gamesLog-item-winlost-title {
    color: $gamesLog-winlost-title;
    font-family: $fontFamily;
    font-size: $fontSize16;
    font-weight: 400;
}

.gamesLog-item-winlost-value {
    color: $gamesLog-winlost-txt;
    font-family: $fontFamily;
    font-size: $fontSize16;
    font-weight: 400;
}

.gamesLog-item-status {
    font-family: $fontFamily;
    font-size: $fontSize16;
    font-weight: 400;
    text-align: right;
}

.gamesLog-item-status-success {
    color: $gamesLog-status-success-txt;
}

.gamesLog-item-status-progress {
    color: $gamesLog-status-progress-txt;
}

.gamesLog-item-status-fail {
    color: $gamesLog-status-fail-txt;
}