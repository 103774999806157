@import '../../resources/theme/Common.scss';

//create variable
$wallet-container-horizontal-padding: 6px;
$wallet-icon-width: 19.7px;
$wallet-icon-height: 20px;

.wallet-balance-container {
    display: flex;
    flex-direction: row;
    width: 144px;
    background: $wallet-bg;
    gap: 6px;
    justify-content: space-between;
    padding: 11px $wallet-container-horizontal-padding;
    align-items: center;
    text-align: center;
    box-shadow: $wallet-shadow;
    border-radius: 6px;
    overflow: hidden;
    border: $wallet-border;
}

.wallet-balance-img {
    height: $wallet-icon-width;
    width: $wallet-icon-height;
    object-fit: contain;
}

.wallet-balance-text {
    font-family: $fontFamily;
    font-style: $fontStyleNormal;
    font-weight: $fontWeight700;
    font-size: $fontSize12;
    color: $theme-txt;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.wallet-signin-text {
    font-family: $fontFamily;
    font-style: $fontStyleNormal;
    font-weight: $fontWeight700;
    font-size: $fontSize12;
    color: $theme-txt;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}


@media screen and (max-width: 415px) {
    .wallet-balance-container {
        width: 100px;
    }
}