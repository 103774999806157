
@import '../../../resources/theme/Common.scss';

.login-component-main-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;

    .login-component-login-container {
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: 8px;

        .login-component-register-btn {
            flex: 1;
            background: $login-component-register-btn;
            box-shadow: $login-component-register-shadow;
            padding: 11px 11px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 10px;
            border: $login-component-register-border;
            color: $login-component-register-txt;
            font-family: $fontFamily;
            font-size: $fontSize16;
            font-weight: $fontWeight700;
        }
    
        .login-component-login-btn {
            flex: 1;
            background: $login-component-login-btn;
            box-shadow: $login-component-login-shadow;
            padding: 11px 11px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 10px;
            border: $login-component-login-border;
            color: $login-component-login-txt;
            font-family: $fontFamily;
            font-size: $fontSize16;
            font-weight: $fontWeight700;
        }
    }
    
    .login-component-separator-container {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;

        .login-component-separator {
            width: 108px;
            height: 1px;
            background: $login-component-separator;
        }

        .login-component-separator-txt {
            color: $login-component-separator-txt;
            font-family: $fontFamily;
            font-size: $fontSize12;
            font-weight: $fontWeight400;
        }
    }
    .login-component-third-login-container {
        width: 100%;

        .login-component-line-container {
            display: flex;
            padding: 11px 11px;
            background: $login-component-line-img-bg;
        }

        .login-component-line-btn-img {
            width: 20px;
            height: 20px;
        }

        .login-component-line-btn{
            flex: 1;
            background: $login-component-line-btn;
            overflow: hidden;
            box-shadow: $login-component-line-shadow;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 10px;
            border-radius: 10px;
            border: $login-component-line-border;
        }

        .login-component-line-btn-txt {
            flex: 1;
            text-align: center;
            color: $login-component-line-txt;
            font-family: $fontFamily;
            font-size: $fontSize16;
            font-weight: $fontWeight700;
        }
    }

}