@import '../../../resources/theme/Common.scss';

.popUpItem-container {
    background: $pop-up-bg;
    display: flex;
    flex-direction: column;
    gap: 11px;
    border-radius: 10px;   
    padding: 11px 16px;
}

.popUpItem-item-container {
    display: flex;
    flex-direction: column;
    gap: 11px;
}

.popUpItem-item-txt {
    color: $pop-up-txt;
    text-align: center;
    font-family: $fontFamily;
    font-size: $fontSize16;
    font-weight: $fontWeight700;
}

.popUpItem-item-separator {
    background: $pop-up-separator-bg;
    height: 1px;
}