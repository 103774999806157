@import '../../../resources/theme/Common.scss';

    .license-certification-title {
        color: $licenseCert-title;
        font-family: $fontFamily;
        font-size: $fontSize16;
        font-style: $fontStyleNormal;
        font-weight: $fontWeight700;
        line-height: $fontStyleNormal;
        padding: 0px 0px 0px 5px;
        opacity: 0.5;
    }
    .license-certification-icons {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
    }
        .license-certification-gc-icon {
            height: 64px; 
            flex-shrink: 0;
            object-fit: contain;
        }
        .license-certification-col {
            // justify-content: center;
            // align-items: center;
            text-align: center;
        }
            .license-certification-authentic-games-subtitle {
                color: $licenseCertAuth-title;
                font-family: $fontFamily;
                font-size: $fontSize12;
                font-style: $fontStyleNormal;
                font-weight: $fontWeight700;
                line-height: $fontStyleNormal;
                padding-bottom: 7px;
                opacity : 0.5;
            }


@media screen and (max-width: 430px) {
    .license-certification-gc-icon {
        height: 48px;
        object-fit: contain;
    }
}

@media screen and (max-width: 364px) {
    .license-certification-gc-icon {
        height: 42px;
        object-fit: contain;
    }
}