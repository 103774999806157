@import '../../../resources/theme/Common.scss';

.game-info-item-container {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    gap: 8px;
}

.game-info-item-title {
    color: $game-flipped-title-txt;
    font-family: $fontFamily;
    font-size: 9px;
    font-weight: $fontWeight700;
    line-height: 95%;
    flex: 0 0 40%;
    word-break: break-word;
}

.game-info-item-separator {
    width: 1px;
    background: $game-flipped-separator-bg;
    flex: 0 0 1px;
}

.game-info-item-value {
    color: $game-flipped-value-txt;
    font-family: $fontFamily;
    font-size: 9px;
    font-weight: 500;
    line-height: 95%;
    flex: 1;
    word-break: break-word;
}

