@import '../../../resources/theme/Common.scss';

.tab-item-container {
    background: $tab-item-bg;
    border-radius: 10px;
    padding: 13px;
    cursor: pointer;
}
//tab-item-container: other classes
.loginMain-tab-item-container {
    background: transparent;
    padding: 17px 13px;
}

.tab-item-container-selected {
    background: $tab-item-selected-bg;
    box-shadow: $tab-item-selected-shadow;
}
//tab-item-container-selected: other classes
.loginMain-tab-item-container-selected {
    box-shadow: none;
}

.tab-item-txt {
    color: $tab-item-txt;
    text-align: center;
    font-family: $fontFamily;
    font-size: $fontSize12;
    font-weight: $fontWeight700;
}
//tab-item-txt: other classes
.loginMain-tab-item-txt {
    font-size: $fontSize20;
    font-weight: $fontWeight700;
    line-height: normal;
}

.tab-item-txt-selected {
    color: $tab-item-selected-txt
}