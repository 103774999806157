
@import '../../../resources/theme/Common.scss';

.language-item-container {
    display: flex;
    flex-direction: row;
    gap: 8px;
} 

.language-item-icon-container {
    height: 42px;
    width: 42px;
    border-radius: 50%;
    border: $language-flag-light-border;
    box-shadow: $language-flag-shadow;
    overflow: hidden;
}

.language-item-icon {
    height: 42px;
    width: 42px;
    object-fit: contain;
}

.language-item-btn {
    background: $language-btn-bg;
    border-radius: 10px;
    width: 92px;
    padding: 11px;
}

.language-item-btn-selected {
    background: $language-btn-bg-selected;
}

.language-item-btn-txt {
    text-align: center;
    color: $language-btn-txt;
    font-family: $fontFamily;
    font-size: $fontSize16;
    font-weight: $fontWeight700;
    overflow: hidden;
    text-overflow: ellipsis;
}

.language-item-btn-txt-selected {
    color: $language-btn-txt-selected;
}