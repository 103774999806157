@import '../../../resources/theme/Common.scss';

.changePasswordTextField-container {
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
}

.changePasswordTextField-input-container {
  border-bottom: 1px solid $signIn-underline;
  padding-top: 10px;
  height: 35px;
}

.changePasswordTextField-input {
    color: black;
  }
  
  .changePasswordTextField-placeholder {
    color: grey;
    font-weight: 350;
}

.changePasswordTextField-icon {
  width: 21px;
  height: 24px;
  object-fit: contain;
}
.changePasswordTextField-error-input-container {
  border: $loginCommonTf-err-border;
}

.changePasswordTextField-hide-show-img-container {
  display: flex;
}

.changePasswordTextField-hide-show-img {
  height: 24px;
  width: 32px;
  object-fit: contain;
}

.changePasswordTextField-error-msg {
  color: $loginCommonTf-err-color;
  font-size: $fontSize12;
  font-family: $fontFamily;
  font-weight: $fontWeight400;
  line-height: normal;
}
