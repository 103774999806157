@import '../../resources/theme/Common.scss';

.footer-style {
    height: 52px;
    width: 52px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    padding: 6px;
}

.footer-selected-style {
  background: $footer-selected-item-bg;
  box-shadow: $footer-selected-item-shadow;
}

.footer-txt {
    font-weight: 700;
    font-size: 11px;
    line-height: 13px;
    margin-top: 3px;
    text-align: center;
    color: $footer-item-txt;
}

.footer-selected-txt {
  color: $footer-item-selected-txt;
}
  
.footer-icon {
    height: 27.4px;
    width: 27.4px;
    object-fit: fill;
}

.invite-angpao {
    width: 100px;
    height: 120px;
    position: absolute;
    top: -30px;
    display: flex;
    background-image: url('../../resources/image/asset/promo/invite/angpao.png');
    background-size: 100% 100%;
}
  
  .invite-angpao-time {
    background: black;
    border: 1px solid white;
    font-size: 8px;
    border-radius: 10px;
    padding: 0 3px 0 3px;
    position: absolute;
    left: 27%;
    bottom: 25px;
    display: none;
}

/* small device */
@media screen and (max-width: 430px), screen and (max-height: 500px) {
  
    .invite-angpao {
      width: 80px;
      height: 90px;
      top: -10px;
    }
  
    .invite-angpao-time {
      font-size: 7px;
      padding: 0 3px 0 3px;
      left: 25%;
    }
}