
@import '../../../resources/theme/Common.scss';

.side-menu-item-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    background: $sideMenuItem-bg;
    box-shadow: $sideMenuItem-shadow;
    border: $sideMenuItem-border;
    border-radius: 10px;
    padding: 16px 59px;
} 

.side-menu-item-horizontal-container {
    display: flex;
    flex-direction: row;
    gap: 30px;
    justify-content: space-between;
    align-items: center;
}

.side-menu-item-left-section {
    display: flex;
    flex-direction: row;
    gap: 30px;
    align-items: center;
}

.side-menu-item-icon {
    width: 24px;
    height: 24px;
    object-fit: contain;
}

.side-menu-item-title {
    color: $side-menu-item-txt;
    font-family: $fontFamily;
    font-size: $fontSize20;
    font-weight: $fontWeight700;
}

.side-menu-verified-icon {
    width: 24px;
    height: 24px;
    object-fit: contain;
}