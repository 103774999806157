@import '../../resources/theme/Common.scss';

.signUp-title {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: $signUp-title;
}

.signUp-verify-mobile-logo {
  height: 55.56px;
  width: 62.01;
  margin: 48.01px 0 42.98px 0;
}

.signUp-tips {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: $signUp-tips;
  margin-bottom: 14px;
}

.signUp-mobile {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: $signUp-mobile;
  margin-bottom: 52px;
}

.signUp-otp-container {
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 23.5px;
  width: 100%;
}

.signUp-prefix {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: $signUp-prefix;
  margin-right: 6.3px;
}

.signUp-otp-group {
  display: flex;
  width: 100%;
  max-width: 360px;
  justify-content: space-between;
}

.signUp-otp-input {
  width: 25px !important;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  border-color: transparent;
  border-bottom: 1px solid $signIn-underline;
  background: transparent;
}

.signUp-timer {
  flex-direction: row;
  display: flex;
  margin-bottom: 20px;
}

.signUp-countdown {
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  color: $signUp-timer;
}

.signUp-verify-btn {
  margin: 0 0 20px 0;
  height: 35px;
}

.signUp-verify-btn-bg {
  background: $signUp-verify-btn-bg;
  box-shadow: $signUp-verify-btn-shadow;
}

.signUp-timer-btn {
  margin: 0 0 53px 0;
  height: 35px;
  box-shadow: $signUp-resend-btn-shadow;
}

.signUp-resend-btn {
  background: $signUp-resend-btn;
}

.signUp-step-icon {
  height: 45px;
  width: 160px;
  margin: 20px 0 20px 0;
}

/* small device */
@media screen and (max-width: 430px), screen and (max-height: 500px) {
  .signUp-title {
    margin-top: 30px;
  }
}

/* landscape*/
@media screen and (max-height: 500px) {
}
