@import '../../resources/theme/Common.scss';

.registerBank-container {
  width: 100%;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  padding-bottom: 32px;
}

.registerBank-style {
  flex-direction: column;
  width: 100%;
  max-width: 468px;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
}

.registerBank-title-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 49px;
  padding-bottom: 21px;
  gap: 39px;
}

.registerBank-title {
  color: $verifyMobile-title;
  font-family: $fontFamily;
  font-size: $fontSize24;
  font-weight: $fontWeight700;
}

.registerBank-step-img {
  height: 32px;
  object-fit: contain;
}

.registerBank-form-container {
    padding-bottom: 32px;
}

.registerBank-form-layout {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.registerBank-separator-container {
    display: flex;
    flex-direction: row;
    padding: 8px 50px;
}

.registerBank-separator {
    background: $registerBank-separator;
    height: 1px;
    width: 100%;
}