@import '../../../resources/theme/Common.scss';

.introAffiliate-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.introAffiliate-btn {
    padding: 11px;
    width: 92px;
    border-radius: 6px;
    justify-content: center;
    background: $introAffiliate-more-btn;
    color: $introAffiliate-more-btn-txt;
    font-size: $fontSize16;
    font-style: $fontStyleNormal;
    font-weight: $fontWeight700;
    line-height: $fontStyleNormal;
    box-shadow: $introAffiliate-more-btn-shadow;
    border: $introAffiliate-more-btn-border;
}

.introAffiliate-img {
    width: 100%;
    object-fit: contain;
}

.introAffiliate-items-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 0px 16px;
}