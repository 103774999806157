:root {
  // Common
  --pagePadding: 10px 20px 10px 20px;
  --main-border-size: 1px;
  --main-border: var(--main-border-size) solid #707070;
  --main-shadow: 4px 4px 4px rgba(0, 0, 0, 0.75);
  --gray-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  --border: #707070;
  --main-radius: 10px;
  --strong: #ff0000;
  --transparent: rgba(0, 0, 0, 0.5);

  //banner slider
  --banner-indicator-active: white;
  --banner-indicator-inactive: gray;

  //background
  --main-bg: #1c2131;
  --wallet-bg: #ffffff;
  --sub-bg: #000000;
  --main-modal-bg: rgba(0, 0, 0, 0.25);
  --bottom-modal-bg: rgba(255, 255, 255, 0.8);
  --main-inpage-modal-bg: 'transparent';
  --bank-bg: #ffffff;
  --main-bottom-shadow: linear-gradient(
    180deg,
    rgba(28, 33, 49, 0) 0%,
    var(--main-bg) 24.22%
  );
  --new-sub-bg: #141723;

  //text
  --fontFamily: 'Helvetica Neue';
  --fontWeight700: 700;
  --fontWeight400: 400;
  --fontStyleNormal: normal;
  --fontSize10: 10px;
  --fontSize12: 12px;
  --fontSize14: 14px;
  --fontSize16: 16px;
  --fontSize20: 20px;
  --fontSize24: 24px;
  --fontSize30: 30px;
  --fontHeight15: 15px;
  --fontHeight20: 20px;
  --main-txt: #ffffff;
  --sub-txt: #000000;
  --theme-txt: #171b28;
  --main-placeholder: #707070;
  --tips-txt: #ef6969;
  --light-gray-txt: #c4c5c8;
  --gold-txt: #fad071;
  --provider-banner-txt: rgba(255, 255, 255, 0.35);

  // tab
  --tab-txt-selected: #ffffff;
  --tab-txt-unSelected: #707070;
  --tab-selected-bg: linear-gradient(to right, #d3367e, #f7bb6a);
  --form-radius: 30px;
  --form-border: var(--main-border);
  --form-shadow: var(--main-shadow);
  --form-bg: #161a23;
  --form-label: var(--main-txt);
  --form-item-bg: var(--main-bg);
  --form-item-radius: 30px;
  --form-item-border: var(--main-border);
  --form-item-shadow: var(--main-shadow);

  //#endregion

  //#region =====> Component

  // Fortunel Wheel
  --wheel-dialog-bg: #141723;
  --wheel-dialog-border: var(--main-border);
  --wheel-dialog-title: #ffe045;
  --wheel-dialog-desc: var(--main-txt);
  --wheel-dialog-total: #fdc46f;
  --wheel-info: var(--main-txt);
  --wheel-info-check: var(--main-txt);
  --wheel-cancel-bg: #161a23;
  --wheel-cancel-txt: #FFFFFF;

  // Permanent Angpao
  --permanent-bg: rgba(0, 0, 0, 0.1);
  --permanent-amt: #fad105;

  // Invite Angpao
  --invite-bg: rgb(26, 26, 26);
  --invite-numbering: #fad105;
  --invite-receive: #9c0000;
  --invite-dialog-bg: #9a0100;
  --invite-dialog-border: 1px solid #fdc46f;
  --invite-dialog-title: #fdc46f;
  --invite-dialog-desc: #fdc46f;
  --invite-dialog-checkTime: #fdc46f;
  --invite-dialog-time: #fdc46f;

  //Timer
  --timer-txt: #000000;

  //Header
  --header-bg: #141723;
  --header-shadow: 0px 4px 4px #141723;
  --header-txt: var(--main-txt);

  //CPPin
  --cppin-field-border: 1px solid #1c2131;
  --cppin-field-active-border: 1px solid #1c2131;
  --cppin-field-bg: transparent;
  --cppin-active-field: #1c2131;
  --cppin-btn-bg: #1c2131;
  --cppin-shadow: var(--main-shadow);
  --cppin-btn-cursor: #ffffff;
  --cppin-btn-txt: #ffffff;

  //CPLang
  --cplang-bg: rgba(255, 255, 255, 0.6);

  // CPBottomModal
  --bottomModal-container-bg: var(--transparent);
  --bottomModal-black-bg: rgba(255, 255, 255, 0.25);

  // CPDropdown
  --dropdown-bg: var(--header-bg);
  --dropdown-shadow: var(--main-shadow);
  --dropdown-border: var(--main-border);
  --dropdown-inner-bg: #ffffff;
  --dropdown-inner-shadow: var(--main-shadow);
  --dropdown-inner-label-container-bg: #ffffff;
  --dropdown-right-label: black;
  --dropdown-list-bg: black;
  --dropdown-list-item-bg: #ffffff;
  --dropdown-list-item-shadow: var(--main-shadow);
  --dropdown-list-item-txt: black;
  --dropdown-placeholder: rgba(255, 255, 255, 0.5);

  // CPButton
  --btn-main: linear-gradient(to right, #350bc8, #8633c3, #b72dbc);
  --btn-sub: linear-gradient(to right, #d7437c, #f6b56b);
  --btn-disabled: #161a23;
  --btn-disabled-border: var(--main-border);
  --btn-disabled-txt: #707070;
  --btn-shadow: var(--main-shadow);
  --btn-deco: var(--main-bg);
  --btn-txt: var(--main-txt);
  --btn-border-radius: 10px;
  --btn-radius: 10px;
  --btn-font: 20px;
  --btn-height: 48px;
  --btn-mobile-height: 35px;
  --btn-width: 280px;

  // CPListTab
  --listTab: var(--main-bg);
  --list-txt: var(--tab-txt-selected);
  --list-unSelect-text: var(--tab-txt-unSelected);
  --list-underline: var(--tab-selected-bg);

  // CPDatePicker
  --datePickerSelected: linear-gradient(to right, #d7437c, #f6b56b);
  --datePickerSelected-shadow: none;
  --datePickerSelected-border: none;
  --datePickerBorder: var(--main-border);
  --datePickerBg: #141723;
  --datePickerTxt: var(--main-txt);
  --datePickSelectedTxt: var(--main-txt);
  --dateShadow: var(--main-shadow);
  --dataUnSelectedShadow: 'transaprent';

  //CPListing
  --listing-border: var(--main-border);
  --listing-shadow: none;
  --listing-bg: var(--form-bg);
  --listing-item: var(--main-txt);

  //CPProgressBar
  --progress-bar-container: #000000;
  --progress-bar-shadow: none;
  --progress-bar-border: none;
  --progress-bar-default: linear-gradient(to right, #f7bb6a, #d3367e);

  //#region =====> Root

  //Notif
  --notif-shadow: 4px 4px 4px rgba(0, 0, 0, 0.5);
  --notif-bg: #ffffff;
  --notif-msg: var(--sub-txt);
  --notif-btn-bg: #141723;
  --notif-btn-shadow: var(--notif-shadow);
  --notif-btn-txt: var(--main-txt);

  //Info
  --info-bg: var(--main-bg);
  --info-title: var(--main-txt);
  --info-desc: var(--main-txt);
  --info-btn-bg: var(--btn-main);
  --info-btn-shadow: var(--main-shadow);
  --info-btn-border: none;
  --info-btn-txt: var(--main-txt);
  --dontShow: var(--main-txt);

  //Plane
  --plane-bg: rgba(0, 0, 0, 0.25);
  --plane-blur: blur(10px);

  //Bank
  --root-bank-bg: rgba(0, 0, 0, 0.5);
  --root-bank-shadow: var(--main-shadow);
  --root-bank-border: var(--main-border);
  --root-bank-title: var(--main-txt);
  --root-bank-tips: var(--tips-txt);
  --root-bank-label: var(--main-txt);
  --root-bank-input-txt: var(--main-txt);
  --root-bank-input-placeholder: rgba(255, 255, 255, 0.5);
  --root-bank-input-border: var(--main-border);
  --root-bank-input-bg: transparent;
  --root-bank-input-shadow: none;
  --root-bank-btn-bg: var(--btn-main);
  --root-bank-btn-shadow: var(--btn-shadow);
  --root-bank-btn-border: none;

  //Dialog
  --dialog-bg: var(--main-bg);
  --dialog-shadow: 5px 5px 5px black;
  --dialog-border: var(--main-border);
  --dialog-title-txt: var(--main-txt);
  --dialog-content-txt: var(--main-txt);
  --dialog-err: linear-gradient(to right, #be2e1e, #d66e36, #f0ba55);
  --dialog-success: linear-gradient(to right, #4aa5b2, #5ac084, #6adc62);
  --dialog-option: linear-gradient(to right, #1327bd, #386cd5, #64bdf0);
  --dialog-btn-txt: var(--main-txt);
  --dialog-option-bottom: 1px solid gray;

  //Loading
  --loading-bg: rgba(0, 0, 0, 0.5);

  //Toast
  --taost-bg: rgba(255, 255, 255, 0.2);
  --taost-txt: var(--main-txt);
  --toast-desc: var(--main-txt);

  //News
  --news-bg: #161a23;
  --news-border: var(--main-border);
  --news-shadow: none;
  --news-close-bg: linear-gradient(135deg, #f14658, #dc2537);
  --news-close-border: var(--btn-shadow);
  --news-close-shadow: none;
  --news-close-txt: var(--main-txt);
  --news-pagination: rgba(255, 255, 255, 0.5);
  --news-pagination-active: #fff;
  --news-title-txt: var(--main-txt);
  --news-desc-txt: var(--main-txt);
  --news-ok-btn-bg: var(--btn-main);
  --news-ok-btn-shadow: var(--btn-shadow);
  --news-ok-btn-border: none;
  --news-ok-btn-txt: var(--main-txt);

  //Feedback
  --feedback-container-bg: var(--transparent);
  --feedback-bg: #101313;
  --feedback-border: var(--main-border);
  --feedback-comment-bg: #252829;
  --feedback-comment-border: var(--main-border);
  --feedback-comment-txt: #fff;
  --feedback-submit-bg: #70c727;
  --feedback-submit-border: none;
  --feedback-submit-shadow: var(--btn-shadow);
  --feedback-submit-disabled-bg: var(--btn-disabled);
  --feedback-submit-disabled-border: var(--btn-disabled-border);
  --feedback-submit-disabled-shadow: none;
  --feedback-submit-txt-disabled: var(--btn-disabled-txt);
  --feedback-submit-txt: var(--btn-txt);

  //#endregion

  //#endregion

  //#region =====> Club
  --club-title: var(--main-txt);
  //#endregion

  //#region =====> Porn
  --porn-content-bg: linear-gradient(135deg, #f7bb6a 0%, #d3367e 100%);
  --porn-title: #000000;
  --porn-date: #000000;
  --porn-desc: #000000;
  //#endregion
  
  //#region =====> Movie
  --movie-content-bg: linear-gradient(132.89deg, #cb2ab8 3.42%, #2700c9 100%);
  --movie-imdb-rate: #ffeb00;
  --movie-btn-bg: #ffffff;
  --movie-btn-txt: #202124;
  --movie-detail-bg: #ffffff;
  --movie-bot-border: 1px solid rgba(0, 0, 0, 0.12);
  --movie-rate-blue: #1a0dab;
  --movie-google-users-txt: #70757a;
  --movie-desc: #4d5156;
  --movie-title: #000000;
  --movie-date: #000000;
  --movie-imdb: #000000;
  --movie-popup-title: #000000;
  --movie-imdb-title: #000000;
  --movie-btm-txt: #000000;
  //#endregion

  //#region =====> Streaming
  --streaming-title: linear-gradient(132.89deg, #cb2ab8 3.42%, #2700c9 100%);
  --streaming-title-txt: var(--main-txt);
  --streaming-home-bg: #ffffff;
  --streaming-home-shadow: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  --streaming-home-txt: var(--sub-txt);
  --streaming-away-txt: var(--sub-txt);
  --streaming-score-bg: #dedede;
  --streaming-score-txt: var(--sub-txt);
  --streaming-date-bg: #000000;
  --streaming-date-txt: var(--main-txt);
  --streaming-isLive-bg: linear-gradient(135deg, #ff0000 0%, #d3367e 100%);
  --streaming-isLive-txt: var(--main-txt);
  --streaming-coming-bg: linear-gradient(135deg, #ff0000 0%, #d3367e 100%);
  --streaming-coming-txt: var(--main-txt);
  --streaming-play-isLive-bg: linear-gradient(135deg, #28e544 0%, #00a6b4 100%);
  --streaming-play-isLive-txt: var(--main-txt);
  --streaming-watch-txt: #000000;
  --streaming-play-coming-bg: linear-gradient(
    134.29deg,
    #3fcffa 0%,
    #0b0bbe 101.27%
  );
  --streaming-play-coming-txt: var(--main-txt);
  //#endregion

  //#region =====> Sign In & Sign Up
  --signIn-title: var(--sub-txt);
  --signIn-underline: #000000;
  --signIn-otp-input-txt: #000000;
  --signIn-otp-underline: #000000;
  --signIn-btn: #1c2131;
  --signIn-btn-border: none;
  --signIn-btn-shadow: none;
  --signIn-disabled-btn: rgba(28, 33, 49, 0.5);
  --signIn-desc: #808080;
  --signIn-bg: rgba(255, 255, 255, 0.75);
  --signIn-input: #000000;
  --signIn-input-placeholder: #757575;
  --signIn-mobile: var(--sub-txt);
  --signIn-mobile-separator: #000000;
  --signIn-input-bg: transparent;
  --signIn-shadow: var(--main-shadow);
  --signIn-disabled-btn-txt: #ffffff;
  --signIn-disabled-border: none;
  --signIn-btn-txt: #ffffff;
  --signIn-help: var(--main-txt);
  --signIn-modal-bg: var(--main-modal-bg);
  --signIn-modal-title: var(--sub-txt);
  --signIn-selected-bg: #ffffff;
  --signIn-selected-txt: var(--sub-txt);
  --signIn-lang-txt: #312d2d;
  --signIn-line-bg: #00c200;
  --signIn-line-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  --signIn-ver: 'transparent';

  // login with line
  --signIn-login-line-bg: #00c200;
  --signIn-line-icon-bg: rgba(0, 0, 0, 0.16);
  --signIn-line-label: var(--main-txt);
  --signIn-or: #8c9098;
  --signIn-or-border: 1px #8c9098 solid;

  --signUp-title: #1c2131;
  --signUp-tips: var(--sub-txt);
  --signUp-prefix: var(--sub-txt);
  --signUp-mobile: var(--sub-txt);
  --signUp-verify-btn-bg: #1c2131;
  --signUp-verify-btn-txt: #ffffff;
  --signUp-disabled-verify-btn-txt: #707070;
  --signUp-disabled-verify-btn-bg: rgba(28, 33, 49, 0.5);
  --signUp-disabled-verify-btn-border: none;
  --signUp-verify-btn-shadow: none;
  --signUp-verify-btn-border: none;
  --signUp-resend-btn: transparent;
  --signUp-resend-btn-shadow: none;
  --signUp-timer: var(--sub-txt) !important;

  //#endregion

  //#region =====> More

  --more-trans-bg: rgba(0, 0, 0, 0.8);
  --more-bg: #1c2131;
  --more-title: var(--main-txt);
  --more-title-bg: var(--header-bg);
  --more-item: var(--main-txt);
  --more-lang-title-txt: #ffffff !important;

  //#endregion

  //#region =====> Profile

  --profile-banklist: linear-gradient(to right, #350bc8, #8633c3, #b72dbc);
  --profile-banklist-shadow: var(--main-shadow);
  --profile-banklist-txt: var(--main-txt);
  --profile-downline: linear-gradient(to right, #1327bd, #386cd5, #64bdf0);
  --profile-downline-shadow: var(--main-shadow);
  --profile-downline-txt: var(--main-txt);
  --profile-changePwd: linear-gradient(to right, #be2e1e, #d66e36, #f0ba55);
  --profile-changePwd-shadow: var(--main-shadow);
  --profile-changePwd-txt: var(--main-txt);
  --profile-signOut-txt: var(--main-txt);
  --profile-version: var(--main-txt);
  --profile-bg: var(--main-bottom-shadow);
  --profile-banklist-border: none;
  --profile-downline-border: none;
  --profile-changePwd-border: none;

  //#endregion

  //#region =====> Lang

  --lang-modal-bg: rgba(255, 255, 255, 0.878);
  --lang-modal-btn: #ffffff;

  //#endregion

  //#region =====> Mission
  --mission-bg: var(--main-bg);
  --mission-more: #c62828;
  --mission-badge-bg: #ff0000;
  --mission-list-bg: #141723;
  --mission-listing-middle-line: gray;
  --mission-btn: #ffffff;
  --mission-btn-txt: #000000;
  --mission-status: #ffffff;
  --mission-status-num: #ffffff;
  --mission-amt-bg: linear-gradient(to right, #350bc8, #8633c3, #b72dbc);
  --mission-amt-shadow: --main-shadow;
  --mission-amt-border: none;
  --mission-amt-amt: #ffffff;
  --mission-amt-title-txt: var(--main-txt);
  --mission-listing-border: --main-border;
  --mission-reward: #f9ce32;
  --mission-noProgress-bg: white;
  --mission-noProgress-txt: black;
  --mission-inProgress-bg: #0b84fa;
  --mission-inProgress-txt: white;
  --mission-claimed-bg: #70c727;
  --mission-claimed-txt: white;
  --mission-noProgress-shadow: none;
  --mission-noProgress-border: none;
  --mission-inProgress-shadow: none;
  --mission-inProgress-border: none;
  --mission-claimed-shadow: none;
  --mission-claimed-border: none;
  --mission-label-default: #ffffff;

  //popup
  --mission-popup-blur-bg: rgba(0, 0, 0, 0.8);
  --mission-popup-border: var(--main-border);
  --mission-popup-shadow: none;
  --mission-popup-bg-top: #141723;
  --mission-popup-bg: #1c2131;
  --mission-popup-reward-bg: #161a23;
  --mission-popup-title: #ffffff;
  --mission-popup-txt-shadow: 2px 2px 2px rgba(0, 0, 0, 0.75);
  --mission-popup-txt-cancel-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
  --mission-popup-label: #ffffff;
  --mission-popup-label-item: #ffffff;
  --mission-popup-reward: #ffffff;
  --mission-popup-info: #ffffff;
  --mission-popup-txt: #ffffff;
  --mission-gradient: var(--main-bottom-shadow);
  --mission-reward-txt: #f9ce32;
  --mission-popup-activity-container-bg: #1c2131;
  --mission-popup-activity-container-border: var(--main-border);
  --mission-cover-gradient: linear-gradient(
    180deg,
    #000000 0%,
    rgba(0, 0, 0, 0.3) 100%
  );
  --mission-popup-bottom-container-top-border: none;

  //record

  --mission-record-date: #c9c9c9;
  --mission-record-txt: #ffffff;
  --mission-record-status-bg: #70c71a;

  //#endregion

  //#region =====> Fund

  --fund-progressbar: linear-gradient(to right, #f7bb6a, #d3367e);
  --fund-btn-bg: #ffffff;
  --fund-btn-shadow: var(--main-shadow);
  --fund-btn-border: 1px solid transparent;
  --fund-btn-bg-disabled: #161a23;
  --fund-btn-border-disabled: var(--main-border);
  --fund-btn-txt-disabled: #707070;
  --fund-btn-txt: #000000;
  --fund-withdraw-btn-bg: var(--fund-btn-bg);
  --fund-withdraw-btn-shadow: var(--fund-btn-shadow);
  --fund-withdraw-btn-border: var(--fund-btn-border);
  --fund-withdraw-btn-bg-disabled: var(--fund-btn-bg-disabled);
  --fund-withdraw-btn-border-disabled: var(--fund-btn-border-disabled);
  --fund-withdraw-btn-txt-disabled: var(--fund-btn-txt-disabled);
  --fund-withdraw-btn-txt: var(--fund-btn-txt);
  --fund-btn-detail-bg: var(--btn-main);
  --fund-btn-detail-shadow: var(--btn-shadow);
  --fund-btn-detail-border: none;
  --fund-tips-title: var(--main-txt);
  --fund-tips-txt: gray;
  --fund-tips-border: var(--main-border);
  --fund-tips-shadow: var(--main-shadow);
  --fund-tips-bg: #161a23;
  --fund-act-icon: none;
  --fund-card-bg: url(../image/asset/wallet/fund.png);
  --fund-card-txt: var(--main-txt);
  --fund-bottom-border: var(--main-border);
  --fund-bottom-shadow: var(--main-shadow);
  --fund-bottom-bg: #141723;
  --fund-bottom-style-bg: var(--main-bottom-shadow);
  --fund-bottom-txt: var(--main-txt);
  --fund-tips: var(--strong);
  --fund-rebate-bg: #ffffff;
  --fund-rebate-txt: #000000;
  --fund-rebate-shadow: var(--main-shadow);
  --fund-timer-txt: var(--timer-txt);

  //#endregion

  //#region =====> Comm (mix with fund)

  --comm-card-bg: url(../image/asset/wallet/comm.png);
  --comm-card-title: #ffffff;
  --comm-card-txt: #ffffff;
  --comm-card-shadow: var(--main-shadow);
  --comm-tips2-txt: #ffffff;
  --comm-btn-shadow: var(--main-shadow);
  --comm-act-icon: none;
  --comm-btn-shadow: var(--main-shadow);
  --comm-btn-txt: var(--main-txt);
  --comm-btn-bg: var(--header-bg);
  --comm-btn-border: var(--main-border);
  --comm-btn-shadow: var(--main-shadow);
  --comm-list-shadow: var(--main-shadow);
  --comm-summ-title: #c9c9c9;
  --comm-summ-green: #70c71a;
  --comm-summ-orange: #ff9345;
  --comm-datePicker-title: var(--sub-txt);
  --comm-datePicker-btn: var(--sub-txt);
  --comm-datePicker-selected-btn: var(--sub-txt);
  --comm-datePicker-btn-radius: var(--btn-radius);
  --comm-datePicker-selected: var(--main-txt);

  //#endregion

  //#region =====> Comm2Wallet

  --comm2wallet-bg: var(--form-bg);
  --comm2wallet-border: var(--main-border);
  --comm2wallet-label: var(--form-label);
  --comm2wallet-remark: var(--tips-txt);
  --comm2wallet-btn-bg: var(--btn-main);
  --comm2wallet-btn-shadow: var(--main-shadow);
  --comm2wallet-btn-border: none;
  --comm2wallet-btn-txt: var(--btn-txt);
  --comm2wallet-btn-bg-disabled: var(--btn-disabled);
  --comm2wallet-btn-shadow-disabled: var(--main-shadow);
  --comm2wallet-btn-border-disabled: var(--btn-disabled-border);
  --comm2wallet-btn-txt-disabled: var(--btn-disabled-txt);
  --comm2wallet-input-bg: var(--form-bg);
  --comm2wallet-input-shadow: none;
  --comm2wallet-input-border: var(--main-border);

  //#endregion

  //#region =====> Comm2Bank (mix with Comm2Wallet)

  --comm2bank-btn-shadow: var(--main-shadow);
  --comm2bank-btn-border: none;
  --comm2bank-btn-bg: var(--btn-main);
  --comm2bank-btn-txt: var(--btn-txt);
  --comm2bank-btn-shadow-disabled: var(--main-shadow);
  --comm2bank-btn-border-disabled: none;
  --comm2bank-btn-bg-disabled: var(--btn-disabled);
  --comm2bank-btn-txt-disabled: var(--btn-disabled-txt);

  //#endregion

  //#region =====> Wallet

  --wallet-detail-bal: linear-gradient(to right, #350bc8, #8633c3, #b72dbc);
  --wallet-detail-comm: linear-gradient(to right, #d7437c, #f6b56b);
  --wallet-radius: var(--main-radius);
  --wallet-txt: var(--main-txt);

  //#endregion

  //#region =====> Game List

  --marquee-txt: var(--main-txt);

  //#endregion

  //#region =====> Game List

  --game-container-bg: #141723;
  --game-filter-btn-bg: #1c2131;
  --game-filter-btn-container: var(--main-border);
  --game-filter-shadow: var(--main-shadow);
  --game-filter-selected: var(--main-txt);
  --game-filter-selected-border: none;
  --game-filter-selected-shadow: var(--main-shadow);
  --game-filter-selected-txt: #141723;
  --game-filter-unselect-txt: var(--main-txt);
  --game-filter-disabled-txt: #707070;
  --game-button-common-shadow: var(--gray-shadow);
  --game-provider-bg: var(--main-bg);
  --game-provider-bg-shadow: 4px 4px 4px rgba(0, 0, 0, 0.75);
  --game-provider-btn: black;
  --game-provider-btn-border: none;
  --game-provider-btn-shadow: 4px 4px 4px rgba(0, 0, 0, 0.5);
  --game-provider-btn-bg: #111415;
  --game-provider-shadow: var(--main-shadow);
  --game-provider-border: var(--main-border);
  --game-ipad-size-max-width: 686px;
  --game-option-bg: var(--main-bg);
  --game-option-shadow: inset 0px 11px 8px -7px #141723;
  --game-top-bg: #141723;
  --game-top-shadow: 0px 4px 4px #141723;
  --game-overlay-background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) -15.36%,
    #000000 54.29%
  );

  //appGame
  --app-game-user-info-background: #ffffff;
  --app-game-pw-info-background: rgba(20, 23, 35, 1);
  --app-game-user-info-text-color: rgba(21, 24, 37, 1);
  --app-game-pw-info-text-color: rgba(255, 255, 255, 1);
  --app-game-play-game-button-background: #70c727;
  --app-game-play-game-text: #006c35;
  --app-game-play-game-button-border: 2px solid #70c727;
  --app-game-warning-color: #e0250c;
  --app-game-alert-background: #f0f3fc;
  --app-game-copy-background: rgba(20, 23, 35, 1);
  --app-game-copy-border: none;
  --app-game-copy-shadow: none;
  --app-game-chg-pw-shadow: none;

  //#endregion

  //#region =====> Play

  --play-container-bg: var(--main-bg);

  //#endregion

  //#region =====> Deposit

  --auto-title: var(--main-txt);
  --auto-info: var(--main-txt);

  //#endregion

  //#region =====> Deposit

  --deposit-render-border: var(--main-border);
  --deposit-render-shadow: var(--main-shadow);
  --deposit-render-bg: #141723;
  --deposit-title: #ffffff;
  --deposit-status: #70c727;
  --deposit-desc: #ffffff;
  --deposit-inactive: #707070;
  --deposit-status-inactive: #ff0000;

  //#endregion

  //#region =====> Topup

  --topup-cs-txt: #ffffff;
  --topup-cs-bg: #111415;
  --topup-label: #ffffff;
  --topup-label-amt: #ffffff;
  --topup-amt-remark: #ffffff;
  --topup-cs-btn: 1px solid #70c727;
  --topup-enter-txt: var(--main-txt);
  --topup-tip-bg: var(--main-bg);
  --topup-tip-title: #ff0000;
  --topup-tip-txt: var(--main-txt);
  --topup-tip-border: var(--main-border);
  --topup-input-bottom: solid 1px #c9c9c9;
  --topup-btn-bg: var(--btn-main);
  --topup-btn-shadow: var(--main-shadow);
  --topup-digit: #ff0000;
  --topup-amt-btn-border: var(--main-border);
  --topup-amt-btn-shadow: var(--main-shadow);
  --topup-amt-btn-txt: #ffffff;

  // discount
  --topup-amt-btn: #141723;
  --topup-amt-selected: linear-gradient(to bottom right, #f6b56b, #d7437c);
  --topup-amt-deco: #000000;
  --topup-promo-bg: #c62828;
  --topup-promo-txt: #ffffff;
  --topup-promo-shadow: var(--main-shadow);
  --topup-promo-selected-bg: #ffffff;
  --topup-promo-selected-txt: #c62828;

  // detail
  --topup-detail-amt: #ffffff;
  --topup-detail-inner-bg: #ffffff;
  --topup-detail-inner-shadow: var(--main-shadow);
  --topup-detail-inner-txt: var(--sub-txt);
  --topup-detail-item-txt: var(--sub-txt);
  --topup-detail-enter-label: var(--main-txt);
  --topup-detail-enter-border: var(--border);
  --topup-detail-tips: var(--strong);
  --topup-detail-cancel: #404243;
  --topup-detail-cancel-shadow: var(--main-shadow);
  --topup-detail-home: #0b84fa;
  --topup-detail-home-shadow: var(--main-shadow);
  --topup-detail-bank-acct: #c10000;
  //#endregion

  //#region =====> Withdraw

  --wit-selectbank-bg: #ffffff;
  --wit-input-txt: var(--main-txt);
  --wit-input-placeholder: rgba(255, 255, 255, 0.5);
  --wit-btn-bg: var(--btn-main);
  --wit-btn-shadow: var(--btn-shadow);
  --wit-btn-border: none;
  --add-bank-wit-btn-txt: var(--btn-txt);
  --add-bank-wit-btn-txt-disabled: var(--btn-disabled-txt);
  --addBank-btn-shadow: var(--comm2wallet-btn-shadow);
  --addBank-btn-border: var(--comm2wallet-btn-border);
  --addBank-btn-bg: var(--comm2wallet-btn-bg);
  --addBank-btn-shadow-disabled: var(--comm2wallet-btn-shadow-disabled);
  --addBank-btn-border-disabled: var(--comm2wallet-btn-border-disabled);
  --addBank-btn-bg-disabled: var(--comm2wallet-btn-bg-disabled);

  //#endregion

  //#region =====> Transaction

  --trxn-type: var(--main-txt);
  --trxn-amt: var(--main-txt);
  --trxn-date: #c9c9c9;
  --trxn-approve: var(--main-txt);
  --trxn-reject: var(--main-txt);
  --trxn-others: var(--main-txt);
  --trxn-approve-bg: #70c71a;
  --trxn-reject-bg: #dd2f2f;
  --trxn-others-bg: #ff9345;

  // detail
  --trxn-detail-bg: var(--form-bg);
  --trxn-detail-border: var(--main-border);
  --trxn-detail-header-bottom-border: none;
  --trxn-detail-shadow: none;
  --trxn-detail-amt-bg: #1c2131;
  --trxn-detail-approve: #70c71a;
  --trxn-detail-reject: #dd2f2f;
  --trxn-detail-others: #ff9345;
  --trxn-detail-cancel: #ffffff;
  --trxn-detail-cancel-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);

  //#endregion

  //#region =====> Game Record

  --gameRec-date: var(--main-txt);
  --gameRec-game: var(--main-txt);
  --gameRec-win: var(--main-txt);
  --gameRec-lose: var(--main-txt);
  --gameRec-draw: var(--main-txt);
  --gameRec-winBg: #70c71a;
  --gameRec-loseBg: #dd2f2f;
  --gameRec-drawBg: #ff9345;
  --gameRec-winTxt: #70c71a;
  --gameRec-loseTxt: #dd2f2f;
  --gameRec-drawTxt: #ff9345;

  //#endregion

  //#region =====> Game Record

  --commRec-date: var(--main-txt);
  --commRec-amt: var(--main-txt);
  --commRec-type: var(--main-txt);

  //#endregion

  //#region =====> Info

  --chat-name: var(--main-txt);
  --chat-tab-shadow: var(--main-shadow);
  --chat-tab-btn: 1px solid gray;
  --chat-tab-selected-bg: linear-gradient(to bottom right, #f6b56b, #d7437c);
  --chat-listing-border: none;
  --chat-listing-bg: var(--listing-bg);
  --chat-listing-shadow: var(--listing-shadow);
  --info-date: #c9c9c9;
  --line-txt: var(--main-txt);
  --line-btn: linear-gradient(to right, #70c727, #70c727);
  --line-btn-border: none;
  --line-btn-shadow: none;
  --line-btn-txt: var(--btn-txt);

  //#endregion

  //#region =====> Bank List

  --bankList-name-border: var(--main-border);
  --bankList-name-shadow: var(--main-shadow);
  --bankList-name: var(--main-txt);
  --bankList-bg: #161a23;
  --bankList-tips-bg: #161a23;
  --bankList-tips-border: var(--main-border);
  --bankList-tips-shadow: transparent;
  --bankList-tips-txt: var(--tips-txt);
  --bankList-style-bg: var(--bank-bg);
  --bankList-bankname: var(--sub-txt);
  --bankList-banknum: var(--sub-txt);

  //#endregion

  //#region =====> Add Bank

  --addBank-btn-bg: var(--btn-main);

  //#endregion

  //#region =====> Affiliate

  --affiliate-save: var(--btn-sub);
  --affiliate-save-shadow: var(--main-shadow);
  --affiliate-save-txt: var(--main-txt);
  --affiliate-share: var(--btn-main);
  --affiliate-share-shadow: var(--main-shadow);
  --affiliate-share-border: none;
  --affiliate-share-txt: var(--main-txt);
  --affiliate-line: linear-gradient(to right, #4aa5b2, #5ac084, #6adc62);
  --affiliate-line-shadow: var(--main-shadow);
  --affiliate-line-border: none;
  --affiliate-line-txt: var(--main-txt);
  --affiliate-copy: #ffffff;
  --affiliate-copy-shadow: var(--main-shadow);
  --affiliate-copy-border: none;
  --affiliate-copy-txt: #000000;
  --affiliate-unsettled-bg: #1c2131;
  --affiliate-unsettled-border: var(--main-border);
  --affiliate-unsettled-title: var(--main-txt);
  --affiliate-unsettled-date: var(--main-txt);
  --affiliate-downline-bg: #141723;
  --affiliate-downline-txt: var(--main-txt);
  --affiliate-downline-border: var(--main-border);
  --affiliate-downline-shadow: var(--main-shadow);
  --affiliate-report-bg: linear-gradient(to right, #d7437c, #f6b56b);
  --affiliate-report-txt: var(--main-txt);
  --affiliate-report-border: none;
  --affiliate-report-shadow: var(--main-shadow);
  --affiliate-topup-btn-shadow: var(--fund-btn-shadow);
  --affiliate-topup-btn-bg: var(--fund-btn-bg);
  --affiliate-topup-btn-border: var(--fund-btn-border);
  --affiliate-topup-btn-txt: var(--fund-btn-txt);
  --affiliate-withdraw-btn-shadow: var(--fund-btn-shadow);
  --affiliate-withdraw-btn-bg: var(--fund-btn-bg);
  --affiliate-withdraw-btn-border: var(--fund-btn-border);
  --affiliate-withdraw-btn-txt: var(--fund-btn-txt);

  //#endregion

  //#region =====> Downline

  --downline-bg-active: #70c71a;
  --downline-bg-new: #6cd0ff;
  --downline-bg-inactive: #ff9345;
  --downline-active: var(--main-txt);
  --downline-new: var(--main-txt);
  --downline-inactive: var(--main-txt);
  --downline-avatar-border: var(--main-border);
  --downline-avatar-bg: #161a23;
  --downline-avatar-shadow: var(--main-shadow);
  --downline-tab-bg: #141723;
  --downline-tab-border: var(--chat-tab-btn);
  --downline-tab-txt: var(--main-txt);
  --downline-active-txt: var(--main-txt);
  --downline-inactive-txt: #707070;
  --downline-invite: var(--main-txt);
  --downline-listing-item-bg: var(--listing-bg);
  --downline-listing-item-border: var(--listing-border);
  --downline-listing-item-shadow: var(--listing-shadow);

  //#endregion

  //#region =====> Footer

  --footer-container: rgba(20, 23, 35, 1);
  --footer-selected-item-bg: #ffffff;
  --footer-selected-item-shadow: none;
  --footer-item-txt: #fff;
  --footer-item-selected-txt: #000000;

  //#endregion

  //#region =====> Change Password

  --changePwd-style-bg: var(--main-bg);
  --changePwd-style-shadow: var(--main-shadow);
  --changePwd-label-container: var(--main-border);
  --changePwd-remark: var(--tips-txt);
  --changePwd-submit-border: none !important;
  --changePwd-submit-shadow: var(--main-shadow);
  --changePwd-disable-shadow: none !important;
  --changePwd-disable-border: var(--main-border) !important;
  --changePwd-disable-bg: transparent !important;
  --changePwd-input-bg: transparent;
  --changePwd-txt: var(--main-txt);
  --changePwd-signOut-bottom: var(--main-border);

  //#endregion

  //#region =====> Pin

  --pin-title: #1c2131;
  --pin-txt: var(--main-txt);

  //#endregion

  //#region =====> ResetPin

  --reset-pin-title: #1c2131;
  --reset-pin-desc: #000000;
  --reset-pin-mobile: var(--sub-txt);
  --reset-pin-btn: var(--signIn-btn);
  --reset-pin-btn-border: none;
  --reset-pin-btn-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  --reset-pin-cancel: var(--sub-txt);
  --reset-pin-btn-txt: #ffffff;

  //#endregion

  //new added - 25 Jul 2023
  --search-text-field-bg: #141723;
  --search-text-inset-shadow: none;
  --search-input-placeholder: #686868;
  --game-category-bg: #141723;
  --wallet-shadow: 4px 4px 4px rgba(0, 0, 0, 0.75);
  --wallet-border: none;
  --profile-mobile-txt: #ffffff;
  --profile-container-shadow: none;
  --mission-content-title-txt: #ffffff;
  --mission-content-desc-txt: var(--main-txt);
  --mission-btn-border: none;
  --root-bank-dialog-bg: #161a23;
  --game-search-input-txt: #ffffff;
  --game-search-icon-bg: #141723;
  --game-search-icon-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  --game-search-icon-border: 1px solid #707070;
  --inner-game-back-bg: #141723;
  --inner-game-back-border: var(--game-filter-btn-container);
  --inner-game-back-shadow: var(--game-button-common-shadow);
  --provider-banner-bg: #141723;
  --provider-banner-shadow: none;
  --inner-game-back-txt: #c4c5c8;
  --datePickerTitleTxt: #ffffff;
  --bankList-style-shadow: none;
  --chat-tab-bg: #1c2131;
  --chat-name-selected: #ffffff;
  --chat-info-title-txt: #ffffff;
  --comm-lifetime-bg: #000000;
  --comm-lifetime-shadow: 4px 4px 4px rgba(0, 0, 0, 0.75);
  --comm-lifetime-border: none;
  --comm-lifetime-txt: var(--main-txt);
  --affiliate-unsettled-shadow: 4px 4px 4px rgba(0, 0, 0, 0.75);
  --affiliate-unsettled-bg: #1c2131;
  --change-lang-item-txt: #ffffff;
  --back-nav-back-bg: #141723;

  //header footer enhancement
  --back-nav-bg: #1c2131;
  --back-nav-back-border: none;
  --back-nav-back-shadow: none;
  --back-nav-back-txt: #ffffff;
  --nav-item-bg: #ffffff;

  //login New UI
  --signIn-disabled-resend-btn: transparent;
  --signIn-disabled-resend-border: var(--main-border-size) solid #bfbfbf;
  --signIn-disabled-resend-txt: #c6c7ca;
  --signUp-resend-border: var(--main-border-size) solid #000000;
  --signUp-resend-txt: #000000;
  --content-modal-bg: #ffffff;
  --forgot-pin-txt: #1c2131;
  --pin-btn-hover-txt: #000000;
  --get-tmp-pin-btn: #1c2131;

  //side menu UI
  --side-menu-bg: #141723;
  --side-menu-nav-bg: var(--side-menu-bg);
  --side-menu-nav-border: none;
  --side-menu-nav-shadow: none;
  --side-menu-home-bg: #1c2131;
  --side-menu-home-border: none;
  --side-menu-home-shadow: none;
  --side-menu-home-txt: #f0f3fc;
  --side-menu-brand-txt: #f0f3fc;
  --language-flag-dark-border: 1px solid #141723;
  --language-flag-dark-shadow: none;
  --language-flag-light-border: 1px solid #ffffff;
  --language-flag-light-shadow: none;
  --language-modal-title: #141723;
  --language-modal-line: rgba(0, 0, 0, 0.25);
  --language-btn-txt: #141723;
  --language-btn-txt-selected: #ffffff;
  --language-btn-bg: rgba(0, 0, 0, 0.05);
  --language-btn-bg-selected: #0057ff;
  --language-flag-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
  --marquee-txt-dark: #141723;
  --side-menu-item-txt: #f0f3fc;
  --side-menu-item-separator: #141723;
  --tab-item-bg: #141723;
  --tab-item-selected-bg: #ffffff;
  --tab-item-selected-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.5);
  --tab-item-txt: #ffffff;
  --tab-item-selected-txt: #000000;
  --title-loading-txt: #ffffff;
  --statement-item-odd-bg: #141723;
  --statement-item-even-bg: transparent;
  --statement-title-txt: #c4c5c8;
  --statement-date-txt: #c4c5c8;
  --statement-amount-txt: #ffffff;
  --statement-status-success-txt: #70c727;
  --statement-status-progress-txt: #ff8c00;
  --statement-status-fail-txt: #e0250c;
  --statement-separator-bg: #6d727c;
  --report-button-bg: #141723;
  --report-button-border: 1px solid #70c727;
  --report-button-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.5);
  --report-button-txt: #ffffff;
  --statement-day-button-bg: #141723;
  --statement-day-button-txt: #ffffff;
  --pop-up-bg: #ffffff;
  --pop-up-txt: #1c2131;
  --pop-up-separator-bg: #8c9098;
  --side-menu-wallet-bg: #1c2131;
  --side-menu-wallet-border: none;
  --side-menu-wallet-shadow: none;
  --side-menu-topup-bg: #1c2131;
  --side-menu-topup-shadow: none;
  --side-menu-topup-border: none;
  --side-menu-withdraw-bg: #1c2131;
  --side-menu-withdraw-shadow: none;
  --side-menu-withdraw-border: none;
  --side-menu-topup-text: #ffffff;
  --side-menu-withdraw-text: #ffffff;
  --sideMenuItem-bg: #1c2131;
  --sideMenuItem-shadow: none;
  --sideMenuItem-border: none;

  // Member Fund Card Component
  // Topup button
  --fundcard-topup-btn-txt-color: #141723;
  --fundcard-topup-btn-box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.5);
  --fundcard-topup-btn-box-border: none;
  --fundcard-topup-btn-background: #fff;

  // Withdraw button
  --fundcard-withdraw-btn-txt-color: #141723;
  --fundcard-withdraw-btn-box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.5);
  --fundcard-withdraw-btn-box-border: none;
  --fundcard-withdraw-btn-background: #fff;

  // Transaction List button
  --fundcard-rebate-btn-txt-color: #000;
  --fundcard-rebate-btn-box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.8);
  --fundcard-rebate-btn-background: #fff;

  //game flipped info
  --game-flipped-small-logo-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.3);
  --game-flipped-bg: #ffffff;
  --game-flipped-title-txt: #000000;
  --game-flipped-value-txt: #666666;
  --game-flipped-separator-bg: #bfbfbf;
  --game-flipped-close-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
  --game-info-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);

  //pagination
  --button-pagination-button-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.5);
  --button-pagination-button-bg: #141723;
  --button-pagination-page-indicator-bg: #141723;
  --button-pagination-page-indicator-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.5);
  --button-pagination-page-indicator-border: 1px solid #707070;
  --button-pagination-page-indicator-txt: #ffffff;

  //custom scroll bar
  --scrollbar-track: #07080c;
  --scrollbar-thumb: rgba(255, 255, 255, 0.5);

  //login component
  --login-component-register-btn: #70c727;
  --login-component-register-border: none;
  --login-component-login-btn: #ffffff;
  --login-component-login-border: none;
  --login-component-register-txt: #ffffff;
  --login-component-login-txt: #000000;
  --login-component-line-btn: #00c200;
  --login-component-line-txt: #ffffff;
  --login-component-register-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.5);
  --login-component-login-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.5);
  --login-component-line-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.5);
  --login-component-line-border: none;
  --login-component-line-img-bg: #00a300;
  --login-component-separator: #8c9098;
  --login-component-separator-txt: #8c9098;

  //intro download app component
  --introDownloadApp-background: #141723;
  --introDownloadApp-btn-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.5);
  --introDownloadApp-btn: linear-gradient(87deg, #ffe600 0%, #70c727 47.66%);
  --introDownloadApp-btn-txt: #ffffff;
  --introDownloadApp-description-txt: #898b90;

  //intro all games component
  --introAllGames-play-btn: #70c727;
  --introAllGames-play-btn-txt: #ffffff;
  --introAllGames-play-btn-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.8);
  --introAllGames-play-btn-border: none;
  --introAllGames-title-txt: #ffffff;

  //intro promotion component
  --introPromotion-view-btn: #141723;
  --introPromotion-view-btn-txt: #ffffff;
  --introPromotion-view-btn-shadow: none;
  --introPromotion-view-btn-border: none;
  --introPromotion-title-txt: #ffffff;
  --introPromotion-info-bg: #141723;
  --introPromotion-content-title: #ffffff;
  --introPromotion-content-subTitle: #ffffff;
  --introPromotion-content-description: rgba(255, 255, 255, 0.5);
  --introPromotion-content-info: rgba(255, 255, 255, 0.5);
  --introPromotion-more-btn: #ffffff;
  --introPromotion-more-btn-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.5);
  --introPromotion-more-btn-border: none;
  --introPromotion-more-btn-txt: #141723;

  //intro affiliate component
  --introAffiliate-more-btn: #141723;
  --introAffiliate-more-btn-txt: #ffffff;
  --introAffiliate-more-btn-shadow: none;
  --introAffiliate-more-btn-border: none;
  --introAffiliateItem-title-txt: #ffffff;
  --introAffiliateItem-title-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
  --introAffiliateItem-desc-txt: rgba(255, 255, 255, 0.6);
  --introAffiliateItem-btn-txt: #141723;
  --introAffiliateItem-btn: #ffffff;
  --introAffiliateItem-btn-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.5);
  --introAffiliateItem-btn-border: none;
  --introAffiliateItem-separator: #ffffff;

  //intro club component
  --introClub-more-btn: #141723;
  --introClub-more-btn-txt: #ffffff;
  --introClub-more-btn-shadow: none;
  --introClub-more-btn-border: none;
  --introClub-title-txt: #ffffff;
  --introClubItem-title-txt: linear-gradient(
    91deg,
    #ffe600 0.21%,
    #ff8c00 99.9%
  );
  --introClubItem-title-shadow: 4px 4px 4px rgba(0, 0, 0, 0.5);
  --introClubItem-desc-txt: #d2d2d4;
  --introClubItem-desc-shadow: 4px 4px 4px rgba(0, 0, 0, 0.5);
  --introClubItem-join-btn: #ffffff;
  --introClubItem-join-btn-txt: #151825;
  --introClubItem-join-btn-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.8);
  --introClubSubItem-title-txt: #ffffff;
  --introClubSubItem-title-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
  --introClubSubItem-btn-txt: #141723;
  --introClubSubItem-btn: #ffffff;
  --introClubSubItem-btn-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.5);
  --introClubSubItem-btn-border: none;
  --introClubSubItem-separator: #ffffff;

  //intro page
  --intro-separator-bg: #8c9098;
  --intro-bottom-info-bg: #151823;

  //fund card component
  --intro-fund-card-bg: #ffffff;
  --intro-fund-card-border: none;
  --intro-fund-card-shadow: none;
  --intro-fund-card-name: #141723;
  --intro-fund-card-bal-title: #141723;
  --intro-fund-card-bal-value: #141723;
  --intro-fund-card-withdraw-bal-value: #141723;
  //side menu fund card component
  --sideMenu-fund-card-name: #ffffff;
  --sideMenu-fund-card-bal-title: #ffffff;
  --sideMenu-fund-card-bal-value: #ffffff;
  --sideMenu-fund-card-withdraw-bal-value: #ffffff;

  //back nav component
  --backNav-title: #545864;

  //auto deposit
  --autoDeposit-separator: #8c9098;

  //games log
  --gamesLog-item-odd-bg: #141723;
  --gamesLog-item-even-bg: transparent;
  --gamesLog-title-txt: #c4c5c8;
  --gamesLog-date-txt: #c4c5c8;
  --gamesLog-turnover-title: #c4c5c8;
  --gamesLog-turnover-txt: #ffffff;
  --gamesLog-winlost-title: #c4c5c8;
  --gamesLog-winlost-txt: #ffffff;
  --gamesLog-status-success-txt: #70c727;
  --gamesLog-status-progress-txt: #ff8c00;
  --gamesLog-status-fail-txt: #e0250c;
  --gamesLog-separator-bg: #6d727c;
  --gamesLog-day-button-bg: #141723;
  --gamesLog-day-button-txt: #ffffff;

  //base text field
  --baseTextField-input: rgba(255, 255, 255, 0.6);
  --baseTextField-input-placeholder: #757575;
  --baseTextField-modal-input: rgba(0, 0, 0, 0.8);
  --baseTextField-modal-input-placeholder: #8c8c8c;

  //textfield
  --textField-title: #ffffff;
  --textField-separator: #ffffff;
  --textField-modal-title: #000000;
  --textField-modal-separator: #000000;
  --textField-err-msg: red;

  //new bank modal
  --newBankModal-title: #000000;
  --newBankModal-hint-txt: rgba(0, 0, 0, 0.5);
  --newBankModal-btn-add-bg: #1c2131;
  --newBankModal-btn-add-txt: #ffffff;

  //Pin Textfield
  --pinItem-bg: #141723;
  --pinItem-txt: #ffffff;
  --pinItem-normal-border: 1px solid #141723;
  --pinItem-err-border: 1px solid #c7240f;
  --pinItem-focus-border: 1px solid #0057ff;
  --pinTextField-err-txt: #e0250c;

  //login Textfield
  --loginCommonTf-bg: #141723;
  --loginCommonTf-txt: #ffffff;
  --loginCommonTf-err-color: #e0250c;
  --loginCommonTf-err-border: 1px solid #c7240f;
  --loginCommonTf-focus-border: 1px solid #0057ff;
  --loginCommonTf-placeholder-txt: rgba(255, 255, 255, 0.5);

  //loginMain tab
  --loginMain-multiTab-bg: #141723;

  //remember component
  --remember-txt: rgba(255, 255, 255, 0.5);

  //login
  --login-forgot-pw-txt: #ffffff;
  --login-separator: rgba(255, 255, 255, 0.5);

  //third party login
  --thirdPartyLogin-border: 0.5px solid #f0f3fc;
  --thirdPartyLogin-bg: transparent;
  --thirdPartyLogin-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.5);

  //ButtonComponent_v2
  --buttonComponent_v2-main-txt: #141723;
  --buttonComponent_v2-main-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.5);
  --buttonComponent_v2-main-bg: #ffffff;
  --buttonComponent_v2-main-border: none;
  --buttonComponent_v2-main-disabled-bg: #181c2a;
  --buttonComponent_v2-main-disabled-txt: rgba(255, 255, 255, 0.5);
  --buttonComponent_v2-main-disabled-shadow: none;

  //verify mobile
  --verifyMobile-title: #ffffff;
  --verifyMobile-otp-des: rgba(255, 255, 255, 0.75);
  --verifyMobile-otp-code: rgba(255, 255, 255, 0.5);
  --verifyMobile-otp-code-bg: #141723;
  --verifyMobile-resend-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.50);
  --verifyMobile-resend-bg: #ffffff;
  --verifyMobile-resend-main-txt: #000000;
  --verifyMobile-resend-border: none;
  --verifyMobile-resend-disabled-bg: #181c2a;
  --verifyMobile-resend-disabled-txt: rgba(255, 255, 255, 0.5);
  --verifyMobile-resend-disabled-shadow: none;

  //timer
  --timer-txt-main: #ffffff;

  //login mobile tf
  --loginMobileTf-prefix-separator: #ffffff;

  //bank listing item
  --bankListingItem-bank-txt: #000000;
  --bankListingItem-separator: #000000;

  //bank drop down border tf
  --bankDropdownBorderTf-border: 1px solid #707070;
  --bankDropdownBorderTf-bg: #141723;
  --bankDropdownBorderTf-placeholder-txt: rgba(255, 255, 255, 0.5);
  --bankDropdownBorderTf-value-txt: #ffffff;
  --bankDropdownBorderTf-popup-title-txt: #141723;
  --bankDropdownBorderTf-popup-bg: #ffffff;
  --bankDropdownBorderTf-popup-shadow: 4px 4px 4px rgba(0, 0, 0, 0.5);
  --bankDropdownBorderTf-popup-separator: #000000;

  //register bank
  --registerBank-separator: #707070;
  //slide indicator
  --slideIndicator-bg: gray;
  --slideIndicator-active-bg: white;

  //subHeader
  --subheader-title-txt: #ffffff;

  //brand about
  --brandAbout-separator: #ffffff7c;
  --brandAbout-brand-txt: #fff;
  --brandAbout-info-txt: #fff;

  //license certification component
  --licenseCert-title: #fff;

  //license certification authentic component
  --licenseCertAuth-title: #fff;

  //join community component
  --joinCommunity-title: #fff;

  //payment supported component
  --paymentSupported-title: #fff;

  //sitemap
  --siteMap-top-separator: #ffffff7c;
  --siteMap-bottom-separator: #ffffff7c;
  --siteMap-title-txt: #fff;
  --siteMap-desc-txt: #8b8c91;
  --siteMap-aboutUs-desc: #fff;

  //copyright
  --copyright-txt: #fff;

  //modal bg
  --enterPin-modal-bg: var(--content-modal-bg);
  --language-modal-bg: var(--content-modal-bg);
  --lineVerifiedMobile-modal-bg: var(--content-modal-bg);
  --resetPin-modal-bg: var(--content-modal-bg);
  --setPin-modal-bg: var(--content-modal-bg);
  --signIn-modal-bg: var(--content-modal-bg);
  --signUp-modal-bg: var(--content-modal-bg);
  --enterPin-modal-shadow: none;
  --enterPin-modal-border: none;
  --language-modal-shadow: none;
  --language-modal-border: none;
  --lineVerifiedMobile-modal-shadow: none;
  --lineVerifiedMobile-modal-border: none;
  --resetPin-modal-shadow: none;
  --resetPin-modal-border: none;
  --setPin-modal-shadow: none;
  --setPin-modal-border: none;
  --signIn-modal-shadow: none;
  --signIn-modal-border: none;
  --signUp-modal-shadow: none;
  --signUp-modal-border: none;

  //scrollbar
  --mission-popup-scrollbar-thumb: rgba(0, 0, 0, 0.6);
  --mission-popup-scrollbar-track: rgba(0, 0, 0, 0.25);
  --mission-popup-scrollbar-thumb-hover: rgba(0, 0, 0, 0.95);
  --news-content-scrollbar-thumb: rgba(0, 0, 0, 0.6);
  --news-content-scrollbar-track: rgba(0, 0, 0, 0.25);
  --news-content-scrollbar-thumb-hover: rgba(0, 0, 0, 0.95);
}
