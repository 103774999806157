@import '../../../resources/theme/Common.scss';

.member-fund-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.member-fund-card-background {
  position: relative;
  background: $intro-fund-card-bg;
  border: $intro-fund-card-border;
  box-shadow: $intro-fund-card-shadow;
  padding: 16px 16px 16px 32px;
    border-radius: 12px;
}

.fundCard-money-image {
  position: absolute;
  width: 38px;
  object-fit: contain;
  bottom: 37px;
  right: 60px;
}

  .member-fund-card-content {
    display: flex;
    flex-direction: column;
    gap: 66px;
    position: relative;
  }

    .member-fund-row1 {
      // justify-content: space-between;
      display: flex;
      align-items: center;
      // padding: 16px 16px 66px 32px;
      // width: 100%;
    }

        .member-fund-name {
          color: $intro-fund-card-name;
          font-family: $fontFamily;
          font-size: $fontSize16;
          font-style: $fontStyleNormal;
          font-weight: $fontWeight700;
          line-height: $fontStyleNormal;
          flex-grow: 1;
        }

        .member-fund-rebate-btn {
          cursor: pointer;
          white-space: nowrap;
          font-size: $fontSize16; 
          text-align: center; 
          color: $fundcard-rebate-btn-txt-color; 
          width: 142px;
          height: 42px;
          padding: 11px;
          border-radius: 10px;
          justify-content: center;
          box-shadow: $fundcard-rebate-btn-box-shadow;
          background: $fundcard-rebate-btn-background;
        }
    
    .member-fund-row2 {
      // padding: 0px 16px 24px 32px;
    }

        .member-fund-bal-title {
          color: $intro-fund-card-bal-title;
          
          font-family: $fontFamily;
          font-size: $fontSize16;
          font-style: $fontStyleNormal;
          font-weight: $fontWeight400;
          line-height: $fontStyleNormal;
        }
        
        .member-fund-bal-amount {
          color: $intro-fund-card-bal-value;
          font-family: $fontFamily;
          font-size: $fontSize30;
          font-style: $fontStyleNormal;
          font-weight: $fontWeight700;
          line-height: $fontStyleNormal;
        }
        
        .member-fund-withdrawable-bal {
          color: $intro-fund-card-withdraw-bal-value;
          font-family: $fontFamily;
          font-size: $fontSize12;
          font-style: $fontStyleNormal;
          font-weight: $fontWeight400;
          line-height: $fontStyleNormal;
        }

  .member-fund-row3 {
      display: flex;
      justify-content: space-between;
      gap: 7px;
  }

      .member-withdraw-btn {
        flex: 1;
        font-size: $fontSize16; 
        color: $fundcard-withdraw-btn-txt-color; 
        font-family: $fontFamily;
        justify-content: center;
        
        padding: 11px;
        border-radius: 10px;
        box-shadow: $fundcard-withdraw-btn-box-shadow;
        border: $fundcard-withdraw-btn-box-border;
        background: $fundcard-withdraw-btn-background;
      }

      .member-withdraw-btn-disabled {
        opacity: 0.5;
      }

      .member-topup-btn {
        flex: 1;
        font-size: $fontSize16; 
        color: $fundcard-topup-btn-txt-color; 
        font-family: $fontFamily;

        justify-content: center;
        padding: 11px;
        border-radius: 10px;
        box-shadow: $fundcard-topup-btn-box-shadow;
        border: $fundcard-topup-btn-box-border;
        background: $fundcard-topup-btn-background;
      }

      .member-topup-btn-disabled {
        opacity: 0.5;
      }

  

