@import '../../../../resources/theme/Common.scss';

.dialog-container {
  min-height: 295px;
  width: 468px;
  padding: 72px 40.5px;
  border-radius: 25px;
  border: $dialog-border;
  background: $dialog-bg;
}

.dialog-content-container {
  max-height: 50vh;
  overflow: auto;
}

.dialog-title {
  font-size: 32px;
  font-weight: 700;
  line-height: 39px;
  text-align: center;
  margin-bottom: 8px;
  color: $dialog-title-txt;
  word-wrap: break-word;
  white-space: wrap;
}

.dialog-content {
  padding-bottom: 32px;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: $dialog-content-txt;
}

.dialog-btn-container {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
}

.dialog-btn {
  height: 48px;
  width: 404px;
  cursor: pointer;
}

.dialog-btn-txt {
  color: $dialog-btn-txt;
}

.dialog-cancel {
  margin-top: 15px;
  border-bottom: $dialog-option-bottom;
  display: inline-block;
  font-size: 20px;
  cursor: pointer;
}

.dialog-error-bg {
  background: $dialog-err;
}

.dialog-success-bg {
  background: $dialog-success;
}

.dialog-option-bg {
  background: $dialog-option;
}

@media screen and (max-width: 430px), screen and (max-height: 500px) {
  .dialog-container {
    min-height: min(22vh, 201px);
    width: min(85vw, 361px);
    padding: unset;
    padding-top: min(4.7619vh, 44px);
    padding-bottom: min(4.9784vh, 46px);
    padding-left: min(3.4632vh, 32px);
    padding-right: min(3.4632vh, 32px);
  }

  .dialog-title {
    font-size: min(4.6838vw, 20px);
    font-weight: 700;
    line-height: 24px;
    text-align: center;
  }

  .dialog-content {
    font-size: min(3.2787vw, 14px);
    font-weight: 400;
    line-height: 17px;
    text-align: center;
  }

  .dialog-btn {
    height: 35px;
    width: min(65.5738vw, 280px);
    border-radius: 10px;
  }

  .dialog-cancel {
    margin-top: min(1.0823vh, 10px);
    font-size: min(3.2787vw, 14px);
  }
}
