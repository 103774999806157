@import '../../../resources/theme/Common.scss';

.button-container-component {
    box-shadow: $game-provider-btn-shadow;
    border-radius: 10px;
    padding: 17px 33px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.button-text-component {
    font-family: $fontFamily;
    font-style: $fontStyleNormal;
    font-weight: $fontWeight700;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: normal;
}