
@import '../../../resources/theme/Common.scss';

.nav-item-container {
    width: 46px;
    height: 46px;
    background: $nav-item-bg;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.nav-item-img {
    width: 30px;
    height: 30px;
    object-fit: fill;
}