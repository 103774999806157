@import '../../../resources/theme/Common.scss';

.thirdPartyLogin-container {
    display: flex;
    flex-direction: row;
    gap: 8px;
}

.thirdPartyLogin-item {
    flex: 1;
}