@import '../../../resources/theme/Common.scss';

.CPPin-container {
  width: 240px;
  margin: 0 auto;
}

.CPPin-field-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0px 0px 24px 0px;
  // display: grid;
  // grid-template-columns: repeat(6, 1fr);
}

.CPPin-field {
  width: 24px;
  height: 24px;
  aspect-ratio: 1;
  border: $cppin-field-border;
  background: transparent;
  border-radius: 100%;
}

.CPPin-field.active {
  background: $cppin-active-field;
  border: $cppin-field-active-border;
}

.CPPin-button-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
}

.CPPin-button {
  width: 60px;
  height: 60px;
  aspect-ratio: 1;
  border-radius: 100%;
  background: $cppin-btn-bg;
  box-shadow: $cppin-shadow;
  border: 0;
  font-size: 24px;
  font-weight: 700;
  //   cursor: pointer;
  user-select: none;
  color: $cppin-btn-txt;
}

.CPPin-button.custom {
  background: transparent;
  box-shadow: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.CPPin-backspace {
  width: 48px;
  height: 31px;
  // aspect-ratio: 1;
}

.CPPin-backspace:active {
  opacity: 0.3;
}

.CPPin-button:disabled {
  cursor: not-allowed;
}

.CPPin-button:enabled:active:not(.custom) {
  background: $cppin-btn-cursor;
  color: $pin-btn-hover-txt;
}

.CPPin-button.hidden {
  opacity: 0;
  cursor: auto;
}

.incorrect {
  -webkit-animation: incorrect 0.8s ease-out 1;
  animation: incorrect 0.8s ease-out 1;
}

@-webkit-keyframes incorrect {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  10% {
    -webkit-transform: translate(-25px, 0);
    transform: translate(-25px, 0);
  }
  20% {
    -webkit-transform: translate(25px, 0);
    transform: translate(25px, 0);
  }
  30% {
    -webkit-transform: translate(-20px, 0);
    transform: translate(-20px, 0);
  }
  40% {
    -webkit-transform: translate(20px, 0);
    transform: translate(20px, 0);
  }
  50% {
    -webkit-transform: translate(-10px, 0);
    transform: translate(-10px, 0);
  }
  60% {
    -webkit-transform: translate(10px, 0);
    transform: translate(10px, 0);
  }
  70% {
    -webkit-transform: translate(-5px, 0);
    transform: translate(-5px, 0);
  }
  80% {
    -webkit-transform: translate(5px, 0);
    transform: translate(5px, 0);
  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}

@keyframes incorrect {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  10% {
    -webkit-transform: translate(-25px, 0);
    transform: translate(-25px, 0);
  }
  20% {
    -webkit-transform: translate(25px, 0);
    transform: translate(25px, 0);
  }
  30% {
    -webkit-transform: translate(-20px, 0);
    transform: translate(-20px, 0);
  }
  40% {
    -webkit-transform: translate(20px, 0);
    transform: translate(20px, 0);
  }
  50% {
    -webkit-transform: translate(-10px, 0);
    transform: translate(-10px, 0);
  }
  60% {
    -webkit-transform: translate(10px, 0);
    transform: translate(10px, 0);
  }
  70% {
    -webkit-transform: translate(-5px, 0);
    transform: translate(-5px, 0);
  }
  80% {
    -webkit-transform: translate(5px, 0);
    transform: translate(5px, 0);
  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}

/* small device */
@media screen and (max-width: 430px), screen and (max-height: 500px) {
}

/* landscape*/
@media screen and (max-height: 500px) {
}
