@import '../../../resources/theme/Common.scss';

.resetPin-modal-main-container {
    width: 100%;
    max-width: 686px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    position: relative;
}

.resetPin-modal-container {
    min-height: 600px;
    width: 100%;
    background: $resetPin-modal-bg;
    box-shadow: $resetPin-modal-shadow;
    border: $resetPin-modal-border;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 32px 50px;
}

.resetPin-modal-close-icon {
    object-fit: contain;
    height: 42px;
    width: 42px;
    position: absolute;
    top: 8px;
    right: 8px;
}


.resetPin-modal-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    color: $reset-pin-title;
  }
  
  .reset-pin-modal-icon {
    height: 75px;
    width: 75px;
    margin: 51.43px 0 39.78px 0;
  }
  
  .reset-pin-modal-desc {
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: $reset-pin-desc;
    padding: 0 23.5px 0 23.5px;
    margin-bottom: 58px;
  }
  
  
  .reset-pin-modal-mobile-container {
    width: 100%;
    border-bottom: 1px solid $signIn-underline;
    margin-bottom: 104px;
  }
  
  .reset-pin-modal-mobile {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: $reset-pin-mobile;
    margin-bottom: 10.5px;
  }
  
  .reset-pin-modal-btn {
    background: $reset-pin-btn;
    margin-bottom: 20px;
    box-shadow: $reset-pin-btn-shadow;
    border: $reset-pin-btn-border;
    height: 42px;
  }
  
  .reset-pin-modal-signIn-txt {
    font-weight: 700;
    font-size: 16px !important;
    line-height: 20px;
    color: $reset-pin-btn-txt;
  }
