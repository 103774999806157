@import '../../../resources/theme/Common.scss';

.textField-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.textField-title {
    color: $textField-title;
    font-family: $fontFamily;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.textField-modal-title {
    color: $textField-modal-title;
}

.textField-error-container {
    display: flex;
    flex-direction: column;
    gap: 0px;
}

.textField-error-msg {
    color: $textField-err-msg;
    font-size: $fontSize10;
}

.textField-separator {
    height: 1px;
    width: 100%;
    background: $textField-separator;
}

.textField-modal-separator {
    background: $textField-modal-separator;
}

.textField-input-container {
    display: flex;
    flex-direction: row;
    gap: 5px;
}

.textField-loading-img {
    width: 24px;
    height: 24px;
}

.textField-error-img {
    width: 24px;
    height: 24px;
}