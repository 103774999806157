@import '../../resources/theme/Common.scss';

.topNav-container {
  background: $header-bg;
  box-shadow: $header-shadow;
  min-height: 48px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: sticky;
  top: 0;
  width: 100vw !important;
  z-index: 1000;
  left: 0;
  position: fixed;
}

.topNav-left-btn {
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: auto;
}

.topNav-right-btn {
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: auto;
}

.topNav-title-container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 20px;
  line-height: 29px;
  color: $header-txt;
}

.topNav-icon {
  height: 28px;
  width: 28px;
  margin-left: 34px;
  margin-right: 10px;
}

.topNav-icon-2 {
  height: 34px;
  width: 34px;
  margin-right: 34px;
}

/* small device */
@media screen and (max-width: 430px), screen and (max-height: 500px) {
  .topNav-container {
    min-height: 48px;
  }

  .topNav-icon {
    height: 18px;
    width: 20px;
    margin-left: 33.7px;
  }

  .topNav-icon-2 {
    height: 20px;
    width: 20px;
    margin-right: 33.7px;
  }

  .topNav-title-container {
    font-size: 14px;
  }
}

/* very small device @ galaxy fold */
@media screen and (max-width: 280px), screen and (max-height: 500px) {
  .topNav-icon {
    height: 20px;
    width: 20px;
    margin-left: 15px;
  }

  .topNav-icon-2 {
    height: 24px;
    width: 24px;
    margin-right: 15px;
  }
}

/* landscape*/
@media screen and (max-height: 500px) {
}
