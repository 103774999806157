@import '../../../resources/theme/Common.scss';

.timer-txt {
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  color: $timer-txt;
}
//timer-txt: other classes --> start
.timer-txt-s16-w700-cMain {
  color: $timer-txt-main;
  font-family: $fontFamily;
  font-size: $fontSize16;
  font-weight: $fontWeight700;
  line-height: normal;
}
//timer-txt: other classes --> end