@import '../../resources/theme/Common.scss';

.club-style {
  padding-top: 48px;
  flex-direction: column;
  width: 100%;
  max-width: 468px;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
  height: 100%;
  margin-bottom: 30px;
}

.club-banner-container {
  margin: 32px 0 42px 0;
  position: relative;
}

.club-banner {
  width: 100%;
  height: 257px;
}

.club-title {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: $club-title;
  position: absolute;
  bottom: 18px;
  text-align: center;
  width: 100%;
}

.club-list-container {
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-row-gap: 26px;
  grid-column-gap: 16px;

  .club-btn-style {
    width: 100%;
    position: relative;

    .club-btn-container {
      height: 100.26px;
      width: 100.26px;
      position: relative;
      border-radius: 15px;
      box-shadow: $main-shadow;
      -moz-box-shadow: $main-shadow;
      -webkit-box-shadow: $main-shadow;
      -khtml-box-shadow: $main-shadow;

      .club-hotLabel-icon {
        height: 30px;
        width: 25px;
        margin-top: 10px;
        left: -12px;
        position: absolute;
      }

      .club-icon {
        height: 100%;
        width: 100%;
        object-fit: fill;
      }
    }

    .club-name {
      font-style: normal;
      font-weight: 700;
      font-size: 9px;
      line-height: 11px;
      text-align: center;
      position: absolute;
      width: 100%;
      padding-top: 5px;
    }
  }
}

@media screen and (max-width: 430px), screen and (max-height: 500px) {
  .club-banner {
    height: 187px;
  }

  .club-list-container {
    grid-template-columns: auto auto auto;
    .club-btn-style {
      .club-btn-container {
        .club-icon {
          height: 100%;
          width: 100%;
          object-fit: fill;
        }
      }
    }
  }
}
