@import '../../../resources/theme/Common.scss';


.paymentSupported-container {
}
    .paymentSupported-title  {
        color: $paymentSupported-title;
        font-family: $fontFamily;
        font-size: $fontSize16;
        font-style: $fontStyleNormal;
        font-weight: $fontWeight700;
        line-height: $fontStyleNormal;
        padding: 0px 0px 8px 5px;
        opacity: 0.5;
    }
    .paymentSupported-icons {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        border-radius: 12px;
        gap: 8px
        // grid-template-columns: repeat(6, minmax(16px,32px)); // set 5col x 1row grid layout
        // justify-content: center;
    }
    .paymentSupported-icons img{   
        width: 32px;
        max-width: 32px;
        object-fit: contain;
        opacity: 0.5;    
    }

/* small device */
@media screen and (max-width: 430px) {

    .paymentSupported-icons{
        // grid-template-columns: repeat(4, minmax(16px,32px)); // set 5col x 1row grid layout
    }
    .paymentSupported-icons img{   
        // padding: 15px 15px 15px 15px;
            // max-height: 32px;
    }
}


