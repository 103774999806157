@import '../../../resources/theme/Common.scss';

.language-modal-main-container {
    width: 100%;
    max-width: 686px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    position: relative;
}

.language-modal-container {
    width: 100%;
    background: $language-modal-bg;
    box-shadow: $language-modal-shadow;
    border: $language-modal-border;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 32px 50px;
    gap: 11px;
}

.language-modal-title {
    font-family: $fontFamily;
    font-size: 24px;
    font-weight: 400;
    color: $language-modal-title;
    width: 100%;
}

.language-modal-line {
    height: 1px;
    width: 100%;
    background: $language-modal-line;
}

.language-modal-content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 0px 18px;
}