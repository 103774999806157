@import '../../../../resources/theme/Common.scss';

.feedback-container {
  height: 100vh;
  width: 100vw;
  position: absolute;
  align-items: center;
  justify-content: center;
  display: flex;
  background: $feedback-container-bg;
  flex-direction: column;
  z-index: $header-footer-zIndex;
}

.feedback {
  height: 701px;
  width: 468px;
  padding: 32px;
  background: $feedback-bg;
  border-radius: 25px;
  border: $feedback-border;
  position: relative;
}

.feedback-title {
  margin-bottom: 32px;
  font-size: 28px;
  font-weight: 700;
  line-height: 34px;
  text-align: left;
}

.feedback-desc {
  margin-bottom: 14px;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
}

.stars-container {
  width: 100%;
  display: flex;
  margin-bottom: 32px;
  justify-content: space-between;
}

.star-img {
  height: 60px;
  width: 60px;
}

.star {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.feedback-comment {
  height: 369px;
  width: 100%;
  margin-bottom: 28px;
  padding: 16px;
  border-radius: 16px;
  border: $feedback-comment-border;
  background: $feedback-comment-bg;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  color: $feedback-comment-txt;
  resize: none;
}

.feedback-submit {
  background: $feedback-submit-bg;
  border: $feedback-submit-border;
  box-shadow: $feedback-submit-shadow;
  cursor: pointer;
}

.feedback-submit-disabled {
  background: $feedback-submit-disabled-bg;
  border: $feedback-submit-disabled-border;
  box-shadow: $feedback-submit-disabled-shadow;
  cursor: not-allowed;
}

.feedback-submit-txt {
  color: $feedback-submit-txt;
}

.feedback-submit-txt-disabled {
  color: $feedback-submit-txt-disabled;
}

.feedback-close-container {
  height: 48px;
  width: 48px;
  position: absolute;
  top: -25px;
  right: -25px;
  cursor: pointer;
}

.feedback-close {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

@media screen and (max-width: 430px), screen and (max-height: 500px) {
  .feedback-container {
    overflow: auto;
    justify-content: unset;
    padding-top: min(22.8355vh, 211px);
  }

  .feedback {
    height: auto;
    width: min(84.5433vw, 361px);
    padding: 20px min(9.3677vw, 40px);
    margin-bottom: 100px;
  }

  .feedback-title {
    font-size: min(5.6206vw, 24px);
    font-weight: 700;
    line-height: min(3.1385vh, 29px);
    margin-bottom: 16px;
  }

  .feedback-desc {
    font-size: min(3.2787vw, 14px);
    font-weight: 400;
    line-height: min(1.8398vh, 17px);
    margin-bottom: 16px;
  }

  .stars-container {
    margin-bottom: 16px;
  }

  .star-img {
    height: min(10.0703vw, 43px);
    width: min(10.0703vw, 43px);
  }

  .feedback-comment {
    height: 255px;
    margin-bottom: 16px;
    font-size: min(3.2787vw, 14px);
    font-weight: 400;
    line-height: min(1.8398vh, 17px);
  }

  .feedback-close-container {
    height: 32px;
    width: 32px;
    top: -15px;
    right: -15px;
  }
}
