@import '../../../resources/theme/Common.scss';

.CPProgressbar-container {
  width: 100%;
  height: 12px;
  border-radius: 20px;
  background: $progress-bar-container;
  box-shadow: $progress-bar-shadow;
  border: $progress-bar-border;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.CPProgressbar-style {
  height: 8px;
  width: 100%;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  border-radius: 20px;
  background: $progress-bar-default;
  margin: 1px;
}

/* small device */
@media screen and (max-width: 430px), screen and (max-height: 500px) {
}

/* landscape*/
@media screen and (max-height: 500px) {
}
