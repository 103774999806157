@import '../../../resources//theme/Common.scss';

.mobileCodeBottomModal-title {
    color: $signIn-modal-title;
    font-size: 14px;
    line-height: 17px;
    margin: 76.79px 0 11.21px 0;
}
  
.mobileCodeBottomModal-select-container {
    height: 200px;
    width: 100%;
    padding: 0 0 30px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
  
.mobileCodeBottomModal-select-style {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}

.mobileCodeBottomModal-selected-container {
    width: 320px;
    height: 35px;
    border-radius: $btn-border-radius;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    color: $signIn-selected-txt;
    font-size: 14px;
    line-height: 17px;
}
  
.mobileCodeBottomModal-selected-bg {
    background: $signIn-selected-bg;
    font-weight: 700;
}
  
.mobileCodeBottomModal-unselect-bg {
    background: transparent;
    box-shadow: none;
}
  
.mobileCodeBottomModal-country-icon {
    height: 18px;
    width: 18px;
    margin-right: 10px;
}