
@import '../../../resources/theme/Common.scss';

.nav-brand-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
}

.nav-brand-icon {
    height: 38px;
    object-fit:contain;
}

.nav-brand-name {
    font-family: $fontFamily;
    font-style: $fontStyleNormal;
    font-weight: $fontWeight700;
    font-size: $fontSize16;
    line-height: $fontHeight20;
    color: $main-txt;
}

@media screen and (max-width: 457px) {
    .nav-brand-icon {
        height: 28px;
    }
}