@import '../../resources/theme/Common.scss';

.mission-mainContainer {
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  background: $mission-bg;
  width: 100%;
}

.mission-style {
  flex-direction: column;
  width: 100%;
  max-width: 468px;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  height: 100%;
  background: $mission-bg;
}

.mission-sticky-header {
  width: 100%;
  position: sticky;
  top: var(--top-header-height);
  z-index: 1;
}

.mission-tab {
  position: relative;
}

.mission-status-container {
  width: 100%;
  max-width: 468px;
  height: 92px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  // position: fixed;
  background: $mission-bg;
}

.mission-status-status-style {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  background: $mission-bg;
  height: 60px;
}

.mission-status-txt {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: $mission-status;
  margin: 0 10px 0 0;
  background: $mission-bg;
}

.mission-status {
  background: $mission-badge-bg;
  border-radius: 60px;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mission-status-num {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: $mission-status-num;
}

.mission-amt-container {
  min-height: 60px;
  width: 50%;
  margin-right: 5px;
  background: $mission-amt-bg;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  padding: 11px 18px 11px 18px;
  box-shadow: $mission-amt-shadow;
  border: $mission-amt-border;
  margin-bottom: 8px;
}

.mission-amt-style {
  height: 100%;
  width: 75%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
}

.mission-amt-txt {
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: $mission-amt-title-txt;
}

.mission-amt-amt-txt {
  margin-top: 6px;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: $mission-amt-amt;
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mission-amt-reload-container {
  height: 100%;
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mission-amt-reload-icon {
  height: 25px;
  width: 25px;
}

.mission-listing-container {
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
}

.mission-listing-style {
  width: 100%;
  border-radius: 10px;
  border: $mission-listing-border;
  position: relative;
  justify-content: center;
  align-items: center;
  display: flex;
  margin: 0 0 32px 0;
  background: $mission-list-bg;
}

.mission-flatlist {
  padding: 0px 0 100px 0 !important;
}

.mission-cover-container {
  min-height: 216px;
}

.mission-list-container {
  min-height: 107px;
}

.mission-line {
  height: 1px;
  width: 100%;
  background: $mission-listing-middle-line;
  position: absolute;
}

.mission-cover {
  height: 50%;
  width: 100%;
  position: absolute;
  top: 0;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.mission-listing-content-container {
  width: 100%;
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 16px;
}

.mission-content-style {
  height: 100%;
  width: 62%;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: flex-start;
}

.mission-reward-container {
  width: 38%;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: flex-end;
}

.mission-reward-style {
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 5px;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: $mission-reward;
}

.mission-btn {
  height: 32px;
  width: 146px;
  background: $mission-btn;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: $mission-btn-border;
}

.mission-btn-txt {
  font-size: 16px;
  color: $mission-btn-txt;
  font-weight: 700;
  line-height: 20px;
}

.mission-label {
  animation: missionLabel 5s both infinite;
}

.mission-no-progress {
  background: $mission-noProgress-bg;
  box-shadow: $mission-noProgress-shadow;
  border: $mission-noProgress-border;
  color: $mission-noProgress-txt;
}

.mission-in-progress {
  background: $mission-inProgress-bg;
  box-shadow: $mission-inProgress-shadow;
  border: $mission-inProgress-border;
  color: $mission-inProgress-txt;
}

.mission-claimed {
  background: $mission-claimed-bg;
  box-shadow: $mission-claimed-shadow;
  border: $mission-claimed-border;
  color: $mission-claimed-txt;
}

.mission-content-title-container {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: left;
  white-space: nowrap;
  width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 8px;
  color: $mission-content-title-txt
}

.mission-content-desc-container {
  text-align: left;
  width: 98%;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: $mission-content-desc-txt
}

.mission-popup-style {
  width: 100%;
  max-width: 428px;
  height: 600px;
  border: $mission-popup-border;
  box-shadow: $mission-popup-shadow;
  border-radius: 30px;
  position: relative;
  background: $mission-popup-bg;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.mission-cancel-btn {
  font-weight: 500;
  font-size: 16px;
  line-height: 17px;
  text-decoration-line: underline;
  color: #ffffff;
  text-shadow: $mission-popup-txt-cancel-shadow;
  margin-top: 16px;
  text-align: center;
}

.mission-top-bg {
  height: 132px !important;
  width: 100%;
  position: relative;
}

.mission-popup-cover {
  width: 100%;
  height: 100%;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
}

.mission-record-container {
  height: auto !important;
}

.mission-record-item-left {
  color: $mission-record-date;
}

.mission-record-status-container {
  flex-direction: row;
  padding: 0 5px 0 5px;
  border-radius: 10px;
  justify-content: flex-end;
  align-items: center;
  display: flex;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: $mission-record-txt;
  background: $mission-record-status-bg;
}

.mission-record-status-icon {
  margin-right: 8px !important;
  height: 8px;
  width: 8px;
  border-radius: 60px;
  margin: 0 5px 0 0;
  background: $mission-record-txt;
}

.mission-coin {
  height: 30px;
  width: 30px;
  margin-right: 5px;
}

.mission-progress {
  position: absolute;
  bottom: -5px;
  width: 100%;
  padding: 0 20px 0 20px;
}

.mission-progress-style {
  width: 100%;
}

.mission-popup-title {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: $mission-popup-title;
  padding: 20px 32px;
  text-shadow: $mission-popup-txt-shadow;
  position: absolute;
  display: flex;
  top: 0px;
  background: $mission-cover-gradient;
  height: 100%;
  width: 100%;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  border-bottom: $mission-popup-activity-container-border;
  backdrop-filter: blur(3px);
}

.mission-popup-label {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: $mission-popup-label;
}

.mission-popup-label-item {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: $mission-popup-label-item;
}

.mission-popup-label-style {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.mission-popup-progress {
  margin: 10px 0;
}

.missionReward-btn-container {
  position: absolute;
  bottom: -20px;
  margin-left: unset;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.missionReward-btn {
  width: 85%;
  background: $mission-popup-reward-bg;
}

.missionReward-icon {
  height: 40px;
  width: 35px;
}

.missionReward-txt {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: $mission-popup-reward;
}

.mission-popup-content {
  height: 280px;
  width: 100%;
  padding: 0 0 30px 0;
  margin: 25px 0 0 0;
  position: relative;
  overflow-y: scroll;
}

.mission-popup-content::-webkit-scrollbar-track {
  background: $mission-popup-scrollbar-track;
  border-radius: 10px;
}

.mission-popup-content::-webkit-scrollbar-thumb {
  background: $mission-popup-scrollbar-thumb;
  border-radius: 10px;
}

.mission-popup-content::-webkit-scrollbar-thumb:hover {
  background: $mission-popup-scrollbar-thumb-hover; /* Change the color when the scrollbar thumb is hovered */
}

.mission-popup-gradient {
  width: 100%;
  padding-top: 40px;
  background: $mission-gradient;
  position: relative;
  bottom: 20px;
}

.mission-popup-info {
  width: 100%;
  text-align: left;
  white-space: pre-line !important;
  word-break: break-word;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: $mission-popup-info;
  margin-top: 25px;
}

.mission-popup-txt {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: $mission-popup-txt;
}

.mission-popup-no-progress-txt {
  color: $mission-noProgress-txt;
}

.mission-popup-in-progress-txt {
  color: $mission-inProgress-txt;
}

.mission-popup-claimed-txt {
  color: $mission-claimed-txt;
}
.mission-popup-claimNow-txt {
  color: $mission-label-default;
}

.mission-more {
  color: $mission-more;
}

.mission-record-icon {
  height: 45px;
  width: 43px;
}

.mission-reward-dialog {
  color: $mission-reward-txt;
  font-size: 32px;
  font-weight: 700;
  line-height: 39px;
  letter-spacing: 0em;
}

.mission-popup-bottom-container {
  padding: 20px 32px 0 32px;
  width: 100%;
  border-top: $mission-popup-bottom-container-top-border;
}

.mission-activity-container {
  height: auto;
  width: 100%;
  background: $mission-popup-activity-container-bg;
  border: $mission-popup-activity-container-border;
  border-radius: 10px;
  padding: 14px;
}

.mission-close-container {
  height: 32px;
  width: 32px;
  position: absolute;
  top: -10px;
  right: -10px;
  cursor: pointer;
}

/* small device */
@media screen and (max-width: 430px), screen and (max-height: 500px) {
  .mission-status-txt {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
  }

  .mission-status {
    height: 28px;
    width: 28px;
  }

  .mission-amt-container {
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    padding: 5px 5px 5px 8px;
  }

  .mission-amt-reload-icon {
    height: 22px;
    width: 22px;
  }

  .mission-amt-amt-txt {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    width: 90px;
  }

  .mission-amt-txt {
    font-weight: 400;
    font-size: 9px;
    line-height: 12px;
  }

  .mission-cover-container {
    min-height: 188px;
  }

  .mission-list-container {
    min-height: 90px;
  }

  .mission-content-title-container {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }

  .mission-content-desc-container {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
  }

  .mission-btn {
    height: 30px;
    width: 100%;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    border-radius: 5px;
  }

  .mission-btn-txt {
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
  }

  .mission-coin {
    height: 18px;
    width: 18px;
  }

  .mission-reward-style {
    padding: 0px;
    font-weight: 700;
    font-size: 13px;
    line-height: 20px;
  }

  .mission-listing-content-container {
    padding: 10px;
  }

  .mission-popup-style {
    max-width: unset;
    border-radius: 25px;
  }

  .mission-top-bg {
    width: 100%;
    background-size: 361px 244px;
    position: relative;
  }

  .missionReward-btn-container {
    bottom: -17.5px;
  }

  .mission-popup-label {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    white-space: nowrap;
  }

  .mission-popup-label-item {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    white-space: wrap !important;
    word-break: break-word;
  }

  .mission-popup-info {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
  }

  .missionReward-icon {
    height: 30px;
    width: 25px;
  }

  .missionReward-txt {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
  }

  .mission-record-icon {
    height: 32px;
    width: 28px;
  }

  .mission-reward-dialog {
    font-size: 27px;
    font-weight: 700;
    line-height: 33px;
  }
}

@keyframes missionLabel {
  0% {
    opacity: 0;
  }

  5%,
  80% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
