@import '../../resources/theme/Common.scss';

.play-container::-webkit-scrollbar {
  background: transparent;
  width: 0px;
  display: none;
}

.play-container {
  height: 100vh;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.play-style {
  position: fixed;
  flex-direction: column;
  top: 48px;
  bottom: 0;
  width: 100%;
}

.play-iframe {
  overflow: scroll;
  height: 100%;
  width: 100%;
}

