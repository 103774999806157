@import '../../../resources/theme/Common.scss';

.signUp-modal-main-container {
    width: 100%;
    max-width: 686px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    position: relative;
}

.signUp-modal-container {
    min-height: 600px;
    width: 100%;
    background: $signUp-modal-bg;
    box-shadow: $signUp-modal-shadow;
    border: $signUp-modal-border;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 32px 50px;
}

.signUp-modal-close-icon {
    object-fit: contain;
    height: 42px;
    width: 42px;
    position: absolute;
    top: 8px;
    right: 8px;
}

.signUp-modal-step-icon {
    object-fit: contain;
    height: 45px;
    max-height: 10%;
    width: 160px;
    max-width: 100%;
}


.signUp-modal-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: $signUp-title;
    overflow-wrap: anywhere;
}
  
.signUp-modal-verify-mobile-logo {
    height: 55.56px;
    width: 62.01;
    margin: 48.01px 0 42.98px 0;
}
  
.signUp-modal-tips {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: $signUp-tips;
}
  
.signUp-modal-mobile {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: $signUp-mobile;
    padding: 14px 0px 52px 0px;
}
  
.signUp-modal-otp-container {
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.signUp-modal-otp-container::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
  
.signUp-modal-prefix {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: $signUp-prefix;
    margin-right: 6.3px;
}
  
.signUp-modal-otp-input {
    width: 25px !important;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    border-color: transparent;
    border-bottom: 1px solid $signIn-otp-underline;
    background: transparent;
    color: $signIn-otp-underline;
}
  
.signUp-modal-otp-group {
    display: flex;
    width: 100%;
    max-width: 360px;
    justify-content: space-between;
}
  
.signUp-modal-timer {
    flex-direction: row;
    display: flex;
    padding: 35px 0px;
}
  
.signUp-modal-countdown {
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 17px !important;
    color: $signUp-timer;
}
  
.signUp-modal-verify-btn-bg {
    background: $signUp-verify-btn-bg;
    box-shadow: $signUp-verify-btn-shadow;
    border: $signUp-verify-btn-border;
}
  
.signUp-modal-verify-btn {
    margin: 0 0 20px 0;
}
  
.signUp-modal-disabled-btn {
    color: white;
    background: $signUp-disabled-verify-btn-bg;
    border: $signUp-disabled-verify-btn-border;
}

.signUp-modal-disabled-resend-btn {
    background: $signIn-disabled-resend-btn;
    border: $signIn-disabled-resend-border;
}
  
.signUp-modal-txt {
    font-weight: 700;
    font-size: 16px !important;
    line-height: 20px;
    margin: 11px;
    color: $signUp-verify-btn-txt;
}

.signUp-modal-verify-disabled-txt {
    color: $signUp-disabled-verify-btn-txt;
}

.signUp-modal-resend-txt {
    font-weight: 700;
    font-size: 16px !important;
    line-height: 20px;
    margin: 11px;
    color:$signUp-resend-txt;
}

.signUp-modal-disabled-txt {
    color: $signIn-disabled-resend-txt;
}
  
.signUp-modal-resend-btn {
    border: $signUp-resend-border;
    background: $signUp-resend-btn;
}
  
.signUp-modal-timer-btn {
    box-shadow: $signUp-resend-btn-shadow;
}
  