@import '../../resources/theme/Common.scss';

// :root {
//     --top-header-padding-bottom: 144px;
// }

.home-container {
    width: 100%;
}

.game-top {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    flex-direction: column;
    background: $game-top-bg;
    align-items: center;
    box-shadow: $game-top-shadow;
    padding: 24px 23px 0px 26px;
    gap: 10px;
    z-index: $header-footer-zIndex;
}

.game-top-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    width: 100%;
    max-width: $game-ipad-size-max-width;
}

.game-top-left-section-container {
    display: flex;
    align-items: center;
    gap: 16px;
}

.game-brand-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
}

.game-top-avatar-icon {
    width: 38px;
    height: 38px;
    object-fit:contain;
}

.game-top-brand-name {
    font-family: $fontFamily;
    font-style: $fontStyleNormal;
    font-weight: $fontWeight700;
    font-size: $fontSize16;
    line-height: $fontHeight20;
    color: $main-txt;
}

.game-top-right-section-container {
    display: flex;
    flex-direction: row;
    gap: 21px;
    align-items: center;
}

.game-top-menu-img {
    width: 26px;
    height: 22px;
    object-fit: contain;
}

.game-top-close-icon {
    width: 32px;
    height: 32px;
    object-fit: contain;
}

.game-marquee-container {
    height: auto;
    width: 100%;
    max-width: $game-ipad-size-max-width;
    margin-bottom: 16px;
}

.layout-page-content {
    width: 100%;
    padding: var(--top-header-height) 0 var(--footer-height) 0;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.iframe-layout-page-content {
    height: 100%;
    width: 100%;
}

// .dimmed-container {
//     top: var(--top-header-height);
//     bottom: var(--footer-height);
//     left: 0;
//     right: 0;
//     position: fixed;
//     background: $plane-bg;
//     backdrop-filter: $plane-blur;
//     z-index: $focus-effect-zIndex;
//     display: var(--dimmedDisplay);
// }

@media screen and (max-width: 415px) {
    .game-top-right-section-container {
        gap: 10px;
    }
}

@media print {
    .game-top {
        display: none;
    }

    .layout-page-content {
        padding: 0px;
    }
}
