@import '../../resources/theme/Common.scss';

.verifyMobile-container {
  width: 100%;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  padding-bottom: 32px;
}

.verifyMobile-style {
  flex-direction: column;
  width: 100%;
  max-width: 468px;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
}

.verifyMobile-title-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 49px;
  padding-bottom: 21px;
  gap: 39px;
}

.verifyMobile-title {
  color: $verifyMobile-title;
  font-family: $fontFamily;
  font-size: $fontSize24;
  font-weight: $fontWeight700;
}

.verifyMobile-step-img {
  height: 32px;
  object-fit: contain;
}

.verifyMobile-content-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-bottom: 99px;
}

.verifyMobile-sendOTP-section {
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: space-between;
}

.verifyMobile-countdown-section {
  display: flex;
  flex-direction: row;
  gap: 0px;
}

.verifyMobile-desc-section {
  display: flex;
  flex-direction: column;
}

.verifyMobile-otp-desc {
  color: $verifyMobile-otp-des;
  font-family: $fontFamily;
  font-size: $fontSize16;
  font-weight: $fontWeight400;
  line-height: normal;
}

.verifyMobile-otp-container {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: flex-start;
}

.verifyMobile-otp-code {
  padding: 17px 13px;
  background: $verifyMobile-otp-code-bg;
  border-radius: 10px;
  color: $verifyMobile-otp-code;
  text-align: center;
  font-family: $fontFamily;
  font-size: $fontSize20;
  font-weight: $fontWeight700;
  line-height: normal;
}
