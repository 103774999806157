@import '../../../../resources/theme/Common.scss';

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner-container {
  height: 100vh;
  width: 100vw;
  z-index: 99999;
  justify-content: center;
  align-items: center;
  display: flex;
  background: $loading-bg;
  backdrop-filter: blur(8px);
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
}

.spinner-style {
  height: 100vh;
  width: 100vw;
  position: relative;
  display: flex;
  background: transparent;
}

.loading-spinner-gif {
  width: 100px;
  height: 100px;
}

@media screen and (max-width: 400px) {
  .loading-spinner-gif {
    width: 50px;
    height: 50px;
  }
}
