@import '../../resources/theme/Common.scss';

.wheel-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.wheel-fortune-wheel {
  height: 35vmin;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.wheel-wheel {
  height: 35vmin;
  position: absolute;
}

.wheel-spotlight {
  height: 100vh;
  width: 100vw;
  position: absolute;
}

.wheel-backlight {
  height: 50vmin;
  position: absolute;
}

.wheel-coin {
  height: 50vmin;
  position: absolute;
}

.wheel-pointer {
  height: 4vmin;
  top: 0;
  position: absolute;
}

.wheel-flashlight {
  position: absolute;
}

.wheel-spin-btn {
  height: 73px;
}

.wheel-cancel-btn {
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  align-self: center;
  text-decoration: underline;
  margin-top: 25px;
  color: $wheel-cancel-txt;
}

.wheel-dialog {
  height: auto;
  width: auto;
  max-width: 350px;
  position: absolute;
  top: 50%;
  background: $wheel-dialog-bg;
  border: $wheel-dialog-border;
  border-radius: $main-radius;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  padding: 10px;
}

.wheel-info {
  padding: 10px;
}

.wheel-title {
  font-size: 24px;
  color: $wheel-dialog-title;
  font-weight: bold;
  margin: 10px 0 0 0;
  font-style: italic;
  text-align: center;
}

.wheel-desc {
  font-size: 18px;
  color: $wheel-dialog-desc;
  margin: 8px 0 8px 0;
}

.wheel-total {
  font-size: 36px;
  color: $wheel-dialog-total;
  font-weight: bold;
}

.wheel-ok-icon {
  height: 45px;
  width: 101px;
  margin: 20px 0 0px 0;
}

.wheel-spin-container {
  top: 75%;
  position: absolute;
  flex-direction: column;
  display: flex;
}

.wheel-info-icon {
  height: 50px;
  width: 50px;
  position: absolute;
  top: 10%;
  right: 5%;
  display: flex;
}

.wheel-info-title {
  color: $wheel-info;
  font-size: 22px;
  text-align: center;
  padding-top: 10px;
  color: $wheel-dialog-title;
}

.wheel-info {
  color: $wheel-info;
  font-size: 16px;
  text-align: center;
  padding: 10px;
  white-space: pre-wrap;
}

.wheel-info-check {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: $wheel-info-check;
  font-size: 14px;
  margin: 10px 0 10px 0;
}

.wheel-close {
  height: 32px;
  width: 32px;
  position: absolute;
  right: -15px;
  top: -15px;
}

.wheel-check-icon {
  height: 25px;
  width: 25px;
  margin-right: 10px;
}

.wheel-info-dialog {
  margin-bottom: 10px;
}

@media screen and (max-width: 430px), screen and (max-height: 500px) {
  .wheel-fortune-wheel {
    height: 60vmin;
  }

  .wheel-wheel {
    height: 60vmin;
  }

  .wheel-coin {
    height: 85vmin;
    position: absolute;
  }

  .wheel-dialog {
    max-width: 300px;
    top: 50%;
  }

  .wheel-pointer {
    height: 5vmin;
    top: 0;
    position: absolute;
  }

  .wheel-total {
    margin: 5px 0 5px 0;
  }

  .wheel-spin-btn {
    height: 53px;
  }

  .wheel-info-icon {
    height: 30px;
    width: 30px;
    top: 10%;
    right: 5%;
  }

  .wheel-info {
    font-size: 14px;
    padding: 10px;
  }

  .wheel-info-check {
    margin-top: 5px;
    font-size: 13px;
    margin-bottom: 10px;
  }

  .wheel-close {
    position: absolute;
    right: -15px;
    top: -15px;
  }

  .wheel-check-icon {
    height: 20px;
    width: 20px;
  }

  .wheel-info {
    padding: 5px;
    top: 40%;
  }

  .wheel-backlight {
    height: 90vmin;
  }
}

@media only screen and (min-device-width: 280px) and (max-device-width: 1000px) and (orientation: landscape) {
  .wheel-spin-container {
    bottom: -20px;
    position: absolute;
    top: unset;
  }
}
