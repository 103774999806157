@import '../../resources/theme/Common.scss';

.more-slider-container {
  position: fixed;
  // z-index: 2;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  // background: $more-trans-bg;
  // backdrop-filter: blur(10px);
  transform: translateX(100%);
  transition: 0.5s;
  display: none;
}

.more {
  height: 100%;
  width: auto;
}

.more-slider-content {
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  width: 60%;
  max-width: 404px;
  -webkit-animation-name: slideRight;
  -webkit-animation-duration: 0.4s;
  animation-name: slideRight;
  animation-duration: 0.4s;
  background: $more-bg;
}

.more-title-container {
  padding: 20px 10px 20px 32px !important;
  min-height: 246px;
  height: 20%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  background: $more-title-bg;
}

.more-close-btn {
  background: none;
  border: none;
  margin-bottom: 32px;
}

.more-close-icon {
  height: 30px;
  width: 30px;
}

.more-title-txt {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  white-space: wrap !important;
  word-break: break-word;
  color: $more-title;
}

.more-item-container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding: 0 10px 0px 32px;
  overflow-x: scroll;
}

.more-item-style {
  display: flex;
  flex-direction: row;
  margin: 51px 0 0 0;
  justify-content: center;
  align-items: center;
  display: flex;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  white-space: wrap !important;
  word-break: break-word;
  color: $more-item;
}

.more-icon {
  height: 28px;
  width: 28px;
  margin: 0 21px 0 0;
}

.more-lang-txt {
  color: $more-lang-title-txt;
}

/* small device */
@media screen and (max-width: 430px), screen and (max-height: 500px) {
  .more-slider-content {
    width: 60%;
    max-width: 204px;
  }

  .more-title-container {
    padding: 20px 10px 20px 20px !important;
    min-height: 150px;
  }

  .more-item-container {
    padding: 0 10px 150px 32px;
  }

  .more-close-btn {
    background: none;
    border: none;
    margin-bottom: 15px;
  }

  .more-close-icon {
    height: 20px;
    width: 20px;
  }

  .more-title-txt {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
  }

  .more-icon {
    height: 22px;
    width: 22px;
    margin: 0 14px 0 0;
  }

  .more-item-style {
    margin: 29px 0 0 0;
    font-weight: 700;
    font-size: 11px;
    line-height: 13px;
  }
}

/* Add Animation */
@-webkit-keyframes slideRight {
  from {
    right: -300px;
    opacity: 0;
  }
  to {
    right: 0;
    opacity: 1;
  }
}

@keyframes slideRight {
  from {
    right: -300px;
    opacity: 0;
  }
  to {
    right: 0;
    opacity: 1;
  }
}
