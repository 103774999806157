@import '../../resources/theme/Common.scss';
:root {
    --side-menu-width: 390px;
}

.side-menu-dimmed-container {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.5s ease-in-out;
    z-index: $header-footer-zIndex;
}

.side-menu-dimmed-container-open {
    opacity: 1;
    pointer-events: unset;
}

.side-menu-container {
    position: relative;
    height: 100%;
    left: calc(-1 * var(--side-menu-width));
    opacity: 0;
    width: var(--side-menu-width);
    background: $side-menu-bg;
    transition: left 0.5s ease-in-out, opacity 0.5s ease-in-out;
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow: auto;
}

.side-menu-container-open {
    left: 0px;
    opacity: 1;
}

.side-menu-nav-container {
    padding: 24px 23px 50px 26px;
    display: flex;
    position: sticky;
    top: 0px;
    flex-direction: column;
    gap: 21px;
    background: $side-menu-nav-bg;
    box-shadow: $side-menu-nav-shadow;
    border: $side-menu-nav-border;
    z-index: 1;
}

.side-menu-nav-horizontal-container {
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;
    justify-content: space-between;
}

.side-menu-left-section-container {
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;
}

.side-menu-brand-txt {
    color: $side-menu-brand-txt !important;
}

.side-menu-close {
    height: 26px;
    width: 26px;
    object-fit: contain;
}

.side-menu-right-section-container {
    display: flex;
    flex-direction: row;
    gap: 14px;
    align-items: center;
}

.side-menu-language-container {
    border: $language-flag-dark-border !important;
    box-shadow: $language-flag-dark-shadow !important;
}

.side-menu-content-container {
    padding: 0px 19px 50px 19px;
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.side-menu-wallet-container {
    background: $side-menu-wallet-bg;
    border: $side-menu-wallet-border;
    box-shadow: $side-menu-wallet-shadow;
}

.side-menu-wallet-name {
    color: $sideMenu-fund-card-name;
}

.side-menu-wallet-bal-title {
    color: $sideMenu-fund-card-bal-title;
}

.side-menu-wallet-bal-amount {
    color: $sideMenu-fund-card-bal-value;
}

.side-menu-wallet-withdraw-bal {
    color: $sideMenu-fund-card-withdraw-bal-value;
}

.side-menu-topup-btn {
    background: $side-menu-topup-bg;
    color: $side-menu-topup-text;
    box-shadow: $side-menu-topup-shadow;
    border: $side-menu-topup-border;
    font-size: $fontSize20;
    padding: 15px;
}

.side-menu-withdraw-btn {
    background: $side-menu-withdraw-bg;
    color: $side-menu-withdraw-text;
    box-shadow: $side-menu-withdraw-shadow;
    border: $side-menu-withdraw-border;
    font-size: $fontSize20;
    padding: 15px;
}

.side-menu-item-group {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.side-menu-wallet-action-container {
    display: flex;
    flex-direction: row;
    gap: 8px;
}

@media screen and (max-width: 576px) {
    :root {
        --side-menu-width: 100%;
    }
}