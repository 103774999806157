@import './resources/theme/Common.scss';
@import './resources/theme/theme-default.scss';
@import './resources/theme/theme-light.scss';
@import './resources/theme/theme-goldMode-default.scss';
@import './resources/theme/theme-brownMode-default.scss';
@import './resources/theme/theme-greenMode-default.scss';
@import './resources/theme/theme-purpleMode-default.scss';
@import './resources/theme/theme-orangeMode-default.scss';
@import './resources/theme/theme-blueMode-default.scss';
@import './resources/theme/theme-blackGoldMode-default.scss';


input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

body {
  color: $main-txt;
  position: relative;
  background: $main-bg;
  font-family: $fontFamily;
  overflow: scroll;
  background-attachment: fixed;
}

.container {
  width: 100%;
  // max-width: 686px; //Base on UI given ipad size
  // min-width: 428px;
  margin: 0 auto;
}

@media screen and (max-width: 700px) {
  .container-padding {
    padding-left: $content-left-right-padding;
    padding-right: $content-left-right-padding;
  }
}

/* small device */
@media screen and (max-width: 430px), screen and (max-height: 500px) {
  .container {
    width: 100%;
    // min-width: 0px;
    height: 100%;
  }
}

/* very small device @ galaxy fold */
@media screen and (max-width: 280px), screen and (max-height: 500px) {
  .container {
    width: 100%;
    // min-width: 280px;
    height: 100%;
  }
}

/* height small than width*/
@media screen and (min-height: 264px) and (min-width: 600px) {
  .container {
    margin: 0 auto;
  }
}

/* landscape*/
@media screen and (max-height: 500px) {
}
