
@import '../../../resources/theme/Common.scss';

.thirdPartyLoginItem-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 8px;
    border: $thirdPartyLogin-border;
    background: $thirdPartyLogin-bg;
    box-shadow: $thirdPartyLogin-shadow;
    border-radius: 6px;
    cursor: pointer;
}

.thirdPartyLoginItem-img {
    height: 26px;
    width: 24px;
    object-fit: contain;
}
