@import '../../../resources/theme/Common.scss';

.button-pagination-listing-container {
    display: flex;
    flex-direction: column;
}

.button-pagination-container {
    background: $main-bg;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 16px;
    position: fixed;
    bottom: var(--footer-height);
    width: 100%;
    left: 0;
    right: 0;
    padding: 32px 30px;
}

.button-pagination-button {
    border-radius: 10px;
    background: $button-pagination-button-bg;
    box-shadow: $button-pagination-button-shadow;
    height: 42px;
    padding: 14px 17px;
}

.button-pagination-button-img {
    height: 100%;
    object-fit: contain;
}

.button-pagination-page-indicator {
    border-radius: 10px;
    background: $button-pagination-page-indicator-bg;
    box-shadow: $button-pagination-page-indicator-shadow;
    border: $button-pagination-page-indicator-border;
    height: 42px;
    padding: 11px 23px;
}

.button-pagination-page-indicator-txt {
    color: $button-pagination-page-indicator-txt;
    font-family: $fontFamily;
    font-size: $fontSize16;
    font-weight: $fontWeight700;
}