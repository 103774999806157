@import '../../resources/theme/Common.scss';

.loginMain-container {
    width: 100%;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
}

.loginMain-style {
    flex-direction: column;
    width: 100%;
    max-width: 468px;
    justify-content: flex-start;
    align-items: center;
    display: flex;
}

.loginMain-sticky-header {
    width: 100%;
    position: sticky;
    top: var(--top-header-height);
    z-index: 1;
    background: $main-bg;
    padding-top: 32px;
    padding-bottom: 21px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.loginMain-step-img-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.loginMain-step-img {
    height: 32px;
    object-fit: contain;
}

.loginMain-empty-img {
    height: 32px;
}