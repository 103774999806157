
@import '../../../resources/theme/Common.scss';


.side-menu-home-container {
    display: flex;
    flex-direction: row;
    width: 142px;
    background: $side-menu-home-bg;
    border: $side-menu-home-border;
    box-shadow: $side-menu-home-shadow;
    gap: 10px;
    justify-content: space-between;
    padding: 9px 16px;
    align-items: center;
    border-radius: 6px;
    overflow: hidden;
}

.side-menu-home-icon {
    height: 23px;
    width: 26px;
    // object-fit: contain;
}

.side-menu-home-text {
    font-family: $fontFamily;
    font-style: $fontStyleNormal;
    font-weight: $fontWeight700;
    font-size: $fontSize12;
    color: $side-menu-home-txt;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: center;
    flex-grow: 1;
}
