@import '../../../resources/theme/Common.scss';

.lineVerMobile-modal-main-container {
    width: 100%;
    max-width: 686px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    position: relative;
}

.lineVerMobile-modal-container {
    min-height: 600px;
    width: 100%;
    background: $lineVerifiedMobile-modal-bg;
    box-shadow: $lineVerifiedMobile-modal-shadow;
    border: $lineVerifiedMobile-modal-border;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 32px 50px;
}

.lineVerMobile-modal-close-icon {
    object-fit: contain;
    height: 42px;
    width: 42px;
    position: absolute;
    top: 8px;
    right: 8px;
}

.lineVerMobile-bottom-modal-title {
    color: $signIn-modal-title;
    font-size: 14px;
    line-height: 17px;
    margin: 76.79px 0 11.21px 0;
}
  
.lineVerMobile-bottom-modal-select-container {
    height: 200px;
    width: 100%;
    padding: 0 0 30px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
  
.lineVerMobile-bottom-modal-select-style {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}

.lineVerMobile-modal-title-container {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    color: $signIn-title;
    font-weight: bold;
    margin: 0px 0 82px 0;
    line-height: 29px;
    text-align: center;
    width: 100%;
    overflow-wrap: anywhere;
}

.lineVerMobile-modal-num-style {
    width: 100%;
    border-bottom: 1px solid $signIn-underline;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 16px;
    color: $signIn-input;
    font-weight: bold;
    padding-bottom: 10.5px;
}


.lineVerMobile-modal-dropdown-mobile {
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: 16px;
    color: $signIn-mobile;
    gap: 14px;
}

.lineVerMobile-dropdown-mobile {
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 16px;
  color: $signIn-mobile;
}

.lineVerMobile-dropdown-icon {
  height: 7px;
  width: 11px;
  margin-left: 5px;
}

.lineVerMobile-input-icon {
  margin-left: 8px;
}


.lineVerMobile-modal-input {
    &:disabled {
      -webkit-text-fill-color: $signIn-input;
      opacity: 1;
    }
    &:focus {
      outline: none;
    }
}
.lineVerMobile-modal-input {
    width: 65% !important;
    background: $signIn-input-bg;
    text-align: left;
    border: none;
    color: $signIn-input;
    margin-left: 9px;
    font-size: 16px;
}

.lineVerMobile-modal-btn {
    background: $signIn-btn;
}

.lineVerMobile-modal-btn-style {
    width: 100%;
    margin-top: 32px;
    height: 42px;
    min-height: 42px;
    font-size: 16px;
    line-height: 20px;
    box-shadow: $signIn-btn-shadow;
}


.lineVerMobile-modal-disabled-btn {
    color: white;
    background: $signIn-disabled-btn;
    border: $signIn-disabled-border;
}

.lineVerMobile-modal-txt {
    font-weight: 700;
    font-size: 16px !important;
    line-height: 20px;
}
