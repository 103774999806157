@import '../../resources/theme/Common.scss';

.permanent-container {
  background: $permanent-bg;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 300px;
  flex-direction: column;
}

.permanent-stage-container {
  background-image: url('../../resources/image/asset/promo/permanentAngpao/stage.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 150px;
  width: 250px;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  animation: permanentStage 0.5s;
  transform: scale(1);
}

.permanent-label-icon {
  height: 50px;
  width: 200px;
  animation: 0.5s linear 1s permanentLabel forwards;
  opacity: 0;
}

.permanent-stageFront-icon {
  height: 100px;
  width: 100%;
  position: absolute;
  bottom: 20px;
}

.permanent-angpao-icon {
  width: 250px;
  position: absolute;
  bottom: 30px;
  animation: 0.5s linear 0.5s permanentAngpao forwards;
  opacity: 0;
}

.permanent-smallAngpao-icon {
  height: 180px;
  width: 250px;
  animation: 0.5s linear 1s permanentSmallAngpaoZoom forwards;
  opacity: 0;
}

.permanent-light {
  height: 400px;
  width: 400px;
  position: absolute;
  margin-bottom: 100px;
  animation: 0.5s linear 0.1s permanentSmallAngpaoZoom forwards,
    permanentLight 100s linear infinite;
  opacity: 0;
  display: none;
}

.permanent-amt {
  font-size: 30px;
  color: $permanent-amt;
  position: absolute;
  top: 15px;
  font-weight: bold;
  display: none;
}

/* small device */
@media screen and (max-width: 430px), screen and (max-height: 500px) {
  .permanent-container {
    justify-content: center;
    padding-bottom: 0px;
  }
}

@keyframes permanentSmallAngpaoZoom {
  from {
    transform: scale(0);
    opacity: 1;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes permanentSmallAngpaoHeartbeat {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  30% {
    opacity: 1;
    transform: scale(0.9);
  }

  35% {
    opacity: 1;
    transform: scale(1);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes permanentAngpao {
  0% {
    height: 0px;
    opacity: 1;
  }
  100% {
    height: 280px;
    opacity: 1;
  }
}

@keyframes permanentLabel {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes permanentStage {
  0% {
    transform: scale(10);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes permanentShakeAngpao {
  0% {
    transform: scale(1.1);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes permanentChoiShen {
  0% {
    height: 380px;
    width: 350px;
    opacity: 1;
  }
  100% {
    height: 280px;
    width: 250px;
    opacity: 1;
  }
}

@keyframes permanentLight {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
