@import '../../../resources/theme/Common.scss';

.change-lang-container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.change-lang-title {
  color: $signIn-modal-title;
  font-size: 14px;
  line-height: 17px;
  margin: 76.79px 0 11.21px 0;
}

.change-lang-select-container {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.change-lang-label {
  width: 320px;
  height: 35px;
  border-radius: $btn-border-radius;
  background: $cplang-bg;
  display: flex;
  justify-content: center;
  align-items: center;
}

.change-lang-icon {
  height: 18px;
  width: 18px;
  margin: 0 10px 0 0;
}

.change-lang-item-txt {
  font-size: 14px;
  line-height: 17px;
}

.change-lang-item-txt-selected {
  font-weight: 700;
}
