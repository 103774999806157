.theme-blackGoldMode-default {
    // Common
    --pagePadding: 10px 20px 10px 20px;
    --main-border-size: 1px;
    --main-border: var(--main-border-size) solid #707070;
    --main-shadow: 3px 3px 4px 0px #131617;
    --gray-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
    --border: #707070;
    --main-radius: 10px;
    --strong: #ff0000;
    --transparent: rgba(0, 0, 0, 0.5);

    //banner slider
    --banner-indicator-active: white;
    --banner-indicator-inactive: gray;

    //background
    --main-bg: #111010;
    --wallet-bg: linear-gradient(108deg, #F5DF82 2.04%, #B67A2A 98.41%);
    --sub-bg: #000000;
    --main-modal-bg: rgba(0, 0, 0, 0.25);
    --bottom-modal-bg: rgba(255, 255, 255, 0.8);
    --main-inpage-modal-bg: 'transparent';
    --bank-bg: #ffffff;
    --main-bottom-shadow: linear-gradient(
    180deg,
    rgba(28, 33, 49, 0) 0%,
    var(--main-bg) 24.22%,
    );
    --new-sub-bg: #141723;

    //text
    --fontFamily: 'Helvetica Neue';
    --fontWeight700: 700;
    --fontWeight400: 400;
    --fontStyleNormal: normal;
    --fontSize10: 10px;
    --fontSize12: 12px;
    --fontSize14: 14px;
    --fontSize16: 16px;
    --fontSize20: 20px;
    --fontSize24: 24px;
    --fontSize30: 30px;
    --fontHeight15: 15px;
    --fontHeight20: 20px;
    --main-txt: #ffffff;
    --sub-txt: #000000;
    --theme-txt: #000000;
    --main-placeholder: #707070;
    --tips-txt: #EC1C23;
    --light-gray-txt: #C4C5C8;
    --gold-txt: #FAD071;
    --provider-banner-txt: #898E8C;

    // tab
    --tab-txt-selected: #ffffff;
    --tab-txt-unSelected: #707070;
    --tab-selected-bg: linear-gradient(93deg, #E38642 0.48%, #D81414 100.37%);
    --form-radius: 30px;
    --form-border: var(--main-border);
    --form-shadow: var(--main-shadow);
    --form-bg: #161a23;
    --form-label: var(--main-txt);
    --form-item-bg: var(--main-bg);
    --form-item-radius: 30px;
    --form-item-border: var(--main-border);
    --form-item-shadow: var(--main-shadow);
   
   
    //#endregion

    //#region =====> Component

    // Fortunel Wheel
    --wheel-dialog-bg: #141723;
    --wheel-dialog-border: var(--main-border);
    --wheel-dialog-title: #ffe045;
    --wheel-dialog-desc: var(--main-txt);
    --wheel-dialog-total: #fdc46f;
    --wheel-info: var(--main-txt);
    --wheel-info-check: var(--main-txt);
    --wheel-cancel-bg: #161a23;
    --wheel-cancel-txt: #FFFFFF;

    // Permanent Angpao
    --permanent-bg: rgba(0, 0, 0, 0.1);
    --permanent-amt: #fad105;

    // Invite Angpao
    --invite-bg: rgb(26, 26, 26);
    --invite-numbering: #fad105;
    --invite-receive: #9c0000;
    --invite-dialog-bg: #9a0100;
    --invite-dialog-border: 1px solid #fdc46f;
    --invite-dialog-title: #fdc46f;
    --invite-dialog-desc: #fdc46f;
    --invite-dialog-checkTime: #fdc46f;
    --invite-dialog-time: #fdc46f;

    //Timer
    --timer-txt: #FFFFFF;

    //Header
    --header-bg: #151F3F;
    --header-shadow: 0px 2px 5px 0px #131617;    
    --header-txt: var(--main-txt);

    //CPPin
    --cppin-field-border: 1px solid #FFFFFF;
    --cppin-field-active-border: 1px solid #FFFFFF;
    --cppin-field-bg: transparent;
    --cppin-active-field: #FFFFFF;
    --cppin-btn-bg: #FFFFFF;
    --cppin-shadow: 4px 4px 4px rgba(0, 0, 0, 0.30);
    --cppin-btn-cursor: #FFFFFF;
    --cppin-btn-txt: #131617;

    //CPLang
    --cplang-bg: rgba(255, 255, 255, 0.6);

    // CPBottomModal
    --bottomModal-container-bg: var(--transparent);
    --bottomModal-black-bg: #FFFFFF33;

    // CPDropdown
    --dropdown-bg: #2E2D2C;
    --dropdown-shadow: 4px 4px 4px rgba(0, 0, 0, 0.75);
    --dropdown-border: 1px solid #898E8C;
    --dropdown-inner-bg: #ffffff;
    --dropdown-inner-shadow: var(--main-shadow);
    --dropdown-inner-label-container-bg: #ffffff;
    --dropdown-right-label: black;
    --dropdown-list-bg: black;
    --dropdown-list-item-bg: #ffffff;
    --dropdown-list-item-shadow: var(--main-shadow);
    --dropdown-list-item-txt: black;
    --dropdown-placeholder: #FFFFFF;

    // CPButton
    --btn-main: linear-gradient(108deg, #F5DF82 2.04%, #B67A2A 98.41%);
    --btn-sub: linear-gradient(to right, #d7437c, #f6b56b);
    --btn-disabled: linear-gradient(92deg, #837447 0.35%, #6D5128 98.37%);
    --btn-disabled-border: none;
    --btn-disabled-txt: #0F0F0F;
    --btn-shadow: var(--main-shadow);
    --btn-deco: var(--main-bg);
    --btn-txt: var(--main-txt);
    --btn-border-radius: 10px;
    --btn-radius: 10px;
    --btn-font: 20px;
    --btn-height: 48px;
    --btn-mobile-height: 35px;
    --btn-width: 280px;

    // CPListTab
    --listTab: var(--main-bg);
    --list-txt: var(--tab-txt-selected);
    --list-unSelect-text: #C9C9C9;
    --list-underline: linear-gradient(108deg, #F5DF82 2.04%, #B67A2A 98.41%);

    // CPDatePicker
    --datePickerSelected: #FFFFFF;
    --datePickerSelected-shadow: none;
    --datePickerSelected-border: none;
    --datePickerBorder: none;
    --datePickerBg: #FFFFFF;
    --datePickerTxt: black;
    --datePickSelectedTxt: #000000;
    --dateShadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.30);
    --dataUnSelectedShadow: 4px 4px 4px rgba(0, 0, 0, 0.75);

    //CPListing
    --listing-border: 1px solid #898E8C;
    --listing-shadow: none;
    --listing-bg: #2E2D2C;
    --listing-item: var(--main-txt);

    //CPProgressBar
    --progress-bar-container: #111010;
    --progress-bar-shadow: 0px 2px 0px 0px #000 inset, 0px 1px 0px 0px #F5DF82;
    --progress-bar-border: none;
    --progress-bar-default: linear-gradient(92.15deg, #34BEFD 0.35%, #AB00FE 98.37%);

    //#region =====> Root

    //Notif
    --notif-shadow: 4px 4px 4px rgba(0, 0, 0, 0.5);
    --notif-bg: #ffffff;
    --notif-msg: var(--sub-txt);
    --notif-btn-bg: #141723;
    --notif-btn-shadow: var(--notif-shadow);
    --notif-btn-txt: var(--main-txt);

    //Info
    --info-bg: #111010;
    --info-title: var(--main-txt);
    --info-desc: var(--main-txt);
    --info-btn-bg: var(--btn-main);
    --info-btn-shadow: var(--main-shadow);
    --info-btn-border: none;
    --info-btn-txt: var(--main-txt);
    --dontShow: var(--main-txt);

    //Plane
    --plane-bg: rgba(0, 0, 0, 0.25);
    --plane-blur: blur(10px);

    //Bank
    --root-bank-bg: rgba(0, 0, 0, 0.5);
    --root-bank-shadow: none;
    --root-bank-border: 1px solid #898E8C;
    --root-bank-title: var(--main-txt);
    --root-bank-tips: var(--tips-txt);
    --root-bank-label: var(--main-txt);
    --root-bank-input-txt: var(--main-txt);
    --root-bank-input-placeholder: #898E8C;
    --root-bank-input-border:1px solid #898E8C;
    --root-bank-input-bg: #2E2D2C;
    --root-bank-input-shadow: none;
    --root-bank-btn-bg: var(--btn-main);
    --root-bank-btn-shadow: none;
    --root-bank-btn-border: none;

    //Dialog
    --dialog-bg: linear-gradient(172deg, #2E2D2C 5.45%, #111010 93.25%);
    --dialog-shadow: 3px 3px 4px 0px #131617;
    --dialog-border: none;
    --dialog-title-txt: var(--main-txt);
    --dialog-content-txt: var(--main-txt);
    --dialog-err: linear-gradient(108deg, #F5DF82 2.04%, #B67A2A 98.41%);
    --dialog-success: linear-gradient(176deg, #00D8FF 3.44%, #0378FF 96.71%);
    --dialog-option: #FFFFFF;
    --dialog-btn-txt: #FFFFFF;
    --dialog-option-bottom: 1px solid #898E8C;

    //Loading
    --loading-bg: rgba(0, 0, 0, 0.5);

    //Toast
    --taost-bg: #FFFFFF;
    --taost-txt: black;
    --toast-desc: black;

    //News
    --news-bg: #002652;
    --news-border: 1px solid #898E8C;
    --news-shadow: 7px 7px 10px 0px rgba(19, 22, 23, 0.50);
    --news-close-bg: #EF4455;
    --news-close-border: none;
    --news-close-shadow: none;
    --news-close-txt: var(--main-txt);
    --news-pagination: rgba(255, 255, 255, 0.5);
    --news-pagination-active: #fff;
    --news-title-txt: var(--main-txt);
    --news-desc-txt: var(--main-txt);
    --news-ok-btn-bg: linear-gradient(108deg, #F5DF82 2.04%, #B67A2A 98.41%);
    --news-ok-btn-shadow: none;
    --news-ok-btn-border: none;
    --news-ok-btn-txt: #000000;

    //Feedback
    --feedback-container-bg: var(--transparent);
    --feedback-bg: linear-gradient(172deg, #2E2D2C 5.45%, #111010 93.25%);
    --feedback-border: 1px solid #898E8C;
    --feedback-comment-bg: #2E2D2C;
    --feedback-comment-border: 1px solid #898E8C;
    --feedback-comment-txt: #fff;
    --feedback-submit-bg: linear-gradient(108deg, #F5DF82 2.04%, #B67A2A 98.41%);
    --feedback-submit-border: none;
    --feedback-submit-shadow: var(--btn-shadow);
    --feedback-submit-disabled-bg: var(--btn-disabled);
    --feedback-submit-disabled-border: var(--btn-disabled-border);
    --feedback-submit-disabled-shadow: none;
    --feedback-submit-txt-disabled: var(--btn-disabled-txt);
    --feedback-submit-txt: var(--btn-txt);

    //#endregion

    //#endregion

    //#region =====> Club
    --club-title: var(--main-txt);
    //#endregion

    //#region =====> Porn
    --porn-content-bg: linear-gradient(108deg, #F5DF82 2.04%, #B67A2A 98.41%);
    --porn-title: #000000;
    --porn-date: #000000;
    --porn-desc: #000000;
    //#endregion

    //#region =====> Movie
    --movie-content-bg: linear-gradient(108deg, #F5DF82 2.04%, #B67A2A 98.41%);
    --movie-imdb-rate: #000000;
    --movie-btn-bg: #ffffff;
    --movie-btn-txt: #000000;
    --movie-detail-bg: #ffffff;
    --movie-bot-border: 1px solid rgba(0, 0, 0, 0.12);
    --movie-rate-blue: #1a0dab;
    --movie-google-users-txt: #70757a;
    --movie-desc: #4d5156;
    --movie-title: #000000;
    --movie-date: #000000;
    --movie-imdb: #000000;
    --movie-popup-title: #000000;
    --movie-imdb-title: #000000;
    //#endregion

    //#region =====> Streaming
    --streaming-title: linear-gradient(132.89deg, #cb2ab8 3.42%, #2700c9 100%);
    --streaming-title-txt: var(--main-txt);
    --streaming-home-bg: #ffffff;
    --streaming-home-shadow: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    --streaming-home-txt: var(--sub-txt);
    --streaming-away-txt: var(--sub-txt);
    --streaming-score-bg: #dedede;
    --streaming-score-txt: var(--sub-txt);
    --streaming-date-bg: #000000;
    --streaming-date-txt: var(--main-txt);
    --streaming-isLive-bg: linear-gradient(135deg, #ff0000 0%, #d3367e 100%);
    --streaming-isLive-txt: var(--main-txt);
    --streaming-coming-bg: linear-gradient(135deg, #ff0000 0%, #d3367e 100%);
    --streaming-coming-txt: var(--main-txt);
    --streaming-play-isLive-bg: linear-gradient(135deg, #28e544 0%, #00a6b4 100%);
    --streaming-play-isLive-txt: var(--main-txt);
    --streaming-watch-txt: #000000;
    --streaming-play-coming-bg: linear-gradient(
    134.29deg,
    #3fcffa 0%,
    #0b0bbe 101.27%
    );
    --streaming-play-coming-txt: var(--main-txt);
    //#endregion

    //#region =====> Sign In & Sign Up
    --signIn-title: #FFFFFF;
    --signIn-underline: #FFFFFF;
    --signIn-otp-input-txt: #FFFFFF;
    --signIn-otp-underline: #FFFFFF;
    --signIn-btn: #FFFFFF;
    --signIn-btn-border: none;
    --signIn-btn-shadow: 2px 2px 4px 0px rgba(252, 81, 106, 0.30);
    --signIn-disabled-btn: #8F8F8F;
    --signIn-desc: #808080;
    --signIn-bg: rgba(255, 255, 255, 0.75);
    --signIn-input: #FFFFFF;
    --signIn-input-placeholder: #898E8C;
    --signIn-mobile: #FFFFFF;
    --signIn-mobile-separator: #898E8C;
    --signIn-input-bg: transparent;
    --signIn-shadow: var(--main-shadow);
    --signIn-disabled-btn-txt: #200A02;
    --signIn-disabled-border: none;
    --signIn-btn-txt: #131617;
    --signIn-help: var(--main-txt);
    --signIn-modal-bg: var(--main-modal-bg);
    --signIn-modal-title: var(--sub-txt);
    --signIn-selected-bg: #ffffff;
    --signIn-selected-txt: var(--sub-txt);
    --signIn-lang-txt: #312d2d;
    --signIn-line-bg: #00c200;
    --signIn-line-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
    --signIn-ver: 'transparent';

    // login with line
    --signIn-login-line-bg: #00c200;
    --signIn-line-icon-bg: rgba(0, 0, 0, 0.16);
    --signIn-line-label: var(--main-txt);
    --signIn-or: #8c9098;
    --signIn-or-border: 1px #8c9098 solid;

    --signUp-title: #FFFFFF;
    --signUp-tips: #FFFFFF;
    --signUp-prefix: #FFFFFF;
    --signUp-mobile: #FFFFFF;
    --signUp-verify-btn-bg: #FFFFFF;
    --signUp-verify-btn-txt: #131617;
    --signUp-disabled-verify-btn-txt: #0F0F0F;
    --signUp-disabled-verify-btn-bg: #8F8F8F;
    --signUp-disabled-verify-btn-border: none;
    --signUp-verify-btn-shadow: 2px 2px 4px 0px rgba(252, 81, 106, 0.30);
    --signUp-verify-btn-border: none;
    --signUp-resend-btn: #041126;
    --signUp-resend-btn-shadow: none;
    --signUp-timer: var(--sub-txt) !important;

    //#endregion

    //#region =====> More

    --more-trans-bg: rgba(0, 0, 0, 0.8);
    --more-bg: #1c2131;
    --more-title: var(--main-txt);
    --more-title-bg: var(--header-bg);
    --more-item: var(--main-txt);
    --more-lang-title-txt: #ffffff !important;

    //#endregion

    //#region =====> Profile

    --profile-banklist: linear-gradient(to right, #350bc8, #8633c3, #b72dbc);
    --profile-banklist-shadow: var(--main-shadow);
    --profile-banklist-txt: var(--main-txt);
    --profile-downline: linear-gradient(to right, #1327bd, #386cd5, #64bdf0);
    --profile-downline-shadow: var(--main-shadow);
    --profile-downline-txt: var(--main-txt);
    --profile-changePwd: linear-gradient(to right, #be2e1e, #d66e36, #f0ba55);
    --profile-changePwd-shadow: var(--main-shadow);
    --profile-changePwd-txt: var(--main-txt);
    --profile-signOut-txt: var(--main-txt);
    --profile-version: var(--main-txt);
    --profile-bg: var(--main-bottom-shadow);
    --profile-banklist-border: none;
    --profile-downline-border: none;
    --profile-changePwd-border: none;

    //#endregion

    //#region =====> Lang

    --lang-modal-bg: rgba(255, 255, 255, 0.878);
    --lang-modal-btn: #ffffff;

    //#endregion

    //#region =====> Mission
    --mission-bg: var(--main-bg);
    --mission-more: #EC1C23;
    --mission-badge-bg: #ff0000;
    --mission-list-bg: linear-gradient(172deg, #2E2D2C 52.3%, #111010 93.25%);
    --mission-listing-middle-line: transparent;
    --mission-btn: #ffffff;
    --mission-btn-txt: #000000;
    --mission-status: #ffffff;
    --mission-status-num: #ffffff;
    --mission-amt-bg: linear-gradient(108deg, #F5DF82 2.04%, #B67A2A 98.41%);
    --mission-amt-shadow: --main-shadow;
    --mission-amt-border: none;
    --mission-amt-amt: #000000;
    --mission-amt-title-txt: #000000;
    --mission-listing-border: --main-border;
    --mission-reward: #f9ce32;
    --mission-noProgress-bg: linear-gradient(108deg, #F5DF82 2.04%, #B67A2A 98.41%);
    --mission-noProgress-txt: #000000;
    --mission-inProgress-bg: linear-gradient(92deg, #34BEFD 0.35%, #AB00FE 98.37%);
    --mission-inProgress-txt: white;
    --mission-claimed-bg: #70c727;
    --mission-claimed-txt: white;
    --mission-noProgress-shadow: none;
    --mission-noProgress-border: none;
    --mission-inProgress-shadow: none;
    --mission-inProgress-border: none;
    --mission-claimed-shadow: none;
    --mission-claimed-border: none;
    --mission-label-default: #ffffff;

    //popup
    --mission-popup-blur-bg: rgba(0, 0, 0, 0.8);
    --mission-popup-border: none;
    --mission-popup-shadow: none;
    --mission-popup-bg-top: #141723;
    --mission-popup-bg: linear-gradient(172deg, #2E2D2C 5.45%, #111010 93.25%);
    --mission-popup-reward-bg: #041126;
    --mission-popup-title: #ffffff;
    --mission-popup-txt-shadow: 2px 2px 2px rgba(0, 0, 0, 0.75);
    --mission-popup-txt-cancel-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
    --mission-popup-label: #ffffff;
    --mission-popup-label-item: #ffffff;
    --mission-popup-reward: #ffffff;
    --mission-popup-info: #ffffff;
    --mission-popup-txt: #ffffff;
    --mission-gradient: var(--main-bottom-shadow);
    --mission-reward-txt: #f9ce32;
    --mission-popup-activity-container-bg: #2E2D2C;
    --mission-popup-activity-container-border: none;
    --mission-cover-gradient: transparent;
    --mission-popup-bottom-container-top-border: none;

    //record

    --mission-record-date: #c9c9c9;
    --mission-record-txt: #ffffff;
    --mission-record-status-bg: #70c71a;

    //#endregion

    //#region =====> Fund

    --fund-progressbar: linear-gradient(310deg, #287CFF 21.42%, #E2EEC8 106.38%);
    --fund-btn-bg: linear-gradient(172deg, #2E2D2C 5.45%, #111010 93.25%);
    --fund-btn-shadow: var(--main-shadow);
    --fund-btn-border: 1px solid #F5DF82;
    --fund-btn-bg-disabled: linear-gradient(92deg, #225F92 0.35%, #560B92 98.37%);
    --fund-btn-border-disabled: none;
    --fund-btn-txt-disabled: #8F8F8F;
    --fund-btn-txt: #FFFFFF;
    --fund-withdraw-btn-bg: linear-gradient(172deg, #2E2D2C 5.45%, #111010 93.25%);
    --fund-withdraw-btn-shadow: var(--fund-btn-shadow);
    --fund-withdraw-btn-border: var(--fund-btn-border);
    --fund-withdraw-btn-bg-disabled: #8F8F8F;
    --fund-withdraw-btn-border-disabled: var(--fund-btn-border-disabled);
    --fund-withdraw-btn-txt-disabled: #0F0F0F;
    --fund-withdraw-btn-txt: #ffffff;
    --fund-btn-detail-bg: linear-gradient(93deg, #E38642 0.48%, #D81414 100.37%);
    --fund-btn-detail-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
    --fund-btn-detail-border: 1px solid #E08735;
    --fund-tips-title: var(--main-txt);
    --fund-tips-txt: #C4C6C6;
    --fund-tips-border: 1px solid #C4C6C6;
    --fund-tips-shadow: 3px 3px 4px 0px #131617;
    --fund-tips-bg: linear-gradient(172deg, #2E2D2C 5.45%, #111010 93.25%);
    --fund-act-icon: none;
    --fund-card-bg: url(../image/asset/wallet/fund.png);
    --fund-card-txt: var(--main-txt);
    --fund-bottom-border: 1px solid #FFF;
    --fund-bottom-shadow: 3px 3px 4px 0px #131617;
    --fund-bottom-bg: #2E2D2C;
    --fund-bottom-style-bg: var(--main-bottom-shadow);
    --fund-bottom-txt: var(--main-txt);
    --fund-tips: var(--strong);
    --fund-rebate-bg: #2E2D2C;
    --fund-rebate-txt: #FFFFFF;
    --fund-rebate-shadow: none;
    --fund-timer-txt: #FFF;

    //#endregion

    //#region =====> Comm (mix with fund)

    --comm-card-bg: url(../image/asset/wallet/comm.png);
    --comm-card-title: #ffffff;
    --comm-card-txt: #ffffff;
    --comm-card-shadow: var(--main-shadow);
    --comm-tips2-txt: #ffffff;
    --comm-btn-shadow: var(--main-shadow);
    --comm-act-icon: none;
    --comm-btn-shadow: var(--main-shadow);
    --comm-btn-txt: #000000;
    --comm-btn-bg: #ffffff;
    --comm-btn-border:none;
    --comm-btn-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.30);
    --comm-list-shadow: none;
    --comm-summ-title: #c9c9c9;
    --comm-summ-green: #70c71a;
    --comm-summ-orange: #ff9345;
    --comm-datePicker-title: var(--sub-txt);
    --comm-datePicker-selected-btn: #000000;
    --comm-datePicker-btn: var(--sub-txt);
    --comm-datePicker-btn-radius: var(--btn-radius);
    --comm-datePicker-selected: #ffffff;

    //#endregion

    //#region =====> Comm2Wallet

    --comm2wallet-bg: linear-gradient(172deg, #2E2D2C 5.45%, #111010 93.25%);
    --comm2wallet-border: 1px solid #898E8C;
    --comm2wallet-label: var(--form-label);
    --comm2wallet-remark: var(--tips-txt);
    --comm2wallet-btn-bg: var(--btn-main);
    --comm2wallet-btn-shadow: var(--main-shadow);
    --comm2wallet-btn-border: none;
    --comm2wallet-btn-txt: var(--btn-txt);
    --comm2wallet-btn-bg-disabled: var(--btn-disabled);
    --comm2wallet-btn-shadow-disabled: var(--main-shadow);
    --comm2wallet-btn-border-disabled: var(--btn-disabled-border);
    --comm2wallet-btn-txt-disabled: var(--btn-disabled-txt);
    --comm2wallet-input-bg: #2E2D2C;
    --comm2wallet-input-shadow: none;
    --comm2wallet-input-border: 1px solid #898E8C;

    //#endregion

    //#region =====> Comm2Bank (mix with Comm2Wallet)

    --comm2bank-btn-shadow: var(--main-shadow);
    --comm2bank-btn-border: none;
    --comm2bank-btn-bg: red;
    --comm2bank-btn-txt: var(--btn-txt);
    --comm2bank-btn-shadow-disabled: var(--main-shadow);
    --comm2bank-btn-border-disabled: none;
    --comm2bank-btn-bg-disabled: var(--btn-disabled);
    --comm2bank-btn-txt-disabled: var(--btn-disabled-txt);

    //#endregion

    //#region =====> Wallet

    --wallet-detail-bal: linear-gradient(to right, #350bc8, #8633c3, #b72dbc);
    --wallet-detail-comm: linear-gradient(to right, #d7437c, #f6b56b);
    --wallet-radius: var(--main-radius);
    --wallet-txt: var(--main-txt);

    //#endregion

    //#region =====> Game List

    --marquee-txt: var(--main-txt);

    //#endregion

    //#region =====> Game List

    --game-container-bg: #141723;
    --game-filter-btn-bg: #2E2D2C;
    --game-filter-btn-container: none;
    --game-filter-shadow: none;
    --game-filter-selected: linear-gradient(108deg, #F5DF82 2.04%, #B67A2A 98.41%);
    --game-filter-selected-border: 1px solid #FDE578;
    --game-filter-selected-shadow: 3px 3px 4px 0px #131617;
    --game-filter-selected-txt: #000000;
    --game-filter-unselect-txt: var(--main-txt);
    --game-filter-disabled-txt: #707070;
    --game-button-common-shadow: var(--gray-shadow);
    --game-provider-bg: var(--main-bg);
    --game-provider-bg-shadow: 4px 4px 4px rgba(0, 0, 0, 0.75);
    --game-provider-btn: black;
    --game-provider-btn-border: none;
    --game-provider-btn-shadow: 4px 4px 4px rgba(0, 0, 0, 0.5);
    --game-provider-btn-bg: #111415;
    --game-provider-shadow: var(--main-shadow);
    --game-provider-border: var(--main-border);
    --game-ipad-size-max-width: 686px;
    --game-option-bg: var(--main-bg);
    --game-option-shadow: inset 0px 11px 8px -7px #141723;
    --game-top-bg: linear-gradient(180deg, #2E2D2C 0%, #111010 100%);
    --game-top-shadow: 0px 2px 5px 0px #131617;
    --game-overlay-background: linear-gradient(180deg, rgba(0, 0, 0, 0) -15.36%, #000000 54.29%);

    //appGame
    --app-game-user-info-background: #FFFFFF;
    --app-game-pw-info-background: rgba(20, 23, 35, 1);
    --app-game-user-info-text-color: rgba(21, 24, 37, 1);
    --app-game-pw-info-text-color: rgba(255, 255, 255, 1);
    --app-game-play-game-button-background: #70C727;
    --app-game-play-game-text: #006C35;
    --app-game-play-game-button-border: 2px solid #70C727;
    --app-game-warning-color: #E0250C;
    --app-game-alert-background: #F0F3FC;
    --app-game-copy-background: rgba(20, 23, 35, 1);

    //#endregion

    //#region =====> Play

    --play-container-bg: var(--main-bg);

    //#endregion

    //#region =====> Deposit

    --auto-title: var(--main-txt);
    --auto-info: var(--main-txt);

    //#endregion

    //#region =====> Deposit

    --deposit-render-border: var(--main-border);
    --deposit-render-shadow: var(--main-shadow);
    --deposit-render-bg: #141723;
    --deposit-title: #ffffff;
    --deposit-status: #70c727;
    --deposit-desc: #ffffff;
    --deposit-inactive: #707070;
    --deposit-status-inactive: #ff0000;

    //#endregion

    //#region =====> Topup

    --topup-cs-txt: #ffffff;
    --topup-cs-bg: #00C200;
    --topup-label: #ffffff;
    --topup-label-amt: #ffffff;
    --topup-amt-remark: #ffffff;
    --topup-cs-btn: 1px solid #70c727;
    --topup-enter-txt: var(--main-txt);
    --topup-tip-bg: var(--main-bg);
    --topup-tip-title: #ff0000;
    --topup-tip-txt: var(--main-txt);
    --topup-tip-border: var(--main-border);
    --topup-input-bottom: solid 1px #c9c9c9;
    --topup-btn-bg: var(--btn-main);
    --topup-btn-shadow: var(--main-shadow);
    --topup-digit: #ff0000;
    --topup-amt-btn-border: var(--main-border);
    --topup-amt-btn-shadow: var(--main-shadow);
    --topup-amt-btn-txt: #ffffff;

    // discount
    --topup-amt-btn: #141723;
    --topup-amt-selected: linear-gradient(to bottom right, #f6b56b, #d7437c);
    --topup-amt-deco: #000000;
    --topup-promo-bg: #c62828;
    --topup-promo-txt: #ffffff;
    --topup-promo-shadow: var(--main-shadow);
    --topup-promo-selected-bg: #ffffff;
    --topup-promo-selected-txt: #c62828;

    // detail
    --topup-detail-amt: #ffffff;
    --topup-detail-inner-bg: #ffffff;
    --topup-detail-inner-shadow: var(--main-shadow);
    --topup-detail-inner-txt: var(--sub-txt);
    --topup-detail-item-txt: var(--sub-txt);
    --topup-detail-enter-label: var(--main-txt);
    --topup-detail-enter-border: var(--border);
    --topup-detail-tips: var(--strong);
    --topup-detail-cancel: #404243;
    --topup-detail-cancel-shadow: var(--main-shadow);
    --topup-detail-home: #0b84fa;
    --topup-detail-home-shadow: var(--main-shadow);
    --topup-detail-bank-acct: #EC1C23;
    //#endregion

    //#region =====> Withdraw

    --wit-selectbank-bg: #ffffff;
    --wit-input-txt: var(--main-txt);
    --wit-input-placeholder: rgba(255, 255, 255, 0.50);
    --wit-btn-bg: var(--btn-main);
    --wit-btn-shadow: var(--btn-shadow);
    --wit-btn-border: none;
    --add-bank-wit-btn-txt: var(--btn-txt);
    --add-bank-wit-btn-txt-disabled: var(--btn-disabled-txt);
    --addBank-btn-shadow: var(--comm2wallet-btn-shadow);
    --addBank-btn-border: var(--comm2wallet-btn-border);
    --addBank-btn-bg: var(--comm2wallet-btn-bg);
    --addBank-btn-shadow-disabled: var(--comm2wallet-btn-shadow-disabled);
    --addBank-btn-border-disabled: var(--comm2wallet-btn-border-disabled);
    --addBank-btn-bg-disabled: var(--comm2wallet-btn-bg-disabled);

    //#endregion

    //#region =====> Transaction

    --trxn-type: var(--main-txt);
    --trxn-amt: var(--main-txt);
    --trxn-date: #c9c9c9;
    --trxn-approve: var(--main-txt);
    --trxn-reject: var(--main-txt);
    --trxn-others: var(--main-txt);
    --trxn-approve-bg: #70c71a;
    --trxn-reject-bg: #dd2f2f;
    --trxn-others-bg: #ff9345;

    // detail
    --trxn-detail-bg: #041126;
    --trxn-detail-border: 1px solid #C4C6C6;
    --trxn-detail-header-bottom-border: 1px solid #C4C6C6;
    --trxn-detail-shadow: none;
    --trxn-detail-amt-bg: #151F3F;
    --trxn-detail-approve: #70c71a;
    --trxn-detail-reject: #dd2f2f;
    --trxn-detail-others: #ff9345;
    --trxn-detail-cancel: #ffffff;
    --trxn-detail-cancel-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);

    //#endregion

    //#region =====> Game Record

    --gameRec-date: var(--main-txt);
    --gameRec-game: var(--main-txt);
    --gameRec-win: var(--main-txt);
    --gameRec-lose: var(--main-txt);
    --gameRec-draw: var(--main-txt);
    --gameRec-winBg: #70c71a;
    --gameRec-loseBg: #dd2f2f;
    --gameRec-drawBg: #ff9345;
    --gameRec-winTxt: #70c71a;
    --gameRec-loseTxt: #dd2f2f;
    --gameRec-drawTxt: #ff9345;

    //#endregion

    //#region =====> Game Record

    --commRec-date: var(--main-txt);
    --commRec-amt: var(--main-txt);
    --commRec-type: var(--main-txt);

    //#endregion

    //#region =====> Info

    --chat-name: var(--main-txt);
    --chat-tab-shadow: var(--main-shadow);
    --chat-tab-btn: 1px solid #898E8C;
    --chat-tab-selected-bg: linear-gradient(127deg, #F5DF82 30.41%, #B67A2A 98.79%);
    --chat-listing-border: var(--listing-border);
    --chat-listing-bg: var(--listing-bg);
    --chat-listing-shadow: var(--listing-shadow);
    --info-date: #c9c9c9;
    --line-txt: var(--main-txt);
    --line-btn: linear-gradient(to right, #70c727, #70c727);
    --line-btn-border: none;
    --line-btn-shadow: none;
    --line-btn-txt: var(--btn-txt);

    //#endregion

    //#region =====> Bank List

    --bankList-name-border: 1px solid #898E8C;
    --bankList-name-shadow: none;
    --bankList-name: var(--main-txt);
    --bankList-bg: linear-gradient(172deg, #2E2D2C 5.45%, #111010 93.25%);
    --bankList-tips-bg: #161a23;
    --bankList-tips-border: var(--main-border);
    --bankList-tips-shadow: transparent;
    --bankList-tips-txt: var(--tips-txt);
    --bankList-style-bg: var(--bank-bg);
    --bankList-bankname: var(--sub-txt);
    --bankList-banknum: var(--sub-txt);

    //#endregion

    //#region =====> Add Bank

    --addBank-btn-bg: var(--btn-main);

    //#endregion

    //#region =====> Affiliate

    --affiliate-save: var(--btn-sub);
    --affiliate-save-shadow: var(--main-shadow);
    --affiliate-save-txt: var(--main-txt);
    --affiliate-share: linear-gradient(108deg, #F5DF82 2.04%, #B67A2A 98.41%);
    --affiliate-share-shadow: var(--main-shadow);
    --affiliate-share-border: none;
    --affiliate-share-txt: var(--main-txt);
    --affiliate-line: #00C200;
    --affiliate-line-shadow: var(--main-shadow);
    --affiliate-line-border: none;
    --affiliate-line-txt: var(--main-txt);
    --affiliate-copy: #111010;
    --affiliate-copy-shadow: var(--main-shadow);
    --affiliate-copy-border: 1px solid #898E8C;
    --affiliate-copy-txt: #FFFFFF;
    --affiliate-unsettled-bg: #2E2D2C;
    --affiliate-unsettled-border: 1px solid #898E8C;
    --affiliate-unsettled-title: var(--main-txt);
    --affiliate-unsettled-date: var(--main-txt);
    --affiliate-downline-bg: linear-gradient(172deg, #2E2D2C 5.45%, #111010 93.25%);
    --affiliate-downline-txt: var(--main-txt);
    --affiliate-downline-border: 1px solid #898E8C;
    --affiliate-downline-shadow: 2px 2px 4px rgba(0, 0, 0, 0.30);
    --affiliate-report-bg: linear-gradient(108deg, #F5DF82 2.04%, #B67A2A 98.41%);
    --affiliate-report-txt: #000000;
    --affiliate-report-border: none;
    --affiliate-report-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.80);
    --affiliate-topup-btn-shadow: 3px 3px 4px 0px #131617;
    --affiliate-topup-btn-bg: linear-gradient(172deg, #2E2D2C 5.45%, #111010 93.25%);
    --affiliate-topup-btn-border: var(--fund-btn-border);
    --affiliate-topup-btn-txt: #ffffff;
    --affiliate-withdraw-btn-shadow: 3px 3px 4px 0px #131617;
    --affiliate-withdraw-btn-bg: linear-gradient(172deg, #2E2D2C 5.45%, #111010 93.25%);
    --affiliate-withdraw-btn-border: var(--fund-btn-border);
    --affiliate-withdraw-btn-txt: #ffffff;

    //#endregion

    //#region =====> Downline

    --downline-bg-active: #70c71a;
    --downline-bg-new: #6cd0ff;
    --downline-bg-inactive: #ff9345;
    --downline-active: var(--main-txt);
    --downline-new: var(--main-txt);
    --downline-inactive: var(--main-txt);
    --downline-avatar-border: 1px solid #898E8C;
    --downline-avatar-bg: #041126;
    --downline-avatar-shadow: var(--main-shadow);
    --downline-tab-bg: #041126;
    --downline-tab-border: 1px solid #898E8C;
    --downline-tab-txt: var(--main-txt);
    --downline-active-txt: var(--main-txt);
    --downline-inactive-txt: #707070;
    --downline-invite: var(--main-txt);
    --downline-listing-item-bg: #041126;
    --downline-listing-item-border: 1px solid #898E8C;
    --downline-listing-item-shadow: var(--listing-shadow);

    //#endregion

    //#region =====> Footer

    --footer-container: linear-gradient(177deg, #2E2D2C 0%, #111010 80.13%);
    --footer-selected-item-bg: #FFFFFF;
    --footer-selected-item-shadow: 0px -2px 5px 0px #131617;
    --footer-item-txt: #FFF;
    --footer-item-selected-txt: #000000;

    //#endregion

    //#region =====> Change Password

    --changePwd-style-bg: var(--main-bg);
    --changePwd-style-shadow: var(--main-shadow);
    --changePwd-label-container: var(--main-border);
    --changePwd-remark: var(--tips-txt);
    --changePwd-submit-border: none !important;
    --changePwd-submit-shadow: var(--main-shadow);
    --changePwd-disable-shadow: none !important;
    --changePwd-disable-border: var(--main-border) !important;
    --changePwd-disable-bg: transparent !important;
    --changePwd-input-bg: transparent;
    --changePwd-txt: var(--main-txt);
    --changePwd-signOut-bottom: var(--main-border);

    //#endregion

    //#region =====> Pin

    --pin-title: #FFFFFF;
    --pin-txt: #FFFFFF;

    //#endregion

    //#region =====> ResetPin

    --reset-pin-title: #FFFFFF;
    --reset-pin-desc: #FFFFFF;
    --reset-pin-mobile: #FFFFFF;
    --reset-pin-btn: #FFFFFF;
    --reset-pin-btn-border: none;
    --reset-pin-btn-shadow: none;
    --reset-pin-cancel: var(--sub-txt);
    --reset-pin-btn-txt: #000000;

    //#endregion

    //new added - 25 Jul 2023
    --search-text-field-bg: #2E2D2C;
    --search-text-inset-shadow: none;
    --search-input-placeholder: #898E8C;
    --game-category-bg: var(--main-bg);
    --wallet-shadow: none;
    --wallet-border: 1px solid #FBE6AE;
    --profile-mobile-txt: #FFFFFF;
    --profile-container-shadow: none;
    --mission-content-title-txt: #FFFFFF;
    --mission-content-desc-txt: #FFFFFF;
    --mission-btn-border: none;
    --root-bank-dialog-bg: linear-gradient(172deg, #2E2D2C 5.45%, #111010 93.25%);
    --game-search-input-txt: #FFFFFF;
    --game-search-icon-bg: #151F3F;
    --game-search-icon-shadow: none;
    --game-search-icon-border: none;
    --inner-game-back-bg: #151F3F;
    --inner-game-back-border: var(--game-filter-btn-container);
    --inner-game-back-shadow: var(--game-button-common-shadow);
    --provider-banner-bg: #151F3F;
    --provider-banner-shadow: none;
    --inner-game-back-txt: #FFFFFF;
    --datePickerTitleTxt: #FFFFFF;
    --bankList-style-shadow: none;
    --chat-tab-bg: #2E2D2C;
    --chat-name-selected: #FFFFFF;
    --chat-info-title-txt: #FFFFFF;
    --comm-lifetime-bg: #FFF;
    --comm-lifetime-shadow: none;
    --comm-lifetime-border: none;
    --comm-lifetime-txt: #000000;
    --affiliate-unsettled-shadow: none;
    --change-lang-item-txt: #FFFFFF;
    --back-nav-back-bg: #2E2D2C;

    //header footer enhancement
    --back-nav-bg: var(--main-bg);
    --back-nav-back-border: 1px solid #F5DF82;
    --back-nav-back-shadow: none;
    --back-nav-back-txt: #FFFFFF;
    --nav-item-bg: #FFF;

    //login New UI
    --signIn-disabled-resend-btn: #041126;
    --signIn-disabled-resend-border: 1px solid #8F8F8F;
    --signIn-disabled-resend-txt: #57605D;
    --signUp-resend-border: 1px solid #FFFFFF;
    --signUp-resend-txt: #FFFFFF;
    --content-modal-bg: linear-gradient(172deg, #2E2D2C 5.45%, #111010 93.25%);
    --forgot-pin-txt: #FFFFFF;
    --pin-btn-hover-txt: #000000;
    --get-tmp-pin-btn: #1C2131;

    //side menu UI
    --side-menu-bg: #000000;
    --side-menu-nav-bg: linear-gradient(180deg, #2E2D2C 0%, #111010 100%);
    --side-menu-nav-border: none;
    --side-menu-nav-shadow: 0px 2px 5px 0px #131617;
    --side-menu-home-bg: #151F3F;
    --side-menu-home-border: 1px solid #34BEFD;
    --side-menu-home-shadow: 3px 3px 4px 0px #131617;
    --side-menu-home-txt: #F0F3FC;
    --side-menu-brand-txt: #F0F3FC;
    --language-flag-dark-border: 1px solid #FFFFFF;
    --language-flag-dark-shadow: none;
    --language-flag-light-border: 1px solid #FFFFFF;
    --language-flag-light-shadow: none;
    --language-modal-title: #FFFFFF;
    --language-modal-line: #FFFFFF40;
    --language-btn-txt: #FFFFFF;
    --language-btn-txt-selected: #000000;
    --language-btn-bg: #2E2D2C;
    --language-btn-bg-selected: #EDD87F;
    --language-flag-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
    --marquee-txt-dark: #141723;
    --side-menu-item-txt: #F0F3FC;
    --side-menu-item-separator: #141723;
    --tab-item-bg: #141723;
    --tab-item-selected-bg: #F5DF82;
    --tab-item-selected-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    --tab-item-txt: #FFFFFF;
    --tab-item-selected-txt: #000000;
    --title-loading-txt: #FFFFFF;
    --statement-item-odd-bg: #141723;
    --statement-item-even-bg: transparent;
    --statement-title-txt: #c4c5c8;
    --statement-date-txt: #c4c5c8;
    --statement-amount-txt: #FFFFFF;
    --statement-status-success-txt: #70C727;
    --statement-status-progress-txt: #FF8C00;
    --statement-status-fail-txt: #E0250C;
    --statement-separator-bg: #6d727c;
    --report-button-bg: #141723;
    --report-button-border: 1px solid #70C727;
    --report-button-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.50);
    --report-button-txt: #FFFFFF;
    --statement-day-button-bg: #141723;
    --statement-day-button-txt: #FFFFFF;
    --pop-up-bg: #FFFFFF;
    --pop-up-txt: #1C2131;
    --pop-up-separator-bg: #8c9098;
    --side-menu-wallet-bg: linear-gradient(108deg, #F5DF82 2.04%, #B67A2A 98.41%);
    --side-menu-wallet-border: 2px solid #B67A2A;
    --side-menu-wallet-shadow: none;
    --side-menu-topup-bg: linear-gradient(172deg, #2E2D2C 5.45%, #111010 93.25%);
    --side-menu-topup-shadow: none;
    --side-menu-topup-border: 1px solid var(--Gold-Gradient, #F5DF82);
    --side-menu-withdraw-bg: linear-gradient(172deg, #2E2D2C 5.45%, #111010 93.25%);
    --side-menu-withdraw-shadow: none;
    --side-menu-withdraw-border: 1px solid var(--Gold-Gradient, #F5DF82);
    --side-menu-topup-text: #FFFFFF;
    --side-menu-withdraw-text: #FFFFFF;
    --sideMenuItem-bg: linear-gradient(172deg, #2E2D2C 5.45%, #111010 93.25%);
    --sideMenuItem-shadow: none;
    --sideMenuItem-border: 1px solid #898E8C;

// Member Fund Card Component 
    // Topup button
    --fundcard-topup-btn-txt-color: #FFFFFF;
    --fundcard-topup-btn-box-shadow: 3px 3px 4px 0px #131617;
    --fundcard-topup-btn-box-border: 1px solid #B67A2A;
    --fundcard-topup-btn-background: linear-gradient(172deg, #2E2D2C 5.45%, #111010 93.25%);

    // Withdraw button
    --fundcard-withdraw-btn-txt-color: #FFFFFF;
    --fundcard-withdraw-btn-box-border: 1px solid #B67A2A;
    --fundcard-withdraw-btn-box-shadow: none;
    --fundcard-withdraw-btn-background: #linear-gradient(172deg, #2E2D2C 5.45%, #111010 93.25%);    ;

    // Transaction List button
    --fundcard-rebate-btn-txt-color: #000;
    --fundcard-rebate-btn-box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.80);
    --fundcard-rebate-btn-background: #FFF;

    //game flipped info
    --game-flipped-small-logo-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.30);
    --game-flipped-bg: #FFFFFF;
    --game-flipped-title-txt: #000000;
    --game-flipped-value-txt: #666666;
    --game-flipped-separator-bg: #bfbfbf;
    --game-flipped-close-shadow: 2px 2px 2px rgba(0, 0, 0, 0.50);
    --game-info-shadow: 2px 2px 2px rgba(0, 0, 0, 0.50);

    //pagination
    --button-pagination-button-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.50);
    --button-pagination-button-bg: #141723;
    --button-pagination-page-indicator-bg: #141723;
    --button-pagination-page-indicator-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.50);
    --button-pagination-page-indicator-border: 1px solid #707070;
    --button-pagination-page-indicator-txt: #FFFFFF;

    //custom scroll bar
    --scrollbar-track: #2E2D2C;
    --scrollbar-thumb: rgba(255, 255, 255, 0.50);

    //login component
    --login-component-register-btn: linear-gradient(108deg, #F5DF82 2.04%, #B67A2A 98.41%);
    --login-component-register-border: 1px solid #FBE6AE;
    --login-component-login-btn: #2E2D2C;
    --login-component-login-border: none;
    --login-component-register-txt: #000000;
    --login-component-login-txt: #FFFFFF;
    --login-component-line-btn: #00C202;
    --login-component-line-txt: #FFFFFF;
    --login-component-register-shadow: none;
    --login-component-login-shadow: none;
    --login-component-line-shadow: none;
    --login-component-line-border: none;
    --login-component-line-img-bg: #00A502;
    --login-component-separator: #898E8C;
    --login-component-separator-txt: #898E8C;

    //intro download app component
    --introDownloadApp-background: #141723;
    --introDownloadApp-btn-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.50);
    --introDownloadApp-btn: linear-gradient(87deg, #FFE600 0%, #70C727 47.66%);
    --introDownloadApp-btn-txt: #FFFFFF;
    --introDownloadApp-description-txt: #898b90;

    //intro all games component
    --introAllGames-play-btn: linear-gradient(92.15deg, #34BEFD 0.35%, #AB00FE 98.37%);
    --introAllGames-play-btn-txt: #FFFFFF;
    --introAllGames-play-btn-shadow: none;
    --introAllGames-play-btn-border: none;
    --introAllGames-title-txt: #FFFFFF;

    //intro promotion component
    --introPromotion-view-btn: linear-gradient(92.15deg, #34BEFD 0.35%, #AB00FE 98.37%);
    --introPromotion-view-btn-txt: #FFFFFF;
    --introPromotion-view-btn-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.30);
    --introPromotion-view-btn-border: none;
    --introPromotion-title-txt: #FFFFFF;
    --introPromotion-info-bg: #151F3F;
    --introPromotion-content-title: #FFFFFF;
    --introPromotion-content-subTitle: #FFFFFF;
    --introPromotion-content-description: rgba(255, 255, 255, 0.50);
    --introPromotion-content-info: #898E8C;
    --introPromotion-more-btn: #FFFFFF;
    --introPromotion-more-btn-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.30);
    --introPromotion-more-btn-border: none;
    --introPromotion-more-btn-txt: #18221F;

    //intro affiliate component
    --introAffiliate-more-btn: linear-gradient(92.15deg, #34BEFD 0.35%, #AB00FE 98.37%);
    --introAffiliate-more-btn-txt: #FFFFFF;
    --introAffiliate-more-btn-shadow: none;
    --introAffiliate-more-btn-border: none;
    --introAffiliateItem-title-txt: #FFFFFF;
    --introAffiliateItem-title-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
    --introAffiliateItem-desc-txt: #898E8C;
    --introAffiliateItem-btn-txt:#18221F;
    --introAffiliateItem-btn:#FFFFFF;
    --introAffiliateItem-btn-shadow:4px 4px 4px 0px rgba(0, 0, 0, 0.50);
    --introAffiliateItem-btn-border: none;
    --introAffiliateItem-separator: #5F5D60;

    //intro club component
    --introClub-more-btn: linear-gradient(92.15deg, #34BEFD 0.35%, #AB00FE 98.37%);
    --introClub-more-btn-txt: #FFFFFF;
    --introClub-more-btn-shadow: none;
    --introClub-more-btn-border: none;
    --introClub-title-txt: #FFFFFF;
    --introClubItem-title-txt: linear-gradient(92.15deg, #34BEFD 0.35%, #AB00FE 98.37%);
    --introClubItem-title-shadow: 4px 4px 4px rgba(0, 0, 0, 0.50);
    --introClubItem-desc-txt: #C4C6C6;
    --introClubItem-desc-shadow: 4px 4px 4px rgba(0, 0, 0, 0.50);
    --introClubItem-join-btn: #FFFFFF;
    --introClubItem-join-btn-txt: #151825;
    --introClubItem-join-btn-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.80);
    --introClubSubItem-title-txt: #FFFFFF;
    --introClubSubItem-title-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
    --introClubSubItem-btn-txt:#18221F;
    --introClubSubItem-btn:#FFFFFF;
    --introClubSubItem-btn-shadow:4px 4px 4px 0px rgba(0, 0, 0, 0.50);
    --introClubSubItem-btn-border: none;
    --introClubSubItem-separator: #5F5D60;

    //intro page
    --intro-separator-bg: #898E8C;
    --intro-bottom-info-bg: linear-gradient(177deg, #2E2D2C 0%, #111010 80.13%);


    //fund card component
    --intro-fund-card-bg: linear-gradient(108deg, #F5DF82 2.04%, #B67A2A 98.41%);
    --intro-fund-card-border: none;
    --intro-fund-card-shadow: none;
    --intro-fund-card-name: #000000;
    --intro-fund-card-bal-title: #000000;
    --intro-fund-card-bal-value: #000000;
    --intro-fund-card-withdraw-bal-value: #000000;
    //side menu fund card component
    --sideMenu-fund-card-name: #000000;
    --sideMenu-fund-card-bal-title: #000000;
    --sideMenu-fund-card-bal-value: #000000;
    --sideMenu-fund-card-withdraw-bal-value: #000000;

    //back nav component
    --backNav-title: #545864;

    //auto deposit
    --autoDeposit-separator: #8c9098;

    //games log
    --gamesLog-item-odd-bg: #141723;
    --gamesLog-item-even-bg: transparent;
    --gamesLog-title-txt: #c4c5c8;
    --gamesLog-date-txt: #c4c5c8;
    --gamesLog-turnover-title: #c4c5c8;
    --gamesLog-turnover-txt: #FFFFFF;
    --gamesLog-winlost-title: #c4c5c8;
    --gamesLog-winlost-txt: #FFFFFF;
    --gamesLog-status-success-txt: #70C727;
    --gamesLog-status-progress-txt: #FF8C00;
    --gamesLog-status-fail-txt: #E0250C;
    --gamesLog-separator-bg: #6d727c;
    --gamesLog-day-button-bg: #141723;
    --gamesLog-day-button-txt: #FFFFFF;

    //base text field
    --baseTextField-input: rgba(255,255,255,0.6);
    --baseTextField-input-placeholder: #757575;
    --baseTextField-modal-input: rgba(0, 0, 0, 0.80);
    --baseTextField-modal-input-placeholder: #8c8c8c;

    //textfield
    --textField-title: #FFFFFF;
    --textField-separator: #FFFFFF;
    --textField-modal-title: #000000;
    --textField-modal-separator: #000000;
    --textField-err-msg: red;

    //new bank modal
    --newBankModal-title: #000000;
    --newBankModal-hint-txt: rgba(0, 0, 0, 0.50);
    --newBankModal-btn-add-bg: #1C2131;
    --newBankModal-btn-add-txt: #FFFFFF;

    //Pin Textfieldx
    --pinItem-bg: #2E2D2C;
    --pinItem-txt: #FFFFFF;
    --pinItem-normal-border: 1px solid #141723;
    --pinItem-err-border: 1px solid #C7240F;
    --pinItem-focus-border: 1px solid #0057FF;
    --pinTextField-err-txt: #E0250C;

    //login Textfield
    --loginCommonTf-bg: #2E2D2C;
    --loginCommonTf-txt: #FFFFFF;
    --loginCommonTf-err-color: #E0250C;
    --loginCommonTf-err-border: 1px solid #C7240F;
    --loginCommonTf-focus-border: 1px solid #0057FF;
    --loginCommonTf-placeholder-txt: rgba(255, 255, 255, 0.50);

    //loginMain tab
    --loginMain-multiTab-bg: #2E2D2C;

    //remember component
    --remember-txt: rgba(255, 255, 255, 0.50);

    //login
    --login-forgot-pw-txt: #FFFFFF;
    --login-separator: rgba(255, 255, 255, 0.50);

    //third party login
    --thirdPartyLogin-border: 1px solid #F5DF82;
    --thirdPartyLogin-bg: linear-gradient(180deg, #2E2D2C 0%, #111010 100%);
    --thirdPartyLogin-shadow: none;

    //ButtonComponent_v2
    --buttonComponent_v2-main-txt: #000000;
    --buttonComponent_v2-main-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.50);
    --buttonComponent_v2-main-bg: linear-gradient(108deg, #F5DF82 2.04%, #B67A2A 98.41%);
    --buttonComponent_v2-main-disabled-bg: #2E2D2C;
    --buttonComponent_v2-main-disabled-txt: rgba(255, 255, 255, 0.50);
    --buttonComponent_v2-main-disabled-shadow: none;
  
    //verify mobile
    --verifyMobile-title: #FFFFFF;
    --verifyMobile-otp-des: rgba(255, 255, 255, 0.75);
    --verifyMobile-otp-code: rgba(255, 255, 255, 0.5);
    --verifyMobile-otp-code-bg: #2E2D2C;

    //timer
    --timer-txt-main: #FFFFFF;

    //login mobile tf
    --loginMobileTf-prefix-separator: #FFFFFF;

    //bank listing item
    --bankListingItem-bank-txt: #FFFFFF;
    --bankListingItem-separator: #000000;

    //bank drop down border tf
    --bankDropdownBorderTf-border: 1px solid #707070;
    --bankDropdownBorderTf-bg: #141723;
    --bankDropdownBorderTf-placeholder-txt: rgba(255, 255, 255, 0.50);
    --bankDropdownBorderTf-value-txt: #FFFFFF;
    --bankDropdownBorderTf-popup-title-txt: #FFFFFF;
    --bankDropdownBorderTf-popup-bg: linear-gradient(172deg, #2E2D2C 5.45%, #111010 93.25%);
    --bankDropdownBorderTf-popup-shadow: 4px 4px 4px rgba(0, 0, 0, 0.50);
    --bankDropdownBorderTf-popup-separator: #FFFFFF;

    //register bank
    --registerBank-separator: #707070;
    //slide indicator
    --slideIndicator-bg: gray;
    --slideIndicator-active-bg: white;

    //subHeader
    --subheader-title-txt: #FFFFFF;

    //brand about
    --brandAbout-separator: #ffffff7c;
    --brandAbout-brand-txt: #FFF;
    --brandAbout-info-txt: #FFF;

    //license certification component
    --licenseCert-title: #FFF;

    //license certification authentic component
    --licenseCertAuth-title: #FFF;

    //join community component
    --joinCommunity-title: #FFF;

    //payment supported component
    --paymentSupported-title: #FFF;

    //sitemap
    --siteMap-top-separator: #ffffff7c;
    --siteMap-bottom-separator: #ffffff7c;
    --siteMap-title-txt: #FFF;
    --siteMap-desc-txt: #8b8c91;
    --siteMap-aboutUs-desc: #FFF;
    
    //copyright
    --copyright-txt: #FFF;

    //modal bg
    --enterPin-modal-bg: #041126;
    --language-modal-bg: var(--content-modal-bg);
    --lineVerifiedMobile-modal-bg: #041126;
    --resetPin-modal-bg: #041126;
    --setPin-modal-bg: #041126;
    --signIn-modal-bg: #041126;
    --signUp-modal-bg: #041126;
    --enterPin-modal-shadow: 7px 7px 10px 0px rgba(19, 22, 23, 0.50);;
    --enterPin-modal-border: none;
    --language-modal-shadow: 7px 7px 10px 0px rgba(19, 22, 23, 0.50);;
    --language-modal-border: none;
    --lineVerifiedMobile-modal-shadow: 7px 7px 10px 0px rgba(19, 22, 23, 0.50);;
    --lineVerifiedMobile-modal-border: none;
    --resetPin-modal-shadow: 7px 7px 10px 0px rgba(19, 22, 23, 0.50);;
    --resetPin-modal-border: none;
    --setPin-modal-shadow: 7px 7px 10px 0px rgba(19, 22, 23, 0.50);;
    --setPin-modal-border: none;
    --signIn-modal-shadow: 7px 7px 10px 0px rgba(19, 22, 23, 0.50);;
    --signIn-modal-border: none;
    --signUp-modal-shadow: 7px 7px 10px 0px rgba(19, 22, 23, 0.50);;
    --signUp-modal-border: none;

    //scrollbar
    --mission-popup-scrollbar-thumb: #FFF;
    --mission-popup-scrollbar-track: #2E2D2C;
    --mission-popup-scrollbar-thumb-hover: #FFF;
    --news-content-scrollbar-thumb: #FFF;
    --news-content-scrollbar-track: #041126;
    --news-content-scrollbar-thumb-hover: #FFF;
}


