@import '../../../resources/theme/Common.scss';

    .subheader-header {
        // margin-bottom: 24px;
    }
        .subheader-col1 {
            display: flex;
            align-items: center;
        }
            .subheader-icon {
                margin: 0px 0px 0px 0px;
                width: 24px;
                height: 24px;
                object-fit: contain;   
            }
            .subheader-title {
                flex-grow: 1;
                color: $subheader-title-txt;
                font-family: $fontFamily;
                font-size: $fontSize20;
                font-style: $fontStyleNormal;
                font-weight: $fontWeight700;
                line-height: $fontStyleNormal;
                padding: 0px 0px 0px 17px;

            }
