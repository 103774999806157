@import '../../resources/theme/Common.scss';

.marquee-container {
  display: flex;
  flex-direction: row;
}

.marquee-icon {
  height: 21.07px;
  width: 24px;
}

.marquee-txt {
  font-size: 12px;
  margin: 0 0 0 20px;
  color: $marquee-txt;
  white-space: nowrap;
}

.marquee-txt-dark {
  color: $marquee-txt-dark;
}

.speaker-icon {
  width: 27px;
  height: 22px;
}
