@import '../../resources/theme/Common.scss';

.setPassword-container {
    width: 100%;
    padding-bottom: 32px;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
}

.setPassword-style {
    flex-direction: column;
    width: 100%;
    max-width: 468px;
    justify-content: flex-start;
    display: flex;
    flex-direction: column;
}

.setPassword-title-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 49px;
    padding-bottom: 21px;
    gap: 39px;
}
  
.setPassword-title {
    color: $verifyMobile-title;
    font-family: $fontFamily;
    font-size: $fontSize24;
    font-weight: $fontWeight700;
}
  
.setPassword-step-img {
    height: 32px;
    object-fit: contain;
}

.setPassword-form-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
}


.setPassword-btn-section-container {
    display: flex;
    flex-direction: column;
    padding-top: 32px;
    gap: 16px;
}
