@import '../../../resources/theme/Common.scss';

.custom-scrollbars__container {
    display: flex;
    flex-direction: row;
    gap: 15px;
  }

  .custom-scrollbars-horizontal__container {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .custom-scrollbars__content {
    height: 100%;
    -ms-overflow-style: none;
    overflow: auto;
    scrollbar-width: none;
  }
  
  .custom-scrollbars__content::-webkit-scrollbar {
    display: none;
  }
  
  .custom-scrollbars__scrollbar {
    display: flex;
  }
  
  .custom-scrollbars__track-and-thumb {
    display: block;
    height: 100%;
    position: relative;
    width: 12px;
  }
  
  .custom-scrollbars__track {
    background: $scrollbar-track;
    border-radius: 32px;
    bottom: 0;
    cursor: pointer;
    position: absolute;
    top: 0;
    width: 12px;
  }
  
  .custom-scrollbars__thumb {
    position: absolute;
    width: 12px;
    padding: 2px;
    
  }

  .custom-scrollbars__thumb-color {
    border-radius: 32px;
    background: $scrollbar-thumb;
    width: 100%;
    height: 100%;
  }

//horizontal

.custom-scrollbars-horizontal__scrollbar {
    display: flex;
}
  
  .custom-scrollbars-horizontal__track-and-thumb {
    display: block;
    height: 12px;
    position: relative;
    width: 100%;
  }
  
  .custom-scrollbars-horizontal__track {
    background: $scrollbar-track;
    border-radius: 32px;
    right: 0;
    cursor: pointer;
    position: absolute;
    left: 0;
    height: 12px;
  }
  
  .custom-scrollbars-horizontal__thumb {
    position: absolute;
    height: 12px;
    padding: 2px;
  }

  .custom-scrollbars-horizontal__thumb-color {
    border-radius: 32px;
    background: $scrollbar-thumb;
    width: 100%;
    height: 100%;
  }
  //end of horizontal
  