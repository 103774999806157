@import '../../../resources/theme/Common.scss';


.baseTextField-container {
    position: relative;
}

.baseTextField-input {
    position: relative;
    padding: 0;
    margin: 0;
    border: none;
    background: transparent;
    color: $baseTextField-input;
    font-family: $fontFamily;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: 100%;
}
//baseTextField-input: other classes --> start
.baseTextField-modal-input {
    color: $baseTextField-modal-input;
}
.passwordTextField-input {
    color: $loginCommonTf-txt;
    font-size: $fontSize20;
}
.loginCommonTf-input {
    color: $loginCommonTf-txt;
    font-size: $fontSize20;
    line-height: normal;
}
//baseTextField-input: other classes --> end

.baseTextField-placeholder-container {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    overflow: hidden;
}

.baseTextField-placeholder {
    color: $baseTextField-input-placeholder;
}
//baseTextField-placeholder: other classes --> start
.baseTextField-modal-placeholder {
    color: $baseTextField-modal-input-placeholder;
}
.passwordTextField-placeholder {
    color: $loginCommonTf-placeholder-txt;
    font-family: $fontFamily;
    font-size: $fontSize20;
    font-weight: $fontWeight400;
}
.loginCommonTf-placeholder {
    color: $loginCommonTf-placeholder-txt;
    font-family: $fontFamily;
    font-size: $fontSize20;
    font-weight: $fontWeight400;
    line-height: normal;
}
//baseTextField-placeholder: other classes --> end