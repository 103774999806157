@import '../../../resources/theme/Common.scss';

.introPromotionHighlight-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    height: 100%;
    min-width: 100%;
}

.introPromotionHighlight-view-btn {
    padding: 11px;
    width: 92px;
    border-radius: 6px;
    justify-content: center;
    background: $introPromotion-view-btn;
    box-shadow: $introPromotion-view-btn-shadow;
    border: $introPromotion-view-btn-border;
    color: $introPromotion-view-btn-txt;
    font-size: $fontSize16;
    font-weight: $fontWeight700;

}

.test-component {
    width: 100%;
    flex-grow: 0;
    flex-shrink: 0;
}