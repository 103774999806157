@import '../../../resources/theme/Common.scss';

.dimmed-container {
    top: var(--top-header-height);
    bottom: var(--footer-height);
    left: 0;
    right: 0;
    position: fixed;
    background: $plane-bg;
    backdrop-filter: $plane-blur;
    z-index: $focus-effect-zIndex;
}

.dimmed-container-hidden {
    display: none;
}