@import '../../resources/theme/Common.scss';

.invite-container {
  background: $invite-bg;
  background-image: url('../../resources/image/asset/promo/invite/effect.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100vh;
  width: 100vw;
  z-index: 99999;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}

.invite-angpao-container {
  height: 300px;
  width: 300px;
  animation: angpao 1.2s;
  transform: scale(1);
  display: flex;

  .invite-angpaoWCloud {
    height: 300px;
    width: 300px;
  }

  .invite-close {
    height: 40px;
    width: 40px;
    position: absolute;
    right: 60px;
    top: 50px;
  }
}

.invite-choishen-container {
  height: 100%;
  width: 100%;
  background-image: url('../../resources/image/asset/promo/invite/sunray.png');
  background-size: 700px 700px;
  background-repeat: no-repeat;
  background-position: center;
  justify-content: center;
  align-items: center;
  display: none;
  animation: angpao 1.2s;
  transform: scale(1);
}

.invite-choishen {
  height: 300px;
  width: 250px;
  background-image: url('../../resources/image/asset/promo/invite/choishen.png');
  background-size: 100% 100%;
  position: absolute;
  justify-content: center;
  align-items: center;
  display: flex;

  .invite-numbering {
    font-size: 40px;
    color: $invite-numbering;
    margin-top: 50px;
    font-weight: bold;
  }

  .invite-receive {
    height: 30px;
    width: 80px;
    background-image: url('../../resources/image/asset/promo/invite/button.png');
    background-size: 100% 100%;
    position: absolute;
    bottom: 20%;
    left: 33%;
    text-align: center;
    line-height: 28px;
    font-size: 14px;
    color: $invite-receive;
  }

  .invite-dialog-container {
    height: auto;
    width: auto;
    padding: 20px 5px 20px 5px;
    background: $invite-dialog-bg;
    box-shadow: $main-shadow;
    border: $invite-dialog-border;
    border-radius: $main-radius;
    position: absolute;
    bottom: -90px;
    flex-direction: column;
    animation: dialog 1.2s;
    transform: scale(1);
    display: none;

    .invite-dialog-title {
      font-size: 18px;
      color: $invite-dialog-title;
      text-align: center;
      font-weight: bold;
    }

    .invite-dialog-desc {
      font-size: 12px;
      color: $invite-dialog-desc;
      text-align: center;
      margin-bottom: 10px;
    }

    .invite-dialog-btn {
      position: absolute;
      bottom: -15px;
    }

    .invite-dialog-checkTime {
      font-size: 13px;
      color: $invite-dialog-checkTime;
      text-align: center;
    }

    .invite-dialog-time {
      font-size: 17px;
      color: $invite-dialog-time;
      font-weight: bold;
      text-align: center;
      margin-bottom: 5px;
    }

    .invite-dialog-close {
      position: absolute;
      height: 40px;
      width: 40px;
      right: -20px;
      top: -20px;
    }
  }

  .invite-dialog-share {
    bottom: -40px;
  }
}

@media screen and (max-width: 430px), screen and (max-height: 500px) {
  .invite-choishen {
    .invite-dialog-container {
      bottom: -80px;

      .invite-dialog-title {
        font-size: 16px;
      }

      .invite-dialog-desc {
        font-size: 10px;
      }

      .invite-dialog-checkTime {
        font-size: 12px;
      }

      .invite-dialog-time {
        font-size: 14px;
      }

      .invite-dialog-close {
        height: 30px;
        width: 30px;
        right: -10px;
        top: -10px;
      }
    }

    .invite-dialog-share {
      bottom: -40px;
    }
  }
}

@keyframes angpao {
  0% {
    transform: scale(1.8);
  }
  20% {
    transform: scale(0.8);
  }
  40% {
    transform: scale(1.1);
  }
  60% {
    transform: scale(0.8);
  }
  70% {
    transform: scale(0.98);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes dialog {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
