@import '../../resources/theme/Common.scss';


.intro-main-container {
    display: flex;
    width: 100%;
    margin: 0 auto;
    flex-direction: column;
    align-items: center;
    gap: 32px;
}

.intro-width-container {
    max-width: $content-max-width;
    width: 100%;
}

.intro-content-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    // gap: 32px;
}

.intro-play-container {
    display: flex;
    justify-content: right;
    padding: 21px 16px;
    background: $game-container-bg;
    border-radius: 12px;
}

.intro-separator {
    height: 1px;
    width: 100%;
    background: $intro-separator-bg;
}

.intro-login-container,.intro-fund-container {
    margin-top: -21px;
    position: relative;
}
    .intro-game-listing-container {
        display: flex;
        flex-direction: row;
        gap: 16px;
    }
    .intro-game-item-container {
        min-width: 120px;
    }

    .intro-section-container {
        display: flex;
        flex-direction: column;
        gap: 22px;
    }

.allGames-list-btn {
    padding: 11px;
    min-width: 92px;
    height: 42px;
    border-radius: 6px;
    background: #141723; 
    justify-content: center;
    color: #FFF;
    font-family: $fontFamily;
    font-size: $fontSize16;
    font-style: $fontStyleNormal;
    font-weight: $fontWeight700;
    line-height: $fontStyleNormal;
}

    .all-games-list-btn {
        padding: 11px;
        width: 92px;
        height: 42px;
        border-radius: 6px;
        background: #141723; 
        justify-content: center;
        color: #FFF;
        font-family: $fontFamily;
        font-size: $fontSize16;
        font-style: $fontStyleNormal;
        font-weight: $fontWeight700;
        line-height: $fontStyleNormal;

    }

.intro-grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 54px;
    column-gap: 15px;
} 

.intro-gamelist-container {
    padding-top: 20px;
}
    

.intro-bottom-container-bg {
    margin-top: 96px;
    background: $intro-bottom-info-bg;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.intro-bottom-container {
    padding-bottom: 15px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-width: $content-max-width;
    width: 100%;
}

    .brand-about-container {
        border-bottom: 1px solid $brandAbout-separator;
    }
        .brand-about-logo {
            display: flex;
            align-items: center;
            justify-content: center;
            color: $brandAbout-brand-txt;
            font-family: $fontFamily;
            font-size: $fontSize24;
            font-style: $fontStyleNormal;
            font-weight: $fontWeight700;
            line-height: $fontStyleNormal;
        }
            .brand-about-logo-icon {
                height: 58px;
                object-fit: contain;
            }
        .brand-about-info {
            text-align: center;
            color: $brandAbout-info-txt;
            font-family: $fontFamily;
            font-size: $fontSize12;
            font-style: $fontStyleNormal;
            font-weight: $fontWeight400;
            line-height: $fontStyleNormal;
            // padding: 0px 19px 0px 19px;
            opacity: 0.5;
        }

.intro-bottom-iconinfo-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

    .sitemap-container {
        border-top: 1px solid $siteMap-top-separator;
        border-bottom: 1px solid $siteMap-bottom-separator;
        padding: 16px 0px 16px 0px;
    }    
        .sitemap-subheader-row {
            display: flex;
            padding: 0px 11.5px 0px 0px;
            align-items: center;
            height: 42px;
        }
            .sitemap-subheader-title {
                flex-grow: 1;
                opacity: 0.75;
                flex-grow: 1;
                opacity: 0.75;
                color: $siteMap-title-txt;
                font-family: $fontFamily;
                font-size: $fontSize16;
                font-style: $fontStyleNormal;
                font-weight: $fontWeight700;
                line-height: 100%;
            }
            .sitemap-subheader-expand-icon, .sitemap-subheader-collapse-icon {
                width: 18.542px;
                height: 12px;
                flex-shrink: 0;
            }
        .sitemap-aboutUs-description {
            color: $siteMap-aboutUs-desc;
            text-align: justify;
            padding: 0px 11.5px 0px 25px;
            opacity: 0.50;        
            overflow-x: auto; /* Enable horizontal scrolling if the content overflows */
        }
            .sitemap-aboutUs-description-pre {
                font-family: $fontFamily;
                font-size: $fontSize12;
                font-style: $fontStyleNormal;
                font-weight: 400;
                line-height: $fontStyleNormal;
                white-space: pre-wrap; /* Preserve whitespace, but allow text wrapping */
                word-wrap: break-word; /* Break long words when necessary */
                max-width: 100%; /* Ensure the code container does not exceed its parent's width */
            }

    .copyright {
        color: $copyright-txt;
        font-family: $fontFamily;
        font-size: $fontSize16;
        font-style: $fontStyleNormal;
        font-weight: $fontWeight400;
        line-height: $fontStyleNormal;
        opacity: 0.75;
    }
            


/* small device */
@media screen and (max-width: 686px) {
    .intro-grid-container {
        grid-template-columns: repeat(1, 1fr);
        gap: 54px;
    }
}

@media screen and (max-width: 430px) {
    .license-certification-gc-icon {
        height: 48px;
        object-fit: contain;
    }
}

@media screen and (max-width: 345px) {
    .license-certification-gc-icon {
        height: 32px;
        object-fit: contain;
    }
}