
@import '../../../resources/theme/Common.scss';

.remember-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 13px;
}

.remember-tick-img {
    height: 32px;
    width: 32px;
    object-fit: contain;
    cursor: pointer;
}

.remember-txt {
    color: $remember-txt;
    font-family: $fontFamily;
    font-size: $fontSize16;
    font-weight: $fontWeight400;
    line-height: normal;
}