@import '../../../resources/theme/Common.scss';

.loginOrSeparator-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.loginOrSeparator-separator {
    height: 1px;
    background: $login-separator;
    width: 108px;
}

.loginOrSeparator-separator-txt {
    color: $login-separator;
    font-family: $fontFamily;
    font-size: $fontSize12;
    font-weight: $fontWeight400;
    line-height: normal;
}