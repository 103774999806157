@import '../../../../resources/theme/Common.scss';

.toast {
  max-width: 80vw;
  height: 50px;
  margin: auto;
  color: $taost-txt;
  text-align: center;
  border-radius: 10px;
  position: fixed;
  z-index: 100000;
  left: 0;
  right: 0;
  top: 20px;
  font-size: 17px;
  white-space: nowrap;
  visibility: visible;
  -webkit-animation: fadein 0.5s;
  animation: fadein 0.5s;
  background: $taost-bg;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}

.toast .desc {
  color: $toast-desc;
  padding: 16px;
  overflow: hidden;
  white-space: nowrap;
}

@-webkit-keyframes fadein {
  from {
    top: 0;
    opacity: 0;
  }
  to {
    top: 20px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    top: 0;
    opacity: 0;
  }
  to {
    top: 20px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    top: 20px;
    opacity: 1;
  }
  to {
    top: 20px;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    top: 20px;
    opacity: 1;
  }
  to {
    top: 20px;
    opacity: 0;
  }
}
