@import '../../../../resources/theme/Common.scss';

.plane-container {
  height: 100vh;
  width: 100vw;
  z-index: 1001;
  justify-content: center;
  align-items: center;
  display: flex;
  backdrop-filter: blur(8px);
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  background: $plane-bg;
  backdrop-filter: $plane-blur;
}
