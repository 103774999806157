@import '../../../resources//theme/Common.scss';

.global-bottomModal-container {
  display: none;
  position: fixed;
  z-index: $header-footer-zIndex;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  // overflow: auto;
  // background: transparent; /* Black w/ opacity */
  -webkit-animation-name: fadeIn;
  -webkit-animation-duration: 0.4s;
  animation-name: fadeIn;
  animation-duration: 0.4s;
  background: $bottomModal-container-bg;
}

.global-bottomModal-content {
  height: 310px;
  position: fixed;
  bottom: 0px;
  width: 100%;
  -webkit-animation-name: slideIn;
  -webkit-animation-duration: 0.4s;
  animation-name: slideIn;
  animation-duration: 0.4s;
  backdrop-filter: blur(10px);
  display: flex;
  justify-content: center;
}

.global-bottomModal-close-modal {
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 10px;
  top: 24px;
}

.global-bottomModal-close-icon {
  height: 35px;
  width: 35px;
}

.global-bottomModal-black {
  background: $bottomModal-black-bg;
}

.global-bottomModal-white {
  background: $bottom-modal-bg;
}

.global-bottomModal-style {
  width: 100%;
  max-width: 430px;
  position: relative;
}

@-webkit-keyframes excludeFooterslideIn {
  from {
    bottom: -300px;
    opacity: 0;
  }
  to {
    bottom: 0;
    opacity: 1;
  }
}

@keyframes excludeFooterslideIn {
  from {
    bottom: -300px;
    opacity: 0;
  }
  to {
    bottom: var(--footer-height);
    opacity: 1;
  }
}

/* small device */
@media screen and (max-width: 430px), screen and (max-height: 500px) {
}

/* height small than width*/
@media screen and (min-height: 264px) and (min-width: 600px) {
}

/* landscape*/
@media screen and (max-height: 500px) {
}
