@import '../../../resources/theme/Common.scss';

.introClubItem-container {
    display: flex;
    position: relative;
}
.introClubItem-background-img {
    width: 100%;
}

.introClubItem-info-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    gap: 25px;
    position: absolute;
    top: 0px;
    right: 16px;
    left: 0px;
    height: 100%;
    white-space: nowrap;
    overflow: hidden;
}

.introClubItem-info-title {
    font-family: $fontFamily;
    font-size: 52px;
    font-weight: $fontWeight700;
    line-height: 100%;
    background: $introClubItem-title-txt;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.introClubItem-info-description {
    text-align: right;
    color: $introClubItem-desc-txt;
    text-shadow: $introClubItem-desc-shadow;
    font-family: $fontFamily;
    font-size: $fontSize16;
    font-weight: $fontWeight400;
    white-space: pre-wrap; //this for allow /n to new line
}

.introClubItem-btn {
    align-self: flex-end;
    width: 92px;
    height: 42px;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    color: $introClubItem-join-btn-txt;
    background: $introClubItem-join-btn;
    box-shadow: $introClubItem-join-btn-shadow;
}