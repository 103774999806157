@import '../../resources/theme/Common.scss';

.register-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 32px;
}

.register-form-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
}


.register-btn-section-container {
    display: flex;
    flex-direction: column;
    padding-top: 32px;
    gap: 16px;
}
