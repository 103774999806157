@import '../../../resources/theme/Common.scss';

.listing-container {
  min-width: 80px;
  width: 99%;
  margin: 0 0 16px 0;
  border: $listing-border;
  border-radius: $main-radius;
  box-shadow: $listing-shadow;
  display: flex;
  flex-direction: row;
  background: $listing-bg;
  height: 80px;
}

.listing-left-container {
  padding: 0 16px 0 32px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.listing-right-container {
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 10px 16px 10px 16px;
  height: 100%;
  width: 100%;
  flex-direction: column;
}

.listing-top {
  height: 50%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.listing-top-left {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: $listing-item;
  white-space: wrap !important;
  word-break: break-word;
}

.listing-top-right {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  text-align: right;
  color: $listing-item;
}

.listing-bottom {
  height: 50%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 5px;
}

.listing-bottom-left {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: $listing-item;
}

.listing-bottom-right {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  text-align: right;
  color: $listing-item;
}

// if small than iphone 4
@media screen and (max-width: 384px) {
  .listing-left-container {
    padding: 0 16px 0 16px !important;
  }
}

/* small device */
@media screen and (max-width: 430px), screen and (max-height: 500px) {
  .listing-right-container {
    padding: 5px;
  }
  .listing-top-left {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }

  .listing-top-right {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
  }

  .listing-left-container {
    padding: 0 16px 0 25px;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
  }

  .listing-right-container {
    padding: 10px 16px 10px 0px;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
  }
}

/* height small than width*/
@media screen and (min-height: 264px) and (min-width: 600px) {
}

/* landscape*/
@media screen and (max-height: 500px) {
}
