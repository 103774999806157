@import '../../../../resources/theme/Common.scss';

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.suspense-spinner-container {
  justify-content: center;
  align-items: center;
  display: flex;
  height: 100vh;
}

.suspense-spinner-style {
  position: relative;
  display: flex;
  background: transparent;
}

.suspense-loading-spinner-gif {
  width: 100px;
  height: 100px;
}

@media screen and (max-width: 400px) {
  .suspense-loading-spinner-gif {
    width: 50px;
    height: 50px;
  }
}
