@import '../../resources/theme/Common.scss';

.signIn-container {
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%;
  flex-direction: column;
  position: relative;
  height: 100%;
}

.signIn-style {
  margin: 16px 0 20px 0;
  border-radius: 30px;
  max-width: 327px;
  width: 100%;
  background: $signIn-bg;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  box-shadow: $signIn-shadow;
  padding: 0 20px 0 20px;
}

.signIn-title-container {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  color: $signIn-title;
  font-weight: bold;
  margin: 49px 0 82px 0;
  line-height: 29px;
  text-align: center;
}

.signIn-logo {
  height: 146px;
  width: 146px;
}

.signIn-title {
  font-size: 16px;
}

.signIn-num-style {
  width: 100%;
  border-bottom: 1px solid $signIn-underline;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 16px;
  color: $signIn-input;
  font-weight: bold;
  padding-bottom: 10.5px;
}

.signIn-dropdown-mobile {
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 16px;
  color: $signIn-mobile;
}

.signIn-dropdown-icon {
  height: 7px;
  width: 11px;
  margin-left: 5px;
}

.lang-dropdown-icon {
  height: 22px;
  width: 22px;
  margin-right: 4px;
}

.signIn-ver {
  width: 100vw;
  bottom: 15px !important;
  font-size: 16px;
  text-align: center;
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  padding-bottom: 10px;
  background: $signIn-ver;
}

.signIn-input-icon {
  margin-left: 8px;
}

.signIn-input {
  &:disabled {
    -webkit-text-fill-color: $signIn-input;
    opacity: 1;
  }
  &:focus {
    outline: none;
  }
}

.signIn-input {
  width: 65% !important;
  background: $signIn-input-bg;
  text-align: left;
  border: none;
  color: $signIn-input;
  margin-left: 9px;
  font-size: 16px;
}

.signIn-btn-style {
  width: 100%;
  margin: 51.5px 0 20px 0;
  height: 35px;
  font-size: 16px;
  line-height: 20px;
  box-shadow: $signIn-btn-shadow;
}

.signIn-txt {
  font-weight: 700;
  font-size: 16px !important;
  line-height: 20px;
}

.signIn-disabled-btn {
  background: $signIn-disabled-btn;
  border: $signIn-disabled-border;
}

.signIn-btn {
  background: $signIn-btn;
}

.signIn-help {
  align-self: flex-start;
  color: $signIn-help;
  font-size: 14px;
  font-weight: 500;
}

.signIn-help-btn {
  align-self: flex-start;
  color: $signIn-help;
  font-size: 8px;
  font-weight: 700;
  width: 92px;
  height: 26px;
  background: $signIn-line-bg;
  box-shadow: $signIn-line-shadow;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 10px;
  color:$signIn-line-label;
}

.signIn-modal-title {
  color: $signIn-modal-title;
  font-size: 14px;
  line-height: 17px;
  margin: 76.79px 0 11.21px 0;
}

.signIn-modal-select-container {
  height: 200px;
  width: 100%;
  padding: 0 0 30px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.signIn-modal-select-style {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.signIn-selected-container {
  width: 320px;
  height: 35px;
  border-radius: $btn-border-radius;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  color: $signIn-selected-txt;
  font-size: 14px;
  line-height: 17px;
}

.signIn-selected-bg {
  background: $signIn-selected-bg;
  font-weight: 700;
}

.signIn-unselect-bg {
  background: transparent;
  box-shadow: none;
}

.signIn-country-icon {
  height: 18px;
  width: 18px;
  margin-right: 10px;
}

.signIn-help-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 37px;
}

.signIn-change-lang {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: $signIn-lang-txt;
}

.signIn-line-icon {
  height: 18px;
  width: 18px;
  margin-right: 4px;
}

.signIn-lang-txt {
  color: $signIn-lang-txt;
}

.signIn-or-container {
  display: flex;
  width: 100%;
  height: 40px;
  max-width: 327px;
  align-items: center;

  .signIn-or-underline {
    flex-grow: 2;
    height: 1px;
    border: $signIn-or-border;
  }

  .signIn-or-text {
    flex-basis: auto;
    flex-grow: 0;
    margin: 0px 13px 0px 13px;
    text-align: center;
    color: $signIn-or;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
  }
}

.signIn-line {
  height: $btn-mobile-height;
  width: 100%;
  max-width: 287px;
  border-radius: $btn-radius;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  position: relative;
  box-shadow: $btn-shadow;
  border: none;
  background: $signIn-login-line-bg;
  padding: 0px;
  margin: 5px 0 30px 0;

  .signIn-line-icon-container {
    height: 100%;
    width: auto;
    background: $signIn-line-icon-bg;
    padding: 9px;
    border-bottom-left-radius: $btn-radius;
    border-top-left-radius: $btn-radius;
    justify-content: center;
    align-items: center;
    display: flex;

    .signIn-login-line-icon {
      height: 20px;
      width: 20px;
    }
  }

  .signIn-line-label {
    justify-content: center;
    align-items: center;
    display: flex;
    width: 100%;
    height: 100%;
    color: $signIn-line-label;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
  }
}

.signIn-seo {
  max-width: 361px;
  position: fixed;
  bottom: 0;
  opacity: 0;
  border: 1px solid #707070;
  border-radius: 10px;
  padding: 10px;
  height: 0.5px;
  overflow: hidden;
}

/* small device */
@media screen and (max-width: 430px), screen and (max-height: 500px) {
  .signIn-style {
    width: 100%;
    padding: 0 20px 0 20px;
  }

  .signIn-container {
    justify-content: flex-start;
    height: 100vh;
    width: 100%;
  }

  .signIn-logo {
    height: 72px;
    width: 72px;
    margin-top: 30px;
  }

  .signIn-line {
    width: 100%;
    max-width: 210px;
  }
}

/* landscape*/
@media screen and (max-height: 500px) {
  .signIn-style {
    max-width: 327px;
    width: 100%;
  }

  .signIn-ver {
    font-size: 16px;
    position: relative;
  }

  .signIn-container {
    margin-bottom: 30px;
  }
}

/* Add Animation */
@-webkit-keyframes slideIn {
  from {
    bottom: -300px;
    opacity: 0;
  }
  to {
    bottom: 0;
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    bottom: -300px;
    opacity: 0;
  }
  to {
    bottom: 0;
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
