@import '../../../../resources/theme/Common.scss';

.custom-alert-container {
  min-height: min(22vh, 295px);
  max-height: max(80vh);
  width: min(85vw, 468px);
  padding-top: min(4.7619vh, 72px);
  padding-bottom: min(4.9784vh, 72px);
  padding-left: min(3.4632vh, 40.5px);
  padding-right: min(3.4632vh, 40.5px);
  border-radius: 25px;
  border: $dialog-border;
  background: $dialog-bg;
  overflow: auto;
}

