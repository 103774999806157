
@import '../../../resources/theme/Common.scss';

.language-container {
    height: 32px;
    width: 32px;
    border-radius: 50%;
    border: $language-flag-light-border;
    box-shadow: $language-flag-light-shadow;
    overflow: hidden;
} 

.language-icon {
    height: 32px;
    width: 32px;
    object-fit: contain;
}