@import '../../../resources/theme/Common.scss';

.howtoplay-container {

}

    .howtoplay-title {
        color: #FFF;
        font-family: $fontFamily;
        font-size: $fontSize20;
        font-style: $fontStyleNormal;
        font-weight: $fontWeight700;
        line-height: $fontStyleNormal;
        margin: 0px 0px 8px 0px;
    }
    .howtoplay-row {
        display: flex;
        justify-content: space-around;
    }

    .howtoplay-card {
        height: 112px;
        width: 112px;
        display: flex;
        flex-direction: column;
        align-items: flex-end; 
        justify-content: flex-end; 
        text-align: right;
        padding: 10px;
        margin-right: 12px;
        border-radius: 10px;
        background: $new-sub-bg;
    }

        .howtoplay-step-title {
            // justify-content: center;
            color: $gold-txt;
            font-family: $fontFamily;
            font-size: $fontSize14;
            font-style: $fontStyleNormal;
            font-weight: $fontWeight700;
            line-height: $fontStyleNormal;    
        }
        .howtoplay-step-description {
            color: $main-txt;    
            font-family: $fontFamily;
            font-size: $fontSize10;
            font-style: $fontStyleNormal;
            font-weight: $fontWeight400;
            line-height: $fontStyleNormal;
            margin-top: 2.5px;
        }


// @media screen and (max-width: 686px), screen and (max-height: 500px) {
//     .howtoplay-card{
//         height: 192px;
//         width: 192px;
//     }
// }
  
// @media screen and (max-width: 600px), screen and (max-height: 500px) {
  
//     .howtoplay-card{
//         height: 152px;
//         width: 152px;
//     }
// }
  
// /* small device */
// @media screen and (max-width: 430px), screen and (max-height: 500px) {

//     .howtoplay-card{
//         height: 122px;
//         width: 122px;
//     }
// }


// @media screen and (max-width: 320px), screen and (max-height: 500px) {
