@import '../../../../resources/theme/Common.scss';

.news-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  z-index: 99998;
  background: $root-bank-bg;
  backdrop-filter: blur(8px);
}

.swiper-container {
  min-height: min(52.5963vh, 628px);
  width: 100%;
  margin-top: min(19.0117vh, 227px);
  margin-bottom: min(5.3601vh, 64px);
}

.swiper-slide {
  height: min(52.5963vh, 628px);
  width: min(51.3189vw, 428px);
  margin: 0 min(7.6739vw, 64px);
  border-radius: min(2.9976vw, 25px);
  border: $news-border;
  background: $news-bg;
  box-shadow: $news-shadow;
  transform: scale(0.9) !important;
  transition: all 200ms linear;
}

.swiper-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 min(3.8369vw, 32px);
}

.swiper-slide-active {
  transform: scale(1) !important;
}

.news-img-container {
  height: min(22.6131vh, 270px);
  margin-top: min(1.34vh, 16px);
  margin-bottom: min(2.7638vh, 33px);
}

.news-img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.news-content-container {
  height: min(11.139vh, 133px);
  margin-bottom: min(5.3601vh, 64px);
  overflow: auto;
  font-size: min(1.9185vw, 16px);
  font-weight: 400;
  // line-height: min(1.5913vh, 19px);
  line-height: 19px;
  text-align: left;
}

.news-content-container::-webkit-scrollbar-track {
  background: $news-content-scrollbar-track;
  border-radius: 10px;
}

.news-content-container::-webkit-scrollbar-thumb {
  background: $news-content-scrollbar-thumb;
  border-radius: 10px;
}

.news-content-container::-webkit-scrollbar-thumb:hover {
  background: $news-content-scrollbar-thumb-hover; /* Change the color when the scrollbar thumb is hovered */
}

.news-title {
  color: $news-title-txt;
  // margin-bottom: min(1.675vh, 20px);
  // font-size: min(1.9185vw, 16px);
  // font-weight: 400;
  // line-height: min(1.5913vh, 19px);
  // text-align: left;
}

.news-desc {
  color: $news-desc-txt;
  // height: min(6.2814vh, 75px);
  // margin-bottom: min(5.3601vh, 64px);
  // overflow: auto;
  // font-size: min(1.9185vw, 16px);
  // font-weight: 400;
  // line-height: min(1.5913vh, 19px);
  // text-align: left;
}

.news-btn {
  height: 48px;
  width: min(43.6451vw, 364px);
  padding: unset;
  cursor: pointer;
  background: $news-ok-btn-bg;
  box-shadow: $news-ok-btn-shadow;
  border: $news-ok-btn-border;
}

.news-txt{
  color: $news-ok-btn-txt;
}

.news-close-container {
}

.news-close {
  height: 48px;
  width: min(48.4412vw, 404px);
  border-radius: min(1.199vw, 10px);
  cursor: pointer;
}

.news-close-bg {
  background: $news-close-bg;
  border: $news-close-border;
  box-shadow: $news-close-shadow;
}

.news-close-txt {
  color: $news-close-txt;
}

.news-pagination-container {
  width: auto;
  position: absolute;
  bottom: min(3.3501vh, 40px);
  display: flex;
  justify-content: center;
  gap: 7px;
}

.news-pagination {
  height: 6px;
  width: 6px;
  border-radius: 100%;
  background: $news-pagination;
}

.news-pagination.active {
  background: $news-pagination-active;
}

@media screen and (max-width: 430px), screen and (max-height: 500px) {
  .swiper-container {
    min-height: min(48.7013vh, 450px);
    margin-top: min(21.4286vh, 198px);
    margin-bottom: min(3.4632vh, 32px);
  }

  .swiper-slide {
    width: min(69.555vw, 297px);
    margin: 0 min(3.7471vw, 16px);
    border-radius: min(5.8548vw, 25px);
  }

  .swiper-content {
    padding: 0 min(3.7471vw, 16px);
  }

  .news-img-container {
    height: min(23.3766vh, 216px);
    margin-top: min(2.0563vh, 19px);
    margin-bottom: min(1.9481vh, 18px);
  }

  .news-content-container {
    height: min(10.6061vh, 98px);
    margin-bottom: min(3.4632vh, 32px);
    font-size: min(2.8103vw, 12px);
    font-weight: 400;
    line-height: 14px;
    text-align: left;
  }

  .news-btn {
    height: 35px;
    width: min(62.0609vw, 265px);
    border-radius: min(2.3419vw, 10px);
  }

  .news-close {
    height: 35px;
    width: min(69.555vw, 297px);
    border-radius: min(2.3419vw, 10px);
  }
}
