@import '../../../resources/theme/Common.scss';

.passwordTextField-container {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.passwordTextField-input-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 33.5px;
    background: $loginCommonTf-bg;
    border-radius: 10px;
    padding: 17px 19px;
}

.passwordTextField-icon {
    width: 21px;
    height: 24px;
    object-fit: contain;
}
.passwordTextField-error-input-container {
    border: $loginCommonTf-err-border;
}

.passwordTextField-focus-input-container {
    border: $loginCommonTf-focus-border;
}

.passwordTextField-hide-show-img-container {
    display: flex;
}

.passwordTextField-hide-show-img {
    height: 24px;
    width: 32px;
    object-fit: contain;
}

.passwordTextField-error-msg {
    color: $loginCommonTf-err-color;
    font-size: $fontSize12;
    font-family: $fontFamily;
    font-weight: $fontWeight400;
    line-height: normal;
}
