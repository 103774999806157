@import '../../../../resources/theme/Common.scss';

.bank-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 99998;
  background: $root-bank-bg;
  backdrop-filter: blur(8px);
}

.bank-popup {
  height: 628px;
  width: 428px;
  padding: 0 32px;
  padding-top: 62px;
  background: $root-bank-dialog-bg;
  border: $root-bank-border;
  border-radius: 25px;
  position: relative;
}

.bank-close-container {
  height: 30px;
  width: 30px;
  top: 16px;
  right: 16px;
  position: absolute;
}

.bank-close-btn {
  height: 100%;
  width: 100%;
  padding: unset;
  border: none;
  object-fit: contain;
  background: transparent;
  cursor: pointer;
}

.bank-close-icon {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.bank-title {
  margin-bottom: 16px;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: $root-bank-title;
}

.bank-tips {
  margin-bottom: 32px;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
  color: $root-bank-tips;
}

.bank-label {
  margin-bottom: 16px;
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  text-align: left;
  color: $root-bank-label;
}

.bank-dropdown {
  height: 60px;
  width: 364px;
  margin: 0px;
  margin-bottom: 32px;
}

.bank-input-container {
  height: 48px;
  width: 362px;
  padding: 12px 16px;
  margin-bottom: 64px;
  border: $root-bank-input-border;
  box-shadow: $root-bank-input-shadow;
  background: $root-bank-input-bg;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bank-input {
  width: 100%;
  border: none;
  background: transparent;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: $root-bank-input-txt;
}

.bank-input::placeholder {
  color: $root-bank-input-placeholder;
}

.bank-btn-container {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
}

.bank-btn-bg {
  background: $root-bank-btn-bg;
  box-shadow: $root-bank-btn-shadow;
  border: $root-bank-btn-border;
}

.bank-btn {
  height: 48px;
  width: 364px;
  cursor: pointer;
}

@media screen and (max-width: 430px), screen and (max-height: 500px) {
  .bank-container {
    justify-content: unset;
    overflow: auto;
  }

  .bank-popup {
    // height: min(51.1905vh, 473px);
    height: auto;
    min-height: 473px;
    width: min(84.5433vw, 361px);
    margin-top: min(21.645vh, 200px);
    padding: 0 min(7.4941vw, 32px);
    padding-top: min(6.71vh, 62px);
    border-radius: min(5.8548vw, 25px);
  }

  .bank-close-container {
    height: 30px;
    width: 30px;
    top: min(1.7316vh, 16px);
    right: min(3.7471vw, 16px);
  }

  .bank-title {
    margin-bottom: 16px;
    font-size: min(3.2787vw, 14px);
    line-height: 17px;
  }

  .bank-tips {
    margin-bottom: 16px;
    font-size: min(3.2787vw, 14px);
    line-height: 17px;
  }

  .bank-label {
    font-size: min(3.7471vw, 16px);
    line-height: min(2.1645vh, 20px);
    margin-bottom: 16.5px;
  }

  .bank-dropdown {
    width: min(69.555vw, 297px);
    margin-bottom: 16.5px;
  }

  .bank-input-container {
    margin-bottom: 41px;
    height: 35px;
    width: min(69.555vw, 297px);
    border-radius: min(2.3419vw, 10px);
  }

  .bank-input {
    padding: min(1.2987vh, 12px) min(4.6838vw, 20px);
    font-size: min(3.2787vw, 14px);
    line-height: min(1.8398vh, 17px);
  }

  .bank-btn {
    height: 35px;
    width: min(69.555vw, 297px);
  }
}
