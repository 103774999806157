@import '../../resources/theme/Common.scss';

.footer-container {
  background: $footer-container;
  height: 81.41px;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0px;
  justify-content: center;
  align-items: flex-start;
  display: flex;
  width: 100%;
  grid-template: repeat(4, 1fr) / repeat(4, 1fr);
  gap: 32px;
  padding-top: 10px;
  z-index: $header-footer-zIndex;
}

/* small device */
@media screen and (max-width: 430px), screen and (max-height: 500px) {
  .footer-container {
    max-width: unset;
  }

  .footer-container {
    justify-content: space-evenly;
    gap: unset;
  }

  .invite-angpao {
    width: 80px;
    height: 90px;
    top: -10px;
  }

  .invite-angpao-time {
    font-size: 7px;
    padding: 0 3px 0 3px;
    left: 25%;
  }
}
