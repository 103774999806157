@import '../../../resources/theme/Common.scss';

.introAllGames-container {
    display: flex;
    flex-direction: column;
    min-width: 100%;
    width: 100%;
}

.introAllGames-play-btn {
    padding: 11px;
    width: 92px;
    border-radius: 6px;
    justify-content: center;
    background: $introAllGames-play-btn;
    box-shadow: $introAllGames-play-btn-shadow;
    border: $introAllGames-play-btn-border;
    color: $introAllGames-play-btn-txt;
    font-size: $fontSize16;
    font-weight: $fontWeight700;
}

.introAllGames-content-img {
    width: 100%;
}