@import '../../../resources/theme/Common.scss';


.joinourcommunity-container {
}
    .joinourcommunity-title  {
        color: $joinCommunity-title;
        font-family: $fontFamily;
        font-size: $fontSize16;
        font-style: $fontStyleNormal;
        font-weight: $fontWeight700;
        line-height: $fontStyleNormal;
        padding: 0px 0px 8px 5px;
        opacity: 0.5;
    }
    .joinourcommunity-icons {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        border-radius: 12px;
        gap: 8px;
        // grid-template-columns: repeat(5, minmax(42px,84px)); // set 5col x 1row grid layout
        // justify-content: space-evenly;

        /* small device */
        @media screen and (max-width: 430px) {
            // grid-template-columns: repeat(3, minmax(42px,84px));
        }
    }
    .joinourcommunity-icons img{   
        width: 42px;
        max-width: 42px;     
        object-fit: contain;   
        opacity: 0.75;
    }


