@import '../../../resources/theme/Common.scss';

.bankDropDownBorderTextField-container {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
}

.bankDropDownBorderTextField-input-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 33.5px;
    background: $loginCommonTf-bg;
    border-radius: 10px;
    padding: 17px 19px;
    border: $bankDropdownBorderTf-border;
    height: 58px;
}

.bankDropDownBorderTextField-error-input-container {
    border: $loginCommonTf-err-border;
}

.bankDropDownBorderTextField-value-section {
    display: flex;
    flex-direction: row;
    gap: 35px;
    align-items: center;
}

.bankDropDownBorderTextField-value-img {
    width: 31px;
    height: 31px;
    object-fit: contain;
}

.bankDropDownBorderTextField-value-txt {
    color: $bankDropdownBorderTf-value-txt;
    font-family: $fontFamily;
    font-size: $fontSize16;
    font-weight: $fontWeight400;
    line-height: normal;
}

.bankDropDownBorderTextField-placeholder-txt {
    color: $bankDropdownBorderTf-placeholder-txt;
    font-family: $fontFamily;
    font-size: $fontSize20;
    font-weight: $fontWeight700;
    line-height: normal;
    text-align: center;
}

.bankDropDownBorderTextField-dropdown-img {
    width: 18px;
    object-fit: contain;
}

.bankDropDownBorderTextField-error-msg {
    color: $loginCommonTf-err-color;
    font-size: $fontSize12;
    font-family: $fontFamily;
    font-weight: $fontWeight400;
    line-height: normal;
}

.bankDropDownBorderTextField-popup-bank-container {
    display: flex;
    flex-direction: column;
    background: $bankDropdownBorderTf-popup-bg;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: $bankDropdownBorderTf-popup-shadow;
    padding: 0px 8px;
}

.bankDropDownBorderTextField-popup-title-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    padding: 9px 21px;
}

.bankDropDownBorderTextField-popup-title {
    color: $bankDropdownBorderTf-popup-title-txt;
    font-family: $fontFamily;
    font-size: $fontSize20;
    font-weight: $fontWeight700;
    line-height: normal;
    flex: 1;
    text-align: center;
}

.bankDropDownBorderTextField-popup-title-img {
    width: 18px;
    object-fit: contain;
}

.bankDropDownBorderTextField-popup-bank-options-container {
    display: flex;
    flex-direction: column;
    overflow: scroll;
    max-height: 252px;
    gap: 5px;
    padding-top: 5px;
}

.bankDropDownBorderTextField-popup-bank-options-container::-webkit-scrollbar {
    display: none;
}

//bank option item

.bankDropDownBorderTextField-item-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.bankDropDownBorderTextField-item-content-container {
    display: flex;
    flex-direction: row;
    gap: 35px;
    align-items: center;
    padding: 0px 38px;
}

.bankDropDownBorderTextField-item-bank-img {
    width: 31px;
    height: 31px;
    object-fit: fill;
}

.bankDropDownBorderTextField-item-bank-name {
    color: $bankListingItem-bank-txt;
    font-family: $fontFamily;
    font-size: $fontSize12;
    font-weight: $fontWeight700;
    line-height: normal;
    word-break: break-all;
}

.bankDropDownBorderTextField-item-separator {
    height: 1px;
    width: 100%;
    background: $bankDropdownBorderTf-popup-separator;
}