@import '../../../../resources/theme/Common.scss';

.notif-style {
  width: 361px;
  max-height: calc(100vh - 70px);
  height: auto;
  background: transparent;
  position: fixed;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  z-index: 999990;
  overflow: auto;
  padding-bottom: 20px;
  padding: 0 10px 20px 0;
}

.notif-container {
  width: 100%;
  min-height: 63px;
  background: $notif-bg;
  box-shadow: $notif-shadow;
  border-radius: 16px;
  margin-top: 16px;
  flex-direction: row;
  display: flex;
  padding: 11px 16px;
  justify-content: center;
  align-items: center;
}

.notif-content-style {
  justify-content: center;
  align-items: center;
  display: flex;
}

.notif-content-container {
  width: 70%;
  height: auto;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
}

.notif-content-container div {
  width: 100%;
  text-align: left;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  color: $notif-msg;
  white-space: wrap;
  word-break: break-word;
  padding-right: 16px;
}

.notif-btn-container {
  height: 27.27px;
  width: 86px;
  background: $notif-btn-bg;
  box-shadow: $notif-btn-shadow;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 11px;
  line-height: 13px;
  color: $notif-btn-txt;
}
