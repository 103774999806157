@import '../../../resources/theme/Common.scss';

.title-loading-container {
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;
}

.title-loading-txt {
    color: $title-loading-txt;
    font-family: $fontFamily;
    font-size: 32px;
    font-weight: $fontWeight700;
    line-height: 95%;
}

.title-loading-spinner {
    height: 24px;
    width: 24px;
    object-fit: contain;
}