@import '../../resources/theme/Common.scss';

.gamesLog-back-nav {
    padding: 0 !important;
}

.gamesLog-container {
    position: relative;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    display: flex;
    width: 100%;
}
  
.gamesLog-style {
    max-width: 468px;
    flex-direction: column;
    width: 100%;
    justify-content: flex-start;
    display: flex;
    min-height: auto;
}
  
.gamesLog-sticky-header {
    width: 100%;
    position: sticky;
    top: var(--top-header-height);
    z-index: 1;
    display: flex;
    flex-direction: column;
    gap: 32px;
    background: $main-bg;
    padding: 32px 0px;
}
  
.gamesLog-filter-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.gamesLog-separator {
    width: 100%;
    height: 1px;
    background: $gamesLog-separator-bg;
}

.gamesLog-day-filter-container {
    display: flex;
    flex-direction: row;
    gap: 7px;
}

.gamesLog-day-button {
    background: $gamesLog-day-button-bg;
    border-radius: 10px;
    padding: 13px;
    justify-content: center;
    align-items: center;
    display: flex;
}

.gamesLog-day-button-txt {
    color: $gamesLog-day-button-txt;
    text-align: center;
    font-family: $fontFamily;
    font-size: $fontSize16;
    font-weight: $fontWeight700;
}

.gamesLog-day-filter-container-item {
    flex: 1;
}

.gamesLog-provider-filter-container {
    display: flex;
    flex-direction: row;
    gap: 7px;
}

.gamesLog-provider-button {
    background: $gamesLog-day-button-bg;
    border-radius: 10px;
    padding: 13px;
    justify-content: center;
    align-items: center;
    display: flex;
}

.gamesLog-provider-button-txt {
    color: $gamesLog-day-button-txt;
    text-align: center;
    font-family: $fontFamily;
    font-size: $fontSize16;
    font-weight: $fontWeight700;
}

.gamesLog-provider-filter-container-item {
    flex: 1;
}