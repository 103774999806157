@import '../../../resources/theme/Common.scss';

.multi-tab-container {
    display: flex;
    flex-direction: row;
    gap: 8px;
    overflow: auto;
}
//multi-tab-container: other classes
.loginMain-multi-tab-container {
    background: $loginMain-multiTab-bg;
    border-radius: 10px;
    gap: 0px;
}

.multi-tab-container::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
}

.multi-tab-item-container {
    flex: 1;
}