@import '../../../resources/theme/Common.scss';

.changePass-modal-main-container {
  width: 100%;
  max-width: 686px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  position: relative;
}

.changePass-modal-container {
  min-height: 450px;
  width: 100%;
  background: $signIn-modal-bg;
  box-shadow: $signIn-modal-shadow;
  border: $signIn-modal-border;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px 50px;
}

.changePass-modal-close-icon {
  object-fit: contain;
  height: 42px;
  width: 42px;
  position: absolute;
  top: 8px;
  right: 8px;
}

.changePass-modal-step-icon {
  object-fit: contain;
  height: 45px;
  max-height: 10%;
  width: 160px;
  max-width: 100%;
}

.changePass-bottom-modal-title {
  color: $signIn-modal-title;
  font-size: 14px;
  line-height: 17px;
  margin: 76.79px 0 11.21px 0;
}

.changePass-bottom-modal-select-container {
  height: 200px;
  width: 100%;
  padding: 0 0 30px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.changePass-bottom-modal-select-style {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.changePass-bottom-modal-selected-container {
  width: 320px;
  height: 35px;
  border-radius: $btn-border-radius;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  color: $signIn-selected-txt;
  font-size: 14px;
  line-height: 17px;
}

.changePass-bottom-modal-selected-bg {
  background: $signIn-selected-bg;
  font-weight: 700;
}

.changePass-bottom-modal-unselect-bg {
  background: transparent;
  box-shadow: none;
}

.changePass-bottom-modal-country-icon {
  height: 18px;
  width: 18px;
  margin-right: 10px;
}

.changePass-modal-title-container {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  color: $signIn-title;
  font-weight: bold;
  margin: 0px 0 82px 0;
  line-height: 29px;
  text-align: center;
  width: 100%;
  overflow-wrap: anywhere;
}

.changePass-form-container {
  width: 90%;
}

.changePass-modal-title {
  align-items: center;
  display: flex;
  font-size: 16px;
  color: $signIn-mobile;
  // gap: 14px;
  font-weight: bold;
}

.changePass-modal-input-container {
  border-bottom: 1px solid $signIn-underline;
  padding-top: 15px;
}

// .signIn-modal-input {
//     border-bottom: 1px solid $signIn-underline;
//     &:disabled {
//       -webkit-text-fill-color: $signIn-input;
//       opacity: 1;
//     }
//     &:focus {
//       outline: none;
//     }
// }

.changePass-modal-mobile-separator {
  width: 1px;
  height: 20px;
  background: $signIn-mobile-separator;
}

.changePass-modal-input {
  width: 65% !important;
  background: $signIn-input-bg;
  text-align: left;
  border: none;
  color: $signIn-input;
  // margin-left: 9px;
  margin-bottom: 5px;
  font-size: 16px;
  display: flex;
  flex-direction: horizontal;
}

.changePass-modal-input::placeholder {
  color: $signIn-input-placeholder;
}

.changePass-modal-btn-container {
  width: 90%;
  display: flex;
  flex-direction: row;
}

.changePass-modal-cancel-btn {
  background: $changePass-cancel-btn-background;
  border: $changePass-cancel-btn-border;
}

.changePass-modal-cancel-txt {
  font-weight: 700;
  font-size: 16px !important;
  line-height: 20px;
  color: black;
}

.changePass-modal-btn-cancel-style {
  width: 100%;
  margin-top: 32px;
  margin-left: 5px;
  height: 42px;
  min-height: 42px;
  font-size: 16px;
  line-height: 20px;
  box-shadow: $signIn-btn-shadow;
}

.changePass-modal-btn {
  background: $signIn-btn;
  border: $signIn-btn-border;
}

.changePass-modal-btn-style {
  width: 100%;
  margin-top: 32px;
  margin-right: 5px;
  height: 42px;
  min-height: 42px;
  font-size: 16px;
  line-height: 20px;
  box-shadow: $signIn-btn-shadow;
}

.changePass-modal-txt {
  font-weight: 700;
  font-size: 16px !important;
  line-height: 20px;
  color: $signIn-btn-txt;
}

.changePass-modal-disabled-txt {
  color: $signIn-disabled-btn-txt;
}

.changePass-modal-disabled-btn {
  color: white;
  background: $signIn-disabled-btn;
  border: $signIn-disabled-border;
}

.changePass-modal-or-container {
  display: flex;
  width: 90%;
  margin: 25px 0px;
}

.changePass-modal-or-underline {
  flex-grow: 2;
  height: 1px;
  border: $signIn-or-border;
}

.changePass-modal-or-text {
  flex-basis: auto;
  flex-grow: 0;
  margin: 0px 13px 0px 13px;
  text-align: center;
  color: $signIn-or;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
}

.changePass-modal-line {
  height: 42px;
  min-height: 42px;
  width: 100%;
  border-radius: $btn-radius;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  position: relative;
  border: none;
  background: $signIn-login-line-bg;
  padding: 0px;

  .changePass-modal-line-icon-container {
    height: 100%;
    width: auto;
    background: $signIn-line-icon-bg;
    padding: 9px;
    border-bottom-left-radius: $btn-radius;
    border-top-left-radius: $btn-radius;
    justify-content: center;
    align-items: center;
    display: flex;

    .changePass-modal-login-line-icon {
      height: 20px;
      width: 20px;
    }
  }

  .changePass-modal-line-label {
    justify-content: center;
    align-items: center;
    display: flex;
    width: 100%;
    height: 100%;
    color: $signIn-line-label;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
  }
}

.changePass-modal-seo {
  max-width: 361px;
  position: fixed;
  bottom: 0;
  opacity: 0;
  border: 1px solid #707070;
  border-radius: 10px;
  padding: 10px;
  height: 0.5px;
  overflow: hidden;
}
