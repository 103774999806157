@import '../../../resources/theme/Common.scss';

.tab-list-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  // position: fixed;
  // top: 48px;
  background: $listTab;
  margin: 0 0 32px 0;
  max-width: 468px;
  height: 70px;
}

.tab-rest-container::-webkit-scrollbar {
  display: none;
}

.tab-rest-container {
  display: flex;
  height: 100%;
  overflow-x: scroll;
  flex-direction: row;
  width: 80%;
  padding-left: 30px;
}

.tab-all {
  padding: 0 20px 0 0px !important;
}

.tab-list-btn {
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  text-align: center;
  flex-direction: column;
  padding-right: 40px;
}

.tab-list-txt-selected {
  font-weight: bold;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: $list-txt;
  text-align: center;
  white-space: nowrap;
}

.tab-list-txt-unSelect {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: $list-unSelect-text;
  white-space: nowrap;
}

.tab-list-underline-selected {
  width: 50px;
  height: 6px;
  background: $list-underline;
  position: absolute;
  bottom: 5px;
  border-radius: 10px;
}

.tab-list-style {
  width: 100%;
  height: 60px;
  flex-direction: row;
  display: flex;
}

.tab-date-container {
  height: 100%;
  max-height: 60px;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  right: 0;
}

// date picker
.datePicker-container {
  height: 200px;
  width: 100%;
  max-width: 766px;
  padding: 0 0 30px 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  position: relative;
  background: transparent;
}

.datePicker-style {
  height: 310px;
}

.datePicker-title {
  width: 100%;
  margin: 41px 0 46px 0;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: $datePickerTitleTxt;
}

.datePicker-top-container {
  width: 100%;
  max-width: 766px;
  flex-direction: row;
  justify-content: space-between;
  display: flex;
  align-items: flex-start;
  margin-bottom: 36px;
}

.datePicker-btn {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: $datePickerTxt;
  border: $datePickerBorder;
  width: 30%;
  height: 60px;
  border-radius: $btn-radius;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $datePickerBg;
  box-shadow: $dateShadow;
}

.datePicker-selected {
  color: $datePickSelectedTxt;
  background: $datePickerSelected;
  box-shadow: $datePickerSelected-shadow;
  border: $datePickerSelected-border;
}

.datePicker-icon {
  height: 35px;
  width: 35px;
}

// if small than iphone 4
@media screen and (max-width: 384px) {
}

/* small device */
@media screen and (max-width: 430px), screen and (max-height: 500px) {

  .tab-date-container {
    right: 40px;
  }

  .tab-rest-container {
    width: 60%;
  }

  .tab-list-container {
    margin: 0 0 22px 0;
    height: 60px;
  }

  .tab-list-txt-selected {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
  }

  .tab-list-txt-unSelect {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
  }

  .datePicker-container {
    height: 200px;
    width: 100%;
    max-width: none;
    padding: 0 0 30px 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    position: relative;
    background: transparent;
  }

  .datePicker-style {
    height: 229px;
  }

  .datePicker-title {
    margin: 41px 0 20px 0;
    padding-left: 24px;
    font-weight: 700;
    font-size: 13px;
    line-height: 17px;
  }

  .datePicker-top-container {
    margin-bottom: 16px;
    padding: 0 24px 0px 24px;
  }

  .datePicker-btn {
    height: 41px;
    font-weight: 700;
    font-size: 13px;
    line-height: 17px;
  }

  .datePicker-icon {
    height: 25px;
    width: 25px;
  }
}

/* height small than width*/
@media screen and (min-height: 264px) and (min-width: 600px) {
}

/* landscape*/
@media screen and (max-height: 500px) {
}
