@import '../../../resources/theme/Common.scss';

.introAffiliateItem-container{ 
    display: flex;
    flex-direction: column;
    gap: 18px;
}

.introAffiliateItem-content-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: $fontSize16;
    font-style: $fontStyleNormal;
    font-weight: $fontWeight700;
}

.introAffiliateItem-separator {
    height: 1px;
    width: 100%;
    background: $introAffiliateItem-separator;
}
.introAffiliateItem-info-container {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.introAffiliateItem-info-title{
    text-shadow: $introAffiliateItem-title-shadow;
    font-family: $fontFamily;
    font-size: $fontSize16;
    font-weight: $fontWeight700;
    color: $introAffiliateItem-title-txt;
}

.introAffiliateItem-info-desc{
    font-family: $fontFamily;
    font-size: $fontSize16;
    font-weight: $fontWeight700;
    color: $introAffiliateItem-desc-txt;
}

.introAffiliateItem-btn {
    width: 92px;
    height: 42px;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    color: $introAffiliateItem-btn-txt;
    background: $introAffiliateItem-btn;
    box-shadow: $introAffiliateItem-btn-shadow;
    border: $introAffiliateItem-btn-border;
}