@import '../../resources/theme/Common.scss';

.chat-container {
  width: 100%;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.chat-style {
  flex-direction: column;
  width: 100%;
  max-width: 468px;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.chat-listing-style {
  border: $chat-listing-border !important;
  background: $chat-listing-bg !important;
  box-shadow: $chat-listing-shadow !important;
  height: auto !important;
}

.chat-sticky-header {
  width: 100%;
  position: sticky;
  top: var(--top-header-height);
  z-index: 1;
}

.chat-tab-container {
  padding: 32px 0px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  background: $listTab;
  max-width: 468px;
}

.chat-render {
  margin-top: 20px !important;
}

.chat-tab-btn {
  height: 60px;
  width: 60px;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 10px;
  border: $chat-tab-btn;
  margin: 0 16px 0 0;
  flex-direction: column;
  box-shadow: $chat-tab-shadow;
  background: $chat-tab-bg;
}

.chat-tab-selected-bg {
  border: none;
  background: $chat-tab-selected-bg;
}

.chat-icon {
  height: 25px;
  width: 25px;
  margin-bottom: 5px;
}

.chat-name {
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: $chat-name;
}

.chat-name-selected {
  color: $chat-name-selected;
}

.info-title {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  margin: 0 0 0 10px;
  color: $chat-info-title-txt;
}

.info-date {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: $info-date;
}

.info-msg {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  margin: 0 0 0px 10px;
}

.line-container {
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.line-btn {
  width: 96%;
  background: $line-btn;
  border: $line-btn-border;
  box-shadow: $line-btn-shadow;
  margin-bottom: 50px;
}

.line-btn-txt {
  color: $line-btn-txt;
}

.line-title {
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
  margin-bottom: 32px;
  color: $line-txt;
}

.info-bottom-container {
  width: 0;
  margin-bottom: 25px;
}

.line-icon {
  height: 30px;
  width: 30px;
}

.chat-content {
  width: 100%;
}

/* small device */
@media screen and (max-width: 430px), screen and (max-height: 500px) {

  .chat-render {
    margin-top: 40px !important;
  }

  .chat-icon {
    height: 25px;
    width: 25px;
  }

  .chat-tab-btn {
    border-radius: 10px;
  }

  .chat-name {
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
  }

  .info-title {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    margin: 0 0 0 10px;
  }

  .line-title {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 16px;
  }

  .info-date {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
  }

  .info-msg {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    margin: 0 0 16px 10px;
  }

  .line-icon {
    height: 25px;
    width: 25px;
  }
}
