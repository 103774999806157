@import '../../../resources/theme/Common.scss';

.loginCommonTf-container {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
}

.loginCommonTf-input-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 33.5px;
    background: $loginCommonTf-bg;
    border-radius: 10px;
    padding: 17px 19px;
}

.loginCommonTf-error-input-container {
    border: $loginCommonTf-err-border;
}

.loginCommonTf-focus-input-container {
    border: $loginCommonTf-focus-border;
}

.loginCommonTf-icon-container {
    display: flex;
}

.loginCommonTf-icon-img {
    width: 21px;
    height: 24px;
    object-fit: contain;
}

.loginCommonTf-loading-img {
    width: 24px;
    height: 24px;
}

.loginCommonTf-error-img {
    width: 24px;
    height: 24px;
}

.loginCommonTf-error-msg {
    color: $loginCommonTf-err-color;
    font-size: $fontSize12;
    font-family: $fontFamily;
    font-weight: $fontWeight400;
    line-height: normal;
}
