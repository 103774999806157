@import '../../../resources/theme/Common.scss';

.appGameChangePassword-modal-main-container {
    width: 100%;
    max-width: 686px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    position: relative;
}

.appGameChangePassword-modal-container {
    min-height: 600px;
    width: 100%;
    background: $content-modal-bg;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 32px 50px;
}

.appGameChangePassword-modal-close-icon {
    object-fit: contain;
    height: 42px;
    width: 42px;
    position: absolute;
    top: 8px;
    right: 8px;
}

.appGameChangePassword-modal-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: $pin-title;
    padding-bottom: 39px;
  }