@import '../../../../resources/theme/Common.scss';

.info-container {
  height: 100vh;
  width: 100vw;
  position: absolute;
  z-index: 199999;
  align-items: center;
  display: flex;
  background: $info-bg;
  flex-direction: column;
}

.info-item {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  align-items: center;
  max-width: $content-max-width;
  width: 100%;
}

.info-close-container {
  height: 60px;
  width: 100%;
}

.info-close-btn {
  height: 100%;
  width: 100%;
  padding: unset;
  object-fit: contain;
  border: none;
  background: transparent;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.info-close-icon {
  height: 60px;
  width: 60px;
  object-fit: contain;
}

.info-content-container {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.info-img {
  height: 150px;
  min-width: 150px;
  margin: 0 0 32px 0;
}

.info-img.topup {
  height: min(48.7013vh, 450px);
  width: min(74.0047vw, 316px);
}

.info-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

.info-title-txt {
  color: $info-title;
  margin-bottom: 32px;
  font-size: 32px;
  font-weight: 700;
  line-height: 39px;
  text-align: center;
}

.info-desc {
  width: 522px;
  text-align: center;
  margin-bottom: 64px;
  color: $info-desc;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  overflow: auto;
}

.info-desc-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  margin: 0 auto;
}

.info-desc-content.comm {
  text-align: left;
}

.info-desc-grid {
  width: 474px;
  margin-bottom: 16px;
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 10px;
  row-gap: 5px;
}

.info-desc-grid.topup {
  column-gap: unset;
  row-gap: 20px;
  margin: unset;
}

.info-desc-step {
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.info-desc-step-desc {
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.info-desc-remark {
  width: 470px;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
}

.info-cta-container {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 15px;
  flex-direction: column;
  align-items: center;
}

.info-cta-bg {
  background: $info-btn-bg;
  box-shadow: $info-btn-shadow;
  border: $info-btn-border;
}

.info-cta {
  height: 48px;
  width: 364px;
  margin-bottom: 19px;
}

.info-cta-txt {
  color: $info-btn-txt;
}

.info-show-container {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: $dontShow;
}

.info-show-icon {
  height: 20px;
  width: 20px;
  margin-right: 8px;
}

@media screen and (max-width: 430px), screen and (max-height: 500px) {
  .info-container {
    overflow: auto;
  }

  .info-item {
    justify-content: flex-start;
  }

  .info-close-icon {
    height: 30px;
    width: 30px;
  }

  .info-img {
    height: 113px;
    min-width: 113px;
  }

  .info-img.topup {
    height: 327px;
    width: 178px;
    margin-top: min(6.0606vh, 56px);
  }

  .info-content-container {
    justify-content: center;
  }

  .info-content {
    height: min(42.6439vh, 394.03px);
  }

  .info-title-txt {
    margin-bottom: 38px;
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
  }

  .info-desc {
    width: 100%;
    max-width: 351px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
  }

  .info-desc-grid {
    max-width: 332px;
    width: 100%;
  }

  .info-desc-step {
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
    text-align: left;
  }

  .info-desc-step-desc {
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    text-align: left;
  }

  .info-desc-remark {
    width: 100%;
    max-width: 336px;
    font-size: 10px;
    font-style: italic;
    font-weight: 400;
    line-height: 12px;
    text-align: left;
  }

  .info-cta {
    height: 35px;
    width: 100%;
    max-width: 280px;
  }

  .info-cta-container {
    padding-bottom: 100px;
  }
}
