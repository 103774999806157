@import '../../resources/theme/Common.scss';

.login-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 32px;
}

.login-form-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-top: 53px;
}

.login-forgot-section-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    padding-top: 21px;
}

.login-forgot-txt {
    cursor: pointer;
    color: $login-forgot-pw-txt;
    font-family: $fontFamily;
    font-size: $fontSize16;
    font-weight: $fontWeight400;
    line-height: normal;
    text-decoration-line: underline;
}

.login-btn-section-container {
    display: flex;
    flex-direction: column;
    padding-top: 45px;
    gap: 16px;
}
