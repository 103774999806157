@import '../../../resources/theme/Common.scss';


.introPromotionItem-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.introPromotionItem-image-container {
    position: relative;
    display: flex;
    width: 100%;
}

.introPromotionItem-title-container {
    position: absolute;
    top: 50%;
    left: 16px;
    width: 65%;
    transform: translate(0, -50%);
}

.introPromotionItem-title-txt {
    color: $introPromotion-content-title;
    font-family: $fontFamily;
    font-size: $fontSize24;
    font-weight: $fontWeight700;
}

.introPromotionItem-subtitle-txt {
    color: $introPromotion-content-subTitle;
    font-family: $fontFamily;
    font-size: $fontSize20;
    font-weight: $fontWeight400;
}

.introPromotionItem-description-txt {
    color: $introPromotion-content-description;
    font-family: $fontFamily;
    font-size: $fontSize16;
    font-weight: $fontWeight700;
}

.introPromotionItem-info-container {
    padding: 10px 16px 16px 16px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 20px;
    background: $introPromotion-info-bg;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.introPromotionItem-info-txt {
    flex: 1;
    color: $introPromotion-content-info;
    font-family: $fontFamily;
    font-size: $fontSize16;
    font-weight: $fontWeight400;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3; /* number of lines to show */
}

.introPromotionItem-info-btn {
    padding: 0px 10px;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    background: $introPromotion-more-btn;
    box-shadow: $introPromotion-more-btn-shadow;
    border: $introPromotion-more-btn-border;
    color: $introPromotion-more-btn-txt;
    font-family: $fontFamily;
    font-size: $fontSize16;
    font-weight: $fontWeight700;
}