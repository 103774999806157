@import '../../../resources/theme/Common.scss';

.setPin-modal-main-container {
    width: 100%;
    max-width: 686px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    position: relative;
}

.setPin-modal-container {
    min-height: 600px;
    width: 100%;
    background: $setPin-modal-bg;
    box-shadow: $setPin-modal-shadow;
    border: $setPin-modal-border;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 32px 50px;
}

.setPin-modal-close-icon {
    object-fit: contain;
    height: 42px;
    width: 42px;
    position: absolute;
    top: 8px;
    right: 8px;
}

.setPin-modal-step-icon {
    object-fit: contain;
    height: 45px;
    max-height: 10%;
    width: 160px;
    max-width: 100%;
}

.setPin-modal-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: $pin-title;
    padding-bottom: 39px;
  }