@import '../../../resources/theme/Common.scss';

.btnV2-container{
    // border-radius: 10px;
    font-family: $fontFamily;
    font-style: $fontStyleNormal;
    font-weight: $fontWeight700;
    line-height: $fontStyleNormal;
    display: flex;
    cursor: pointer;
}
//btnV2-container: other classes --> start
.btnV2-container-row-center-p17-r10-mainshadow {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    padding: 17px;
    box-shadow: $buttonComponent_v2-main-shadow;
    background: $buttonComponent_v2-main-bg;
    border: $buttonComponent_v2-main-border;
}

.btnV2-container-verify-mobile-resend {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    padding: 17px;
    box-shadow: $verifyMobile-resend-shadow;
    background: $verifyMobile-resend-bg;
    border: $verifyMobile-resend-border;
}
//btnV2-container: other classes --> end

.btnV2-container-disabled {
    cursor: not-allowed;
}

//btnV2-container-disabled: other classes --> start
.btnV2-container-disabled-opacity {
    opacity: 0.5;
}
.btnV2-container-row-center-p17-r10-mainshadow-disabled {
    background: $buttonComponent_v2-main-disabled-bg;
    box-shadow: $buttonComponent_v2-main-disabled-shadow;
}

.btnV2-container-verify-mobile-resend-disabled {
    background: $verifyMobile-resend-disabled-bg;
    box-shadow: $verifyMobile-resend-disabled-shadow;
}
//btnV2-container-disabled: other classes --> end

.btnV2-btn-txt {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
//btnV2-btn-txt: other classes --> start
.btnV2-btn-txt-s20-w700-cMain { // later add here
    color: $buttonComponent_v2-main-txt;
    text-align: center;
    font-family: $fontFamily;
    font-size: $fontSize20;
    font-weight: $fontWeight700;
    line-height: normal;
}

.btnV2-btn-txt-s20-w700-cMain-disabled {
    color: $buttonComponent_v2-main-disabled-txt;
}

.btnV2-container-verify-mobile-resend-cMain { // later add here
    color: $verifyMobile-resend-main-txt;
    text-align: center;
    font-family: $fontFamily;
    font-size: $fontSize20;
    font-weight: $fontWeight700;
    line-height: normal;
}

.btnV2-container-verify-mobile-resend-cMain-disabled {
    color: $verifyMobile-resend-disabled-txt;
}
//btnV2-btn-txt: other classes --> end

//Homepage sitemap
.sitemap-content-item {
    font-family: $fontFamily;
    font-size: $fontSize16;
    font-style: $fontStyleNormal;
    font-weight: $fontWeight700;
    line-height: $fontStyleNormal;
    padding: 11.25px 0px 11.25px 25px;
    color: $siteMap-desc-txt;
}