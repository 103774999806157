@import '../../../resources/theme/Common.scss';

.introClub-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    min-width: 100%;
    width: 100%;
}

.introClub-content {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.introClub-more-btn {
    padding: 11px;
    width: 92px;
    border-radius: 6px;
    justify-content: center;
    background: $introClub-more-btn;
    box-shadow: $introClub-more-btn-shadow;
    border: $introClub-more-btn-border;
    color: $introClub-more-btn-txt;
    font-size: $fontSize16;
    font-weight: $fontWeight700;

}

.introClub-content-img {
    width: 100%;
}