@import '../../../resources/theme/Common.scss';

:root {
    --slider-width: 100%;
    --slider-height: auto;
    --slider-frame-border-width: 0px;
}

.slider-main-container {
    touch-action: none;
    display: flex;
    flex-direction: column;
    gap: 7px;
    align-items: center;
    width: 100%;
}

.image-slider {
    position: relative;
    width: var(--slider-width);
    height: var(--slider-height);
    overflow: hidden;
    // border: $main-border;
    // border-radius: 15px;
    // box-shadow: $main-shadow;
    transform: translate3d(0, 0, 0); //solved for safari when sliding cutt-off edge will briefly appear
}

.slide img {
    user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
}

.slides {
    display: flex;
    transition: transform 0.3s ease-in-out;
    height: 100%;
    width: 100%;
    word-break: break-all;
}

.slide {
    display: flex;
    width: calc(var(--slider-width) + (var(--slider-frame-border-width)));
    height: 100%;
    flex-shrink: 0;
}

.slide img {
    width: 100%;
    height: 100%;
}

.slide-indicators-container {
    display: flex;
    flex-direction: row;
    gap: 7px;
}

.slide-indicator {
    height: 6px;
    width: 6px;
    background: $slideIndicator-bg;
    border-radius: 3px;
}

.selected-slide-indicator {
    height: 6px;
    width: 6px;
    background: $slideIndicator-active-bg;
    border-radius: 3px;
}


.slider-faded-container {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 50px;
    background: linear-gradient(to bottom, transparent, $main-bg 80%);
    pointer-events: none;
}