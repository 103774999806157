.flatList-container {
  height: auto;
  width: 100%;
  flex-direction: column;
  padding-bottom: 70px;
}

.flatList-horizontal-container {
  margin: 105px 0 0 0;
  display: flex;
  height: 100%;
  flex-direction: row;
  padding-right: 50px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.hide-scrollbar {
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.noData {
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  display: flex;
  font-size: 18px;
  margin-top: 30px;
}
