
@import '../../../resources/theme/Common.scss';

.back-nav-back-btn-container {
    background: $back-nav-back-bg;
    height: 46px;
    min-height: 46px;
    padding: 13px 16px;
    border-radius: 8px;
    border: $back-nav-back-border;
    box-shadow: $back-nav-back-shadow;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
}


.back-nav-back-btn-txt {
    font-weight: $fontWeight700;
    font-size: 16px;
    color: $back-nav-back-txt;
}