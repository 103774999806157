@import '../../resources/theme/Common.scss';

.game-container {
  height: 100%;
  width: 100%;
  max-width: $content-max-width;
}

.game-style {
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
  padding: 0px 0px 33.7px 0px;
}

.game-arrow-icon {
  height: 20px;
  width: 12px;
}

.game-search-icon {
  height: 24px;
  width: 24px;
}

.game-search-textfield {
  border-radius: 8px;
  padding: 11px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  background: $search-text-field-bg;
  flex-grow: 1;
  align-items: center;
  box-shadow: $search-text-inset-shadow;
}

.search-input {
  border: none;
  font-size: 16px;
  font-weight: 400;
  background: transparent;
  color: $game-search-input-txt;
  width: 100%;
}

.search-input::placeholder {
  color: $search-input-placeholder;
}

//this div for detect the max width to do some responsive
.game-category-width-container {
  padding-top: 12px;
  width: 100%;
  max-width: $game-ipad-size-max-width;
}

.game-category-container{
  padding: 0px 12px;
  // display: inline-block;
  background: $game-category-bg;
  border-radius: 12px;
}
//flex to make it same width as game-category-width-container, to trigger category become scrollable
.game-category-container-flex{
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
}

.game-category-scroll-container{
  padding: 12px 4px;
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  flex-grow: 1;
  gap: 18px;
}

.game-category-scroll-container::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.game-search-background-container {
  background: $main-bg;
  position: sticky;
  top: var(--top-header-height);
  //these 3 line for extra background to overlap the game listing shadow
  width: calc(100% + 20px);
  padding: 0px 10px;
  transform: translateX(-10px);
  z-index: 1;
}
.game-search-filter-container {
  padding: 12px 0px;
  display: flex;
  flex-direction: row;
  gap: 30px;
}

.game-filter-btn-container {
  width: 57px;
  height: 57px;
  min-width: 57px;
  border: $game-filter-btn-container;
  background: $game-filter-btn-bg;
  border-radius: 10px;
  padding-top: 3px;
  // margin-right: 15px;
  justify-content: space-evenly;
  align-items: center;
  display: flex;
  position: relative;
  flex-direction: column;
  box-shadow: $game-filter-shadow;
  // margin-bottom: 16px;
}

.game-filter-btn-container-selected {
  background: $game-filter-selected;
  border: $game-filter-selected-border;
  box-shadow: $game-filter-selected-shadow;
}

.game-filter-icon {
  height: 25px;
  width: 25px;
}

.game-filter-txt {
  font-size: 11px;
  font-weight: 700;
  color: $game-filter-selected-txt;
}

.game-filter-unselect-txt {
  font-size: 10px;
  font-weight: 700;
  color: $game-filter-unselect-txt;
}

.game-provider-list-container{
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 16px;
  width: 100%;
}

.game-provider-item-container {
  display: flex;
  // height: 150px; //currently no nid set height for this, because it will auto get the image height
  overflow: hidden;
  border: $game-provider-btn-border;
  box-shadow: $game-provider-btn-shadow;
  border-radius: 10px;
}

.game-provider-item-image {
  width: 100%;
  height: 100%;
}

.game-provider-icon {
  height: 100%;
}

.category-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 81px;
}

.game-nodata {
  height: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.inner-game-search-background-container {
  background: $main-bg;
  position: sticky;
  top: var(--top-header-height);
  //these 3 line for extra background to overlap the game listing shadow
  width: calc(100% + 20px);
  padding: 0px 10px;
  transform: translateX(-10px);
}

.inner-game-search-filter-container {
  padding: 12px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 26px;
}

.inner-game-back-container {
  background: $inner-game-back-bg;
  height: 46px;
  min-height: 46px;
  padding: 13px 16px;
  border-radius: 8px;
  border: $inner-game-back-border;
  box-shadow: $inner-game-back-shadow;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
}

.inner-game-back-text {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: $inner-game-back-txt;
}

.provider-banner-container {
  display: flex;
  flex-direction: row;
  gap: 26px;
  padding: 6px 17px;
  border-radius: 12px;
  background: $provider-banner-bg;
  align-items: center;
  max-width: 362px;
  max-height: 46;
  box-shadow: $provider-banner-shadow;
}

.provider-banner-container-below {
  display: none;
  flex-direction: row;
  gap: 11px;
  padding: 23px 18px;
  border-radius: 12px;
  background: $provider-banner-bg;
  align-items: center;
  margin-bottom: 16px;
  box-shadow: $provider-banner-shadow;
}

.provider-banner-text {
  font-weight: 700;
  font-size: 12px;
  color: $provider-banner-txt;
  line-height: 95%;
  padding-bottom: 1px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4; /* number of lines to show */
}

.inner-game-search-icon {
  height: 24px;
  width: 24px;
}

.inner-game-search-textfield {
  border-radius: 8px;
  padding: 11px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  background: $search-text-field-bg;
  flex-grow: 1;
  align-items: center;
  box-shadow: $search-text-inset-shadow;
}

.inner-game-hide-css {
  display: none;
}

.inner-search-input {
  border: none;
  font-size: 16px;
  font-weight: 400;
  background: transparent;
  color: $game-search-input-txt;
  width: 100%;
}

.inner-search-input::placeholder {
  color: $search-input-placeholder
}

//app game alert --start
.app-game-in-progress-alert-main-container {
  display: flex;
  flex-direction: column;
  gap: 36px;
}

.app-game-in-progress-top-view {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 55px;
}

.app-game-in-progress-img {
  width: 100%;
}

.app-game-in-progress-title {
  font-family: $fontFamily;
  font-style: $fontStyleNormal;
  font-weight: $fontWeight700;
  font-size: $fontSize24;
  color: $sub-txt;
  text-align: center;
}

.app-game-in-progress-middle-view {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.app-game-in-progress-bottom-view {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 18px;
}

.app-game-alert-play-button, .app-game-alert-exit-button {
  width: calc((100% - 18px) / 2); //16px is the gap in app-game-button-container
}

.app-game-not-found-alert-main-container {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.app-game-not-found-top-view {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
}

.app-game-not-found-img {
  width: 100%;
}

.app-game-not-found-top-sub-view {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.app-game-not-found-title {
  font-family: $fontFamily;
  font-style: $fontStyleNormal;
  font-weight: $fontWeight700;
  font-size: $fontSize24;
  color: $app-game-warning-color;
  text-align: center;
}

.app-game-not-found-desc {
  font-family: $fontFamily;
  font-style: $fontStyleNormal;
  font-weight: $fontWeight700;
  font-size: $fontSize16;
  color: $sub-txt;
  text-align: center;
}

.app-game-not-found-middle-view {
  width: 100%;
}

.app-game-not-found-bottom-view {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 18px;
}
//app game alert --end
@media screen and (max-width: 890px), screen and (max-height: 500px) {
  .game-style {
    padding: 0px 0px 33.7px 0px;
  }
}

@media screen and (max-width: 686px), screen and (max-height: 500px) {
  .inner-game-search-filter-container {
    gap: 16px;
  }

  .game-style {
    padding: 0px 0px 33.7px 0px;
  }

  .game-provider-list-container{
    grid-template-columns: repeat(5, 1fr);
  }
}

@media screen and (max-width: 900px) {
  .provider-banner-container {
      display: none;
  }
  
  .provider-banner-container-below {
      display: flex;
  }
}

@media screen and (max-width: 600px), screen and (max-height: 500px) {

  .game-provider-list-container{
    grid-template-columns: repeat(4, 1fr);
  }

  .game-icon-style {
    height: auto;
    width: 100%;
    position: relative;
    margin-bottom: 16px;

    .game-icon-container {
      min-height: 80px;
      height: auto;
      width: 100%;
    }

    .game-name {
      margin: 5px 0 0px 0;
      left: 0;
      width: 100%;
      position: absolute;
    }
  }

  .game-style {
    padding: 0px 0px 33.7px 0px;
  }

}

/* small device */
@media screen and (max-width: 430px), screen and (max-height: 500px) {
  .game-style {
    padding: 0px 0px 33.7px 0px;
  }

  .game-provider-list-container{
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (min-width: 400px) and (max-width: 920px) and (orientation: landscape) {
  .game-provider-list-container{
    grid-template-columns: repeat(5, 1fr);
  }
}

/* very small device @ galaxy fold */
@media screen and (max-width: 280px), screen and (max-height: 500px) {

  .game-style {
    padding: 0px 0px 33.7px 0px;
  }
}
