//#region =====> Common

//zIndex Config
$header-footer-zIndex: 4;
$focus-effect-zIndex: 2;

//common attribute
$content-left-right-padding: 19px;
$content-max-width: 686px;
$modal-border-radius: 16px;


// Common
$pagePadding: var(--pagePadding);
$main-border-size: var(--main-border-size);
$main-border: var(--main-border);
$main-shadow: var(--main-shadow);
$gray-shadow: var(--gray-shadow);
$border: var(--border);
$main-radius: var(--main-radius);
$strong: var(--strong);
$transparent: var(--transparent);

//background
$main-bg: var(--main-bg);
$wallet-bg: var(--wallet-bg);
$sub-bg: var(--sub-bg);
$main-modal-bg: var(--main-modal-bg);
$bottom-modal-bg: var(--bottom-modal-bg);
$main-inpage-modal-bg: var(--main-inpage-modal-bg);
$bank-bg: var(--bank-bg);
$main-bottom-shadow: var(--main-bottom-shadow);
$new-sub-bg: var(--new-sub-bg);

//text
$fontFamily: var(--fontFamily);
$fontWeight700: var(--fontWeight700);
$fontWeight400: var(--fontWeight400);
$fontStyleNormal: var(--fontStyleNormal);
$fontSize10: var(--fontSize10);
$fontSize12: var(--fontSize12);
$fontSize14: var(--fontSize14);
$fontSize16: var(--fontSize16);
$fontSize20: var(--fontSize20);
$fontSize24: var(--fontSize24);
$fontSize30: var(--fontSize30);
$fontHeight15: var(--fontHeight15);
$fontHeight20: var(--fontHeight20);
$main-txt: var(--main-txt);
$sub-txt: var(--sub-txt);
$theme-txt: var(--theme-txt);
$main-placeholder: var(--main-placeholder);
$tips-txt: var(--tips-txt);
$light-gray-txt: var(--light-gray-txt);
$gold-txt: var(--gold-txt);
$provider-banner-txt: var(--provider-banner-txt);

// tab
$tab-txt-selected: var(--tab-txt-selected);
$tab-txt-unSelected: var(--tab-txt-unSelected);
$tab-selected-bg: var(--tab-selected-bg);
$form-radius: var(--form-radius);
$form-border: var(--form-border);
$form-shadow: var(--form-shadow);
$form-bg: var(--form-bg);
$form-label: var(--form-label);
$form-item-bg: var(--form-item-bg);
$form-item-radius: var(--form-item-radius);
$form-item-border: var(--form-item-border);
$form-item-shadow: var(--form-item-shadow);

//#endregion

//#region =====> Component

// Fortunel Wheel
$wheel-dialog-bg: var(--wheel-dialog-bg);
$wheel-dialog-border: var(--wheel-dialog-border);
$wheel-dialog-title: var(--wheel-dialog-title);
$wheel-dialog-desc: var(--wheel-dialog-desc);
$wheel-dialog-total: var(--wheel-dialog-total);
$wheel-info: var(--wheel-info);
$wheel-info-check: var(--wheel-info-check);
$wheel-cancel-bg: var(--wheel-cancel-bg);
$wheel-cancel-txt: var(--wheel-cancel-txt);

// Permanent Angpao
$permanent-bg: var(--permanent-bg);
$permanent-amt: var(--permanent-amt);

// Invite Angpao
$invite-bg: var(--invite-bg);
$invite-numbering: var(--invite-numbering);
$invite-receive: var(--invite-receive);
$invite-dialog-bg: var(--invite-dialog-bg);
$invite-dialog-border: var(--invite-dialog-border);
$invite-dialog-title: var(--invite-dialog-title);
$invite-dialog-desc: var(--invite-dialog-desc);
$invite-dialog-checkTime: var(--invite-dialog-checkTime);
$invite-dialog-time: var(--invite-dialog-time);

//Timer
$timer-txt: var(--timer-txt);

//Header
$header-bg: var(--header-bg);
$header-shadow: var(--header-shadow);
$header-txt: var(--header-txt);

//CPPin
$cppin-field-border: var(--cppin-field-border);
$cppin-field-active-border: var(--cppin-field-active-border);
$cppin-field-bg: var(--cppin-field-bg);
$cppin-active-field: var(--cppin-active-field);
$cppin-btn-bg: var(--cppin-btn-bg);
$cppin-shadow: var(--cppin-shadow);
$cppin-btn-cursor: var(--cppin-btn-cursor);
$cppin-btn-txt: var(--cppin-btn-txt);

//CPLang
$cplang-bg: var(--cplang-bg);

// CPBottomModal
$bottomModal-container-bg: var(--bottomModal-container-bg);
$bottomModal-black-bg: var(--bottomModal-black-bg);

// CPDropdown
$dropdown-bg: var(--dropdown-bg);
$dropdown-shadow: var(--dropdown-shadow);
$dropdown-border: var(--dropdown-border);
$dropdown-inner-bg: var(--dropdown-inner-bg);
$dropdown-inner-shadow: var(--dropdown-inner-shadow);
$dropdown-inner-label-container-bg: var(--dropdown-inner-label-container-bg);
$dropdown-right-label: var(--dropdown-right-label);
$dropdown-list-bg: var(--dropdown-list-bg);
$dropdown-list-item-bg: var(--dropdown-list-item-bg);
$dropdown-list-item-shadow: var(--dropdown-list-item-shadow);
$dropdown-list-item-txt: var(--dropdown-list-item-txt);
$dropdown-placeholder: var(--dropdown-placeholder);

// CPButton
$btn-main: var(--btn-main);
$btn-sub: var(--btn-sub);
$btn-disabled: var(--btn-disabled);
$btn-disabled-border: var(--btn-disabled-border);
$btn-disabled-txt: var(--btn-disabled-txt);
$btn-shadow: var(--btn-shadow);
$btn-deco: var(--btn-deco);
$btn-txt: var(--btn-txt);
$btn-border-radius: var(--btn-border-radius);
$btn-radius: var(--btn-radius);
$btn-font: var(--btn-font);
$btn-height: var(--btn-height);
$btn-mobile-height: var(--btn-mobile-height);
$btn-width: var(--btn-width);
$btn-change-pass-txt: var(--btn-change-pass-txt);

// CPListTab
$listTab: var(--listTab);
$list-txt: var(--list-txt);
$list-unSelect-text: var(--list-unSelect-text);
$list-underline: var(--list-underline);

// CPDatePicker
$datePickerSelected: var(--datePickerSelected);
$datePickerSelected-shadow: var(--datePickerSelected-shadow);
$datePickerSelected-border: var(--datePickerSelected-border);
$datePickerBorder: var(--datePickerBorder);
$datePickerBg: var(--datePickerBg);
$datePickerTxt: var(--datePickerTxt);
$datePickSelectedTxt: var(--datePickSelectedTxt);
$dateShadow: var(--dateShadow);
$dataUnSelectedShadow: var(--dataUnSelectedShadow);

//CPListing
$listing-border: var(--listing-border);
$listing-shadow: var(--listing-shadow);
$listing-bg: var(--listing-bg);
$listing-item: var(--listing-item);

//CPProgressBar
$progress-bar-container: var(--progress-bar-container);
$progress-bar-shadow: var(--progress-bar-shadow);
$progress-bar-border: var(--progress-bar-border);
$progress-bar-default: var(--progress-bar-default);

//#region =====> Root

//Notif
$notif-shadow: var(--notif-shadow);
$notif-bg: var(--notif-bg);
$notif-msg: var(--notif-msg);
$notif-btn-bg: var(--notif-btn-bg);
$notif-btn-shadow: var(--notif-btn-shadow);
$notif-btn-txt: var(--notif-btn-txt);

//Info
$info-bg: var(--info-bg);
$info-title: var(--info-title);
$info-desc: var(--info-desc);
$info-btn-bg: var(--info-btn-bg);
$info-btn-shadow: var(--info-btn-shadow);
$info-btn-border: var(--info-btn-border);
$info-btn-txt: var(--info-btn-txt);
$dontShow: var(--dontShow);

//Plane
$plane-bg: var(--plane-bg);
$plane-blur: var(--plane-blur);

//Bank
$root-bank-bg: var(--root-bank-bg);
$root-bank-shadow: var(--root-bank-shadow);
$root-bank-border: var(--root-bank-border);
$root-bank-title: var(--root-bank-title);
$root-bank-tips: var(--root-bank-tips);
$root-bank-label: var(--root-bank-label);
$root-bank-input-txt: var(--root-bank-input-txt);
$root-bank-input-placeholder: var(--root-bank-input-placeholder);
$root-bank-input-border: var(--root-bank-input-border);
$root-bank-input-bg: var(--root-bank-input-bg);
$root-bank-input-shadow: var(--root-bank-input-shadow);
$root-bank-btn-bg: var(--root-bank-btn-bg);
$root-bank-btn-shadow: var(--root-bank-btn-shadow);
$root-bank-btn-border: var(--root-bank-btn-border);

//Dialog
$dialog-bg: var(--dialog-bg);
$dialog-shadow: var(--dialog-shadow);
$dialog-border: var(--dialog-border);
$dialog-title-txt: var(--dialog-title-txt);
$dialog-content-txt: var(--dialog-content-txt);
$dialog-err: var(--dialog-err);
$dialog-success: var(--dialog-success);
$dialog-option: var(--dialog-option);
$dialog-btn-txt: var(--dialog-btn-txt);
$dialog-option-bottom: var(--dialog-option-bottom);

//Loading
$loading-bg: var(--loading-bg);

//Toast
$taost-bg: var(--taost-bg);
$taost-txt: var(--taost-txt);
$toast-desc: var(--toast-desc);

//News
$news-bg: var(--news-bg);
$news-border: var(--news-border);
$news-shadow: var(--news-shadow);
$news-close-bg: var(--news-close-bg);
$news-close-border: var(--news-close-border);
$news-close-shadow: var(--news-close-shadow);
$news-close-txt: var(--news-close-txt);
$news-pagination: var(--news-pagination);
$news-pagination-active: var(--news-pagination-active);
$news-title-txt: var(--news-title-txt);
$news-desc-txt: var(--news-desc-txt);
$news-ok-btn-bg: var(--news-ok-btn-bg);
$news-ok-btn-shadow: var(--news-ok-btn-shadow);
$news-ok-btn-border: var(--news-ok-btn-border);
$news-ok-btn-txt: var(--news-ok-btn-txt);

//Feedback
$feedback-container-bg: var(--feedback-container-bg);
$feedback-bg: var(--feedback-bg);
$feedback-border: var(--feedback-border);
$feedback-comment-bg: var(--feedback-comment-bg);
$feedback-comment-border: var(--feedback-comment-border);
$feedback-comment-txt: var(--feedback-comment-txt);
$feedback-submit-bg: var(--feedback-submit-bg);
$feedback-submit-border: var(--feedback-submit-border);
$feedback-submit-shadow: var(---feedback-submit-shadow);
$feedback-submit-disabled-bg: var(--feedback-submit-disabled-bg);
$feedback-submit-disabled-border: var(--feedback-submit-disabled-border);
$feedback-submit-disabled-shadow: var(--feedback-submit-disabled-shadow);
$feedback-submit-txt-disabled: var(--feedback-submit-txt-disabled);
$feedback-submit-txt: var(--feedback-submit-txt);

//#endregion

//#endregion

//#region =====> Club
$club-title: var(--club-title);
//#endregion

//#region =====> Porn
$porn-content-bg: var(--porn-content-bg);
$porn-title: var(--porn-title);
$porn-date: var(--porn-date);
$porn-desc: var(--porn-desc);
//#endregion

//#region =====> Movie
$movie-content-bg: var(--movie-content-bg);
$movie-imdb-rate: var(--movie-imdb-rate);
$movie-btn-bg: var(--movie-btn-bg);
$movie-btn-txt: var(--movie-btn-txt);
$movie-detail-bg: var(--movie-detail-bg);
$movie-bot-border: var(--movie-bot-border);
$movie-rate-blue: var(--movie-rate-blue);
$movie-google-users-txt: var(--movie-google-users-txt);
$movie-desc: var(--movie-desc);
$movie-title: var(--movie-title);
$movie-date: var(--movie-date);
$movie-imdb: var(--movie-imdb);
$movie-popup-title: var(--movie-popup-title);
$movie-imdb-title: var(--movie-imdb-title);
$movie-btm-txt: var(--movie-btm-txt);
//#endregion

//#region =====> Streaming
$streaming-title: var(--streaming-title);
$streaming-title-txt: var(--streaming-title-txt);
$streaming-home-bg: var(--streaming-home-bg);
$streaming-home-shadow: var(--streaming-home-shadow);
$streaming-home-txt: var(--streaming-home-txt);
$streaming-away-txt: var(--streaming-away-txt);
$streaming-score-bg: var(--streaming-score-bg);
$streaming-score-txt: var(--streaming-score-txt);
$streaming-date-bg: var(--streaming-date-bg);
$streaming-date-txt: var(--streaming-date-txt);
$streaming-isLive-bg: var(--streaming-isLive-bg);
$streaming-isLive-txt: var(--streaming-isLive-txt);
$streaming-coming-bg: var(--streaming-coming-bg);
$streaming-coming-txt: var(--streaming-coming-txt);
$streaming-play-isLive-bg: var(--streaming-play-isLive-bg);
$streaming-play-isLive-txt: var(--streaming-play-isLive-txt);
$streaming-watch-txt: var(--streaming-watch-txt);
$streaming-play-coming-bg: var(--streaming-play-coming-bg);
$streaming-play-coming-txt: var(--streaming-play-coming-txt);
//#endregion

//#region =====> Sign In & Sign Up
$signIn-title: var(--signIn-title);
$signIn-underline: var(--signIn-underline);
$signIn-otp-input-txt: var(--signIn-otp-input-txt);
$signIn-otp-underline: var(--signIn-otp-underline);
$signIn-btn: var(--signIn-btn);
$signIn-btn-border: var(--signIn-btn-border);
$signIn-btn-shadow: var(--signIn-btn-shadow);
$signIn-disabled-btn: var(--signIn-disabled-btn);
$signIn-desc: var(--signIn-desc);
$signIn-bg: var(--signIn-bg);
$signIn-input: var(--signIn-input);
$signIn-input-placeholder: var(--signIn-input-placeholder);
$signIn-mobile: var(--signIn-mobile);
$signIn-mobile-separator: var(--signIn-mobile-separator);
$signIn-input-bg: var(--signIn-input-bg);
$signIn-shadow: var(--signIn-shadow);
$signIn-disabled-btn-txt: var(--signIn-disabled-btn-txt);
$signIn-disabled-btn: var(--signIn-disabled-btn);
$signIn-disabled-border: var(--signIn-disabled-border);
$signIn-btn-txt: var(--signIn-btn-txt);
$signIn-help: var(--signIn-help);
$signIn-modal-bg: var(--signIn-modal-bg);
$signIn-modal-title: var(--signIn-modal-title);
$signIn-selected-bg: var(--signIn-selected-bg);
$signIn-selected-txt: var(--signIn-selected-txt);
$signIn-lang-txt: var(--signIn-lang-txt);
$signIn-line-bg: var(--signIn-line-bg);
$signIn-line-shadow: var(--signIn-line-shadow);
$signIn-ver: var(--signIn-ver);

// login with line
$signIn-login-line-bg: var(--signIn-login-line-bg);
$signIn-line-icon-bg: var(--signIn-line-icon-bg);
$signIn-line-label: var(--signIn-line-label);
$signIn-or: var(--signIn-or);
$signIn-or-border: var(--signIn-or-border);

$signUp-title: var(--signUp-title);
$signUp-tips: var(--signUp-tips);
$signUp-prefix: var(--signUp-prefix);
$signUp-mobile: var(--signUp-mobile);
$signUp-verify-btn-bg: var(--signUp-verify-btn-bg);
$signUp-verify-btn-txt: var(--signUp-verify-btn-txt);
$signUp-disabled-verify-btn-txt: var(--signUp-disabled-verify-btn-txt);
$signUp-disabled-verify-btn-bg: var(--signUp-disabled-verify-btn-bg);
$signUp-disabled-verify-btn-border: var(--signUp-disabled-verify-btn-border);
$signUp-verify-btn-shadow: var(--signUp-verify-btn-shadow);
$signUp-verify-btn-border: var(--signUp-verify-btn-border);
$signUp-resend-btn: var(--signUp-resend-btn);
$signUp-resend-btn-shadow: var(--signUp-resend-btn-shadow);
$signUp-timer: var(--signUp-timer);

//#endregion

//#region =====> More

$more-trans-bg: var(--more-trans-bg);
$more-bg: var(--more-bg);
$more-title: var(--more-title);
$more-title-bg: var(--more-title-bg);
$more-item: var(--more-item);
$more-lang-title-txt: var(--more-lang-title-txt);

//#endregion

//#region =====> Profile

$profile-banklist: var(--profile-banklist);
$profile-banklist-shadow: var(--profile-banklist-shadow);
$profile-banklist-txt: var(--profile-banklist-txt);
$profile-downline: var(--profile-downline);
$profile-downline-shadow: var(--profile-downline-shadow);
$profile-downline-txt: var(--profile-downline-txt);
$profile-changePwd: var(--profile-changePwd);
$profile-changePwd-shadow: var(--profile-changePwd-shadow);
$profile-changePwd-txt: var(--profile-changePwd-txt);
$profile-signOut-txt: var(--profile-signOut-txt);
$profile-version: var(--profile-version);
$profile-bg: var(--profile-bg);
$profile-banklist-border: var(--profile-banklist-border);
$profile-downline-border: var(--profile-downline-border);
$profile-changePwd-border: var(--profile-changePwd-border);

//#endregion

//#region =====> Lang

$lang-modal-bg: var(--lang-modal-bg);
$lang-modal-btn: var(--lang-modal-btn);

//#endregion

//#region =====> Mission
$mission-bg: var(--mission-bg);
$mission-more: var(--mission-more);
$mission-badge-bg: var(--mission-badge-bg);
$mission-list-bg: var(--mission-list-bg);
$mission-listing-middle-line: var(--mission-listing-middle-line);
$mission-btn: var(--mission-btn);
$mission-btn-txt: var(--mission-btn-txt);
$mission-status: var(--mission-status);
$mission-status-num: var(--mission-status-num);
$mission-amt-bg: var(--mission-amt-bg);
$mission-amt-shadow: var(--mission-amt-shadow);
$mission-amt-border: var(--mission-amt-border);
$mission-amt-amt: var(--mission-amt-amt);
$mission-amt-title-txt: var(--mission-amt-title-txt);
$mission-listing-border: var(--mission-listing-border);
$mission-reward: var(--mission-reward);
$mission-noProgress-bg: var(--mission-noProgress-bg);
$mission-noProgress-txt: var(--mission-noProgress-txt);
$mission-inProgress-bg: var(--mission-inProgress-bg);
$mission-inProgress-txt: var(--mission-inProgress-txt);
$mission-claimed-bg: var(--mission-claimed-bg);
$mission-claimed-txt: var(--mission-claimed-txt);
$mission-noProgress-shadow: var(--mission-noProgress-shadow);
$mission-noProgress-border: var(--mission-noProgress-border);
$mission-inProgress-shadow: var(--mission-inProgress-shadow);
$mission-inProgress-border: var(--mission-inProgress-border);
$mission-claimed-shadow: var(--mission-claimed-shadow);
$mission-claimed-border: var(--mission-claimed-border);
$mission-label-default: var(--mission-label-default);

//popup
$mission-popup-blur-bg: var(--mission-popup-blur-bg);
$mission-popup-border: var(--mission-popup-border);
$mission-popup-shadow: var(--mission-popup-shadow);
$mission-popup-bg-top: var(--mission-popup-bg-top);
$mission-popup-bg: var(--mission-popup-bg);
$mission-popup-reward-bg: var(--mission-popup-reward-bg);
$mission-popup-title: var(--mission-popup-title);
$mission-popup-txt-shadow: var(--mission-popup-txt-shadow);
$mission-popup-txt-cancel-shadow: var(--mission-popup-txt-cancel-shadow);
$mission-popup-label: var(--mission-popup-label);
$mission-popup-label-item: var(--mission-popup-label-item);
$mission-popup-reward: var(--mission-popup-reward);
$mission-popup-info: var(--mission-popup-info);
$mission-popup-txt: var(--mission-popup-txt);
$mission-gradient: var(--mission-gradient);
$mission-reward-txt: var(--mission-reward-txt);
$mission-popup-activity-container-bg: var(--mission-popup-activity-container-bg);
$mission-popup-activity-container-border: var(--mission-popup-activity-container-border);
$mission-cover-gradient: var(--mission-cover-gradient);
$mission-popup-bottom-container-top-border: var(--mission-popup-bottom-container-top-border);

//record

$mission-record-date: var(--mission-record-date);
$mission-record-txt: var(--mission-record-txt);
$mission-record-status-bg: var(--mission-record-status-bg);

//#endregion

//#region =====> Fund

$fund-progressbar: var(--fund-progressbar);
$fund-btn-bg: var(--fund-btn-bg);
$fund-btn-shadow: var(--fund-btn-shadow);
$fund-btn-border: var(--fund-btn-border);
$fund-btn-bg-disabled: var(--fund-btn-bg-disabled);
$fund-btn-border-disabled: var(--fund-btn-border-disabled);
$fund-btn-txt-disabled: var(--fund-btn-txt-disabled);
$fund-btn-txt: var(--fund-btn-txt);
$fund-withdraw-btn-bg: var(--fund-withdraw-btn-bg);
$fund-withdraw-btn-shadow: var(--fund-withdraw-btn-shadow);
$fund-withdraw-btn-border: var(--fund-withdraw-btn-border);
$fund-withdraw-btn-bg-disabled: var(--fund-withdraw-btn-bg-disabled);
$fund-withdraw-btn-border-disabled: var(--fund-withdraw-btn-border-disabled);
$fund-withdraw-btn-txt-disabled: var(--fund-withdraw-btn-txt-disabled);
$fund-withdraw-btn-txt: var(--fund-withdraw-btn-txt);
$fund-btn-detail-bg: var(--fund-btn-detail-bg);
$fund-btn-detail-shadow: var(--fund-btn-detail-shadow);
$fund-btn-detail-border: var(--fund-btn-detail-border);
$fund-tips-title: var(--fund-tips-title);
$fund-tips-txt: var(--fund-tips-txt);
$fund-tips-border: var(--fund-tips-border);
$fund-tips-shadow: var(--fund-tips-shadow);
$fund-tips-bg: var(--fund-tips-bg);
$fund-act-icon: var(--fund-act-icon);
$fund-card-bg: var(--fund-card-bg);
$fund-card-txt: var(--fund-card-txt);
$fund-bottom-border: var(--fund-bottom-border);
$fund-bottom-shadow: var(--fund-bottom-shadow);
$fund-bottom-bg: var(--fund-bottom-bg);
$fund-bottom-style-bg: var(--fund-bottom-style-bg);
$fund-bottom-txt: var(--fund-bottom-txt);
$fund-tips: var(--fund-tips);
$fund-rebate-bg: var(--fund-rebate-bg);
$fund-rebate-txt: var(--fund-rebate-txt);
$fund-rebate-shadow: var(--fund-rebate-shadow);
$fund-timer-txt: var(--fund-timer-txt);

//#endregion

//#region =====> Comm (mix with fund)

$comm-card-bg: var(--comm-card-bg);
$comm-card-title: var(--comm-card-title);
$comm-card-txt: var(--comm-card-txt);
$comm-card-shadow: var(--comm-card-shadow);
$comm-tips2-txt: var(--comm-tips2-txt);
$comm-btn-shadow: var(--comm-btn-shadow);
$comm-act-icon: var(--comm-act-icon);
$comm-btn-shadow: var(--comm-btn-shadow);
$comm-btn-txt: var(--comm-btn-txt);
$comm-btn-bg: var(--comm-btn-bg);
$comm-btn-border: var(--comm-btn-border);
$comm-btn-shadow: var(--comm-btn-shadow);
$comm-list-shadow: var(--comm-list-shadow);
$comm-summ-title: var(--comm-summ-title);
$comm-summ-green: var(--comm-summ-green);
$comm-summ-orange: var(--comm-summ-orange);
$comm-datePicker-title: var(--comm-datePicker-title);
$comm-datePicker-btn: var(--comm-datePicker-btn);
$comm-datePicker-btn-radius: var(--comm-datePicker-btn-radius);
$comm-datePicker-selected: var(--comm-datePicker-selected);
$comm-datePicker-selected-btn: var(--comm-datePicker-selected-btn);

//#endregion

//#region =====> Comm2Wallet

$comm2wallet-bg: var(--comm2wallet-bg);
$comm2wallet-border: var(--comm2wallet-border);
$comm2wallet-label: var(--comm2wallet-label);
$comm2wallet-remark: var(--comm2wallet-remark);
$comm2wallet-btn-bg: var(--comm2wallet-btn-bg);
$comm2wallet-btn-shadow: var(--comm2wallet-btn-shadow);
$comm2wallet-btn-border: var(--comm2wallet-btn-border);
$comm2wallet-btn-txt: var(--comm2wallet-btn-txt);
$comm2wallet-btn-bg-disabled: var(--comm2wallet-btn-bg-disabled);
$comm2wallet-btn-shadow-disabled: var(--comm2wallet-btn-shadow-disabled);
$comm2wallet-btn-border-disabled: var(--comm2wallet-btn-border-disabled);
$comm2wallet-btn-txt-disabled: var(--comm2wallet-btn-txt-disabled);
$comm2wallet-input-bg: var(--comm2wallet-input-bg);
$comm2wallet-input-shadow: var(--comm2wallet-input-shadow);
$comm2wallet-input-border: var(--comm2wallet-input-border);

//#endregion

//#region =====> Comm2Bank (mix with Comm2Wallet)

$comm2bank-btn-shadow: var(--comm2bank-btn-shadow);
$comm2bank-btn-border: var(--comm2bank-btn-border);
$comm2bank-btn-bg: var(--comm2bank-btn-bg);
$comm2bank-btn-txt: var(--comm2bank-btn-txt);
$comm2bank-btn-shadow-disabled: var(--comm2bank-btn-shadow-disabled);
$comm2bank-btn-border-disabled: var(--comm2bank-btn-border-disabled);
$comm2bank-btn-bg-disabled: var(--comm2bank-btn-bg-disabled);
$comm2bank-btn-txt-disabled: var(--comm2bank-btn-txt-disabled);

//#endregion

//#region =====> Wallet

$wallet-detail-bal: var(--wallet-detail-bal);
$wallet-detail-comm: var(--wallet-detail-comm);
$wallet-radius: var(--wallet-radius);
$wallet-txt: var(--wallet-txt);

//#endregion

//#region =====> Game List

$marquee-txt: var(--marquee-txt);

//#endregion

//#region =====> Game List

$game-container-bg: var(--game-container-bg);
$game-filter-btn-bg: var(--game-filter-btn-bg);
$game-filter-btn-container: var(--game-filter-btn-container);
$game-filter-shadow: var(--game-filter-shadow);
$game-filter-selected: var(--game-filter-selected);
$game-filter-selected-border: var(--game-filter-selected-border);
$game-filter-selected-shadow: var(--game-filter-selected-shadow);
$game-filter-selected-txt: var(--game-filter-selected-txt);
$game-filter-unselect-txt: var(--game-filter-unselect-txt);
$game-filter-disabled-txt: var(--game-filter-disabled-txt);
$game-button-common-shadow: var(--game-button-common-shadow);
$game-provider-bg: var(--game-provider-bg);
$game-provider-bg-shadow: var(--game-provider-bg-shadow);
$game-provider-btn: var(--game-provider-btn);
$game-provider-btn-border: var(--game-provider-btn-border);
$game-provider-btn-shadow: var(--game-provider-btn-shadow);
$game-provider-btn-bg: var(--game-provider-btn-bg);
$game-provider-shadow: var(--game-provider-shadow);
$game-provider-border: var(--game-provider-border);
$game-ipad-size-max-width: var(--game-ipad-size-max-width);
$game-option-bg: var(--game-option-bg);
$game-option-shadow: var(--game-option-shadow);
$game-top-bg: var(--game-top-bg);
$game-top-shadow: var(--game-top-shadow);
$game-overlay-background: var(--game-overlay-background);

//appGame
$app-game-user-info-background: var(--app-game-user-info-background);
$app-game-pw-info-background: var(--app-game-pw-info-background);
$app-game-user-info-text-color: var(--app-game-user-info-text-color);
$app-game-pw-info-text-color: var(--app-game-pw-info-text-color);
$app-game-play-game-button-background: var(--app-game-play-game-button-background);
$app-game-play-game-text: var(--app-game-play-game-text);
$app-game-play-game-button-border: var(--app-game-play-game-button-border);
$app-game-warning-color: var(--app-game-warning-color);
$app-game-alert-background: var(--app-game-alert-background);
$app-game-copy-background: var(--app-game-copy-background);
$app-game-copy-shadow: var(--app-game-copy-shadow);
$app-game-chg-pw-shadow: var(--app-game-chg-pw-shadow);

//#endregion

//#region =====> Play

$play-container-bg: var(--play-container-bg);

//#endregion

//#region =====> Deposit

$auto-title: var(--auto-title);
$auto-info: var(--auto-info);

//#endregion

//#region =====> Deposit

$deposit-render-border: var(--deposit-render-border);
$deposit-render-shadow: var(--deposit-render-shadow);
$deposit-render-bg: var(--deposit-render-bg);
$deposit-title: var(--deposit-title);
$deposit-status: var(--deposit-status);
$deposit-desc: var(--deposit-desc);
$deposit-inactive: var(--deposit-inactive);
$deposit-status-inactive: var(--deposit-status-inactive);

//#endregion

//#region =====> Topup

$topup-cs-txt: var(--topup-cs-txt);
$topup-cs-bg: var(--topup-cs-bg);
$topup-label: var(--topup-label);
$topup-label-amt: var(--topup-label-amt);
$topup-amt-remark: var(--topup-amt-remark);
$topup-cs-btn: var(--topup-cs-btn);
$topup-enter-txt: var(--topup-enter-txt);
$topup-tip-bg: var(--topup-tip-bg);
$topup-tip-title: var(--topup-tip-title);
$topup-tip-txt: var(--topup-tip-txt);
$topup-tip-border: var(--topup-tip-border);
$topup-input-bottom: var(--topup-input-bottom);
$topup-btn-bg: var(--topup-btn-bg);
$topup-btn-shadow: var(--topup-btn-shadow);
$topup-digit: var(--topup-digit);
$topup-amt-btn-border: var(--topup-amt-btn-border);
$topup-amt-btn-shadow: var(--topup-amt-btn-shadow);
$topup-amt-btn-txt: var(--topup-amt-btn-txt);

// discount
$topup-amt-btn: var(--topup-amt-btn);
$topup-amt-selected: var(--topup-amt-selected);
$topup-amt-deco: var(--topup-amt-deco);
$topup-promo-bg: var(--topup-promo-bg);
$topup-promo-txt: var(--topup-promo-txt);
$topup-promo-shadow: var(--topup-promo-shadow);
$topup-promo-selected-bg: var(--topup-promo-selected-bg);
$topup-promo-selected-txt: var(--topup-promo-selected-txt);

// detail
$topup-detail-amt: var(--topup-detail-amt);
$topup-detail-inner-bg: var(--topup-detail-inner-bg);
$topup-detail-inner-shadow: var(--topup-detail-inner-shadow);
$topup-detail-inner-txt: var(--topup-detail-inner-txt);
$topup-detail-item-txt: var(--topup-detail-item-txt);
$topup-detail-enter-label: var(--topup-detail-enter-label);
$topup-detail-enter-border: var(--topup-detail-enter-border);
$topup-detail-tips: var(--topup-detail-tips);
$topup-detail-cancel: var(--topup-detail-cancel);
$topup-detail-cancel-shadow: var(--topup-detail-cancel-shadow);
$topup-detail-home: var(--topup-detail-home);
$topup-detail-home-shadow: var(--topup-detail-home-shadow);
$topup-detail-bank-acct: var(--topup-detail-bank-acct);
//#endregion

//#region =====> Withdraw

$wit-selectbank-bg: var(--wit-selectbank-bg);
$wit-input-txt: var(--wit-input-txt);
$wit-input-placeholder: var(--wit-input-placeholder);
$wit-btn-bg: var(--wit-btn-bg);
$wit-btn-shadow: var(--wit-btn-shadow);
$wit-btn-border: var(--wit-btn-border);
$add-bank-wit-btn-txt: var(--add-bank-wit-btn-txt);
$add-bank-wit-btn-txt-disabled: var(--add-bank-wit-btn-txt-disabled);
$addBank-btn-shadow: var(--addBank-btn-shadow);
$addBank-btn-border: var(--addBank-btn-border);
$addBank-btn-bg: var(--addBank-btn-bg);
$addBank-btn-shadow-disabled: var(--addBank-btn-shadow-disabled);
$addBank-btn-border-disabled: var(--addBank-btn-border-disabled);
$addBank-btn-bg-disabled: var(--addBank-btn-bg-disabled);


//#endregion

//#region =====> Transaction

$trxn-type: var(--trxn-type);
$trxn-amt: var(--trxn-amt);
$trxn-date: var(--trxn-date);
$trxn-approve: var(--trxn-approve);
$trxn-reject: var(--trxn-reject);
$trxn-others: var(--trxn-others);
$trxn-approve-bg: var(--trxn-approve-bg);
$trxn-reject-bg: var(--trxn-reject-bg);
$trxn-others-bg: var(--trxn-others-bg);

// detail
$trxn-detail-bg: var(--trxn-detail-bg);
$trxn-detail-border: var(--trxn-detail-border);
$trxn-detail-header-bottom-border: var(--trxn-detail-header-bottom-border);
$trxn-detail-shadow: var(--trxn-detail-shadow);
$trxn-detail-amt-bg: var(--trxn-detail-amt-bg);
$trxn-detail-approve: var(--trxn-detail-approve);
$trxn-detail-reject: var(--trxn-detail-reject);
$trxn-detail-others: var(--trxn-detail-others);
$trxn-detail-cancel: var(--trxn-detail-cancel);
$trxn-detail-cancel-shadow: var(--trxn-detail-cancel-shadow);

//#endregion

//#region =====> Game Record

$gameRec-date: var(--gameRec-date);
$gameRec-game: var(--gameRec-game);
$gameRec-win: var(--gameRec-win);
$gameRec-lose: var(--gameRec-lose);
$gameRec-draw: var(--gameRec-draw);
$gameRec-winBg: var(--gameRec-winBg);
$gameRec-loseBg: var(--gameRec-loseBg);
$gameRec-drawBg: var(--gameRec-drawBg);
$gameRec-winTxt: var(--gameRec-winTxt);
$gameRec-loseTxt: var(--gameRec-loseTxt);
$gameRec-drawTxt: var(--gameRec-drawTxt);

//#endregion

//#region =====> Game Record

$commRec-date: var(--commRec-date);
$commRec-amt: var(--commRec-amt);
$commRec-type: var(--commRec-type);

//#endregion

//#region =====> Info

$chat-name: var(--chat-name);
$chat-tab-shadow: var(--chat-tab-shadow);
$chat-tab-btn: var(--chat-tab-btn);
$chat-tab-selected-bg: var(--chat-tab-selected-bg);
$chat-listing-border: var(--chat-listing-border);
$chat-listing-bg: var(--chat-listing-bg);
$chat-listing-shadow: var(--chat-listing-shadow);
$info-date: var(--info-date);
$line-txt: var(--line-txt);
$line-btn: var(--line-btn);
$line-btn-border: var(--line-btn-border);
$line-btn-shadow: var(--line-btn-shadow);
$line-btn-txt: var(--line-btn-txt);

//#endregion

//#region =====> Bank List

$bankList-name-border: var(--bankList-name-border);
$bankList-name-shadow: var(--bankList-name-shadow);
$bankList-name: var(--bankList-name);
$bankList-bg: var(--bankList-bg);
$bankList-tips-bg: var(--bankList-tips-bg);
$bankList-tips-border: var(--bankList-tips-border);
$bankList-tips-shadow: var(--bankList-tips-shadow);
$bankList-tips-txt: var(--bankList-tips-txt);
$bankList-style-bg: var(--bankList-style-bg);
$bankList-bankname: var(--bankList-bankname);
$bankList-banknum: var(--bankList-banknum);

//#endregion

//#region =====> Add Bank

$addBank-btn-bg: var(--addBank-btn-bg);

//#endregion

//#region =====> Affiliate

$affiliate-save: var(--affiliate-save);
$affiliate-save-shadow: var(--affiliate-save-shadow);
$affiliate-save-txt: var(--affiliate-save-txt);
$affiliate-share: var(--affiliate-share);
$affiliate-share-shadow: var(--affiliate-share-shadow);
$affiliate-share-border: var(--affiliate-share-border);
$affiliate-share-txt: var(--affiliate-share-txt);
$affiliate-line: var(--affiliate-line);
$affiliate-line-shadow: var(--affiliate-line-shadow);
$affiliate-line-border: var(--affiliate-line-border);
$affiliate-line-txt: var(--affiliate-line-txt);
$affiliate-copy: var(--affiliate-copy);
$affiliate-copy-shadow: var(--affiliate-copy-shadow);
$affiliate-copy-border: var(--affiliate-copy-border);
$affiliate-copy-txt: var(--affiliate-copy-txt);
$affiliate-unsettled-bg: var(--affiliate-unsettled-bg);
$affiliate-unsettled-border: var(--affiliate-unsettled-border);
$affiliate-unsettled-title: var(--affiliate-unsettled-title);
$affiliate-unsettled-date: var(--affiliate-unsettled-date);
$affiliate-downline-bg: var(--affiliate-downline-bg);
$affiliate-downline-txt: var(--affiliate-downline-txt);
$affiliate-downline-border: var(--affiliate-downline-border);
$affiliate-downline-shadow: var(--affiliate-downline-shadow);
$affiliate-report-bg: var(--affiliate-report-bg);
$affiliate-report-txt: var(--affiliate-report-txt);
$affiliate-report-border: var(--affiliate-report-border);
$affiliate-report-shadow: var(--affiliate-report-shadow);
$affiliate-topup-btn-shadow: var(--affiliate-topup-btn-shadow);
$affiliate-topup-btn-bg: var(--affiliate-topup-btn-bg);
$affiliate-topup-btn-border: var(--affiliate-topup-btn-border);
$affiliate-topup-btn-txt: var(--affiliate-topup-btn-txt);
$affiliate-withdraw-btn-shadow: var(--affiliate-withdraw-btn-shadow);
$affiliate-withdraw-btn-bg: var(--affiliate-withdraw-btn-bg);
$affiliate-withdraw-btn-border: var(--affiliate-withdraw-btn-border);
$affiliate-withdraw-btn-txt: var(--affiliate-withdraw-btn-txt);

//#endregion

//#region =====> Downline

$downline-bg-active: var(--downline-bg-active);
$downline-bg-new: var(--downline-bg-new);
$downline-bg-inactive: var(--downline-bg-inactive);
$downline-active: var(--downline-active);
$downline-new: var(--downline-new);
$downline-inactive: var(--downline-inactive);
$downline-avatar-border: var(--downline-avatar-border);
$downline-avatar-bg: var(--downline-avatar-bg);
$downline-avatar-shadow: var(--downline-avatar-shadow);
$downline-tab-bg: var(--downline-tab-bg);
$downline-tab-border: var(--downline-tab-border);
$downline-tab-txt: var(--downline-tab-txt);
$downline-active-txt: var(--downline-active-txt);
$downline-inactive-txt: var(--downline-inactive-txt);
$downline-invite: var(--downline-invite);
$downline-listing-item-bg: var(--downline-listing-item-bg);
$downline-listing-item-border: var(--downline-listing-item-border);
$downline-listing-item-shadow: var(--downline-listing-item-shadow);

//#endregion

//#region =====> Footer

$footer-container: var(--footer-container);
$footer-selected-item-bg: var(--footer-selected-item-bg);
$footer-selected-item-shadow: var(--footer-selected-item-shadow);
$footer-item-txt: var(--footer-item-txt);
$footer-item-selected-txt: var(--footer-item-selected-txt);

//#endregion

//#region =====> Change Password

$changePwd-style-bg: var(--changePwd-style-bg);
$changePwd-style-shadow: var(--changePwd-style-shadow);
$changePwd-label-container: var(--changePwd-label-container);
$changePwd-remark: var(--changePwd-remark);
$changePwd-submit-border: var(--changePwd-submit-border);
$changePwd-submit-shadow: var(--changePwd-submit-shadow);
$changePwd-disable-shadow: var(--changePwd-disable-shadow);
$changePwd-disable-border: var(--changePwd-disable-border);
$changePwd-disable-bg: var(--changePwd-disable-bg);
$changePwd-input-bg: var(--changePwd-input-bg);
$changePwd-txt: var(--changePwd-txt);
$changePwd-signOut-bottom: var(--changePwd-signOut-bottom);

//#endregion

//#region =====> Pin

$pin-title: var(--pin-title);
$pin-txt: var(--pin-txt);

//#endregion

//#region =====> ResetPin

$reset-pin-title: var(--reset-pin-title);
$reset-pin-desc: var(--reset-pin-desc);
$reset-pin-mobile: var(--reset-pin-mobile);
$reset-pin-btn: var(--reset-pin-btn);
$reset-pin-btn-border: var(--reset-pin-btn-border);
$reset-pin-btn-shadow: var(--reset-pin-btn-shadow);
$reset-pin-cancel: var(--reset-pin-cancel);
$reset-pin-btn-txt: var(--reset-pin-btn-txt);

//#endregion

//new added - 25 Jul 2023
$search-text-field-bg: var(--search-text-field-bg);
$search-text-inset-shadow: var(--search-text-inset-shadow);
$search-input-placeholder: var(--search-input-placeholder);
$game-category-bg: var(--game-category-bg);
$wallet-shadow: var(--wallet-shadow);
$wallet-border: var(--wallet-border);
$profile-mobile-txt: var(--profile-mobile-txt);
$profile-container-shadow: var(--profile-container-shadow);
$mission-content-title-txt: var(--mission-content-title-txt);
$mission-content-desc-txt: var(--mission-content-desc-txt);
$mission-btn-border: var(--mission-btn-border);
$root-bank-dialog-bg: var(--root-bank-dialog-bg);
$game-search-input-txt: var(--game-search-input-txt);
$game-search-icon-bg: var(--game-search-icon-bg);
$game-search-icon-shadow: var(--game-search-icon-shadow);
$game-search-icon-border: var(--game-search-icon-border);
$inner-game-back-bg: var(--inner-game-back-bg);
$inner-game-back-border: var(--inner-game-back-border);
$inner-game-back-shadow: var(--inner-game-back-shadow);
$provider-banner-bg: var(--provider-banner-bg);
$provider-banner-shadow: var(--provider-banner-shadow);
$inner-game-back-txt: var(--inner-game-back-txt);
$datePickerTitleTxt: var(--datePickerTitleTxt);
$bankList-style-shadow: var(--bankList-style-shadow);
$chat-tab-bg: var(--chat-tab-bg);
$chat-name-selected: var(--chat-name-selected);
$chat-info-title-txt: var(--chat-info-title-txt);
$comm-lifetime-bg: var(--comm-lifetime-bg);
$comm-lifetime-shadow: var(--comm-lifetime-shadow);
$comm-lifetime-border: var(--comm-lifetime-border);
$comm-lifetime-txt: var(--comm-lifetime-txt);
$affiliate-unsettled-shadow: var(--affiliate-unsettled-shadow);
$affiliate-unsettled-bg: var(--affiliate-unsettled-bg);

//header footer enhancement
$back-nav-bg: var(--back-nav-bg);
$back-nav-back-bg: var(--back-nav-back-bg);
$back-nav-back-border: var(--back-nav-back-border);
$back-nav-back-shadow: var(--back-nav-back-shadow);
$back-nav-back-txt: var(--back-nav-back-txt);
$nav-item-bg: var(--nav-item-bg);


//login new UI
$signIn-disabled-resend-btn: var(--signIn-disabled-resend-btn);
$signIn-disabled-resend-border: var(--signIn-disabled-resend-border);
$signIn-disabled-resend-txt: var(--signIn-disabled-resend-txt);
$signUp-resend-border: var(--signUp-resend-border);
$signUp-resend-txt: var(--signUp-resend-txt);
$content-modal-bg: var(--content-modal-bg);
$forgot-pin-txt: var(--forgot-pin-txt);
$pin-btn-hover-txt: var(--pin-btn-hover-txt);
$get-tmp-pin-btn: var(--get-tmp-pin-btn);

//side menu UI
$side-menu-bg: var(--side-menu-bg);
$side-menu-nav-bg: var(--side-menu-nav-bg);
$side-menu-nav-border: var(--side-menu-nav-border);
$side-menu-nav-shadow: var(--side-menu-nav-shadow);
$side-menu-home-bg: var(--side-menu-home-bg);
$side-menu-home-border: var(--side-menu-home-border);
$side-menu-home-shadow: var(--side-menu-home-shadow);
$side-menu-home-txt: var(--side-menu-home-txt);
$side-menu-brand-txt: var(--side-menu-brand-txt);
$language-flag-dark-border: var(--language-flag-dark-border);
$language-flag-dark-shadow: var(--language-flag-dark-shadow);
$language-flag-light-border: var(--language-flag-light-border);
$language-flag-light-shadow: var(--language-flag-light-shadow);
$language-modal-title: var(--language-modal-title);
$language-modal-line: var(--language-modal-line);
$language-btn-txt: var(--language-btn-txt);
$language-btn-txt-selected: var(--language-btn-txt-selected);
$language-btn-bg: var(--language-btn-bg);
$language-btn-bg-selected: var(--language-btn-bg-selected);
$language-flag-shadow: var(--language-flag-shadow);
$marquee-txt-dark: var(--marquee-txt-dark);
$side-menu-item-txt: var(--side-menu-item-txt);
$side-menu-item-separator: var(--side-menu-item-separator);
$tab-item-bg: var(--tab-item-bg);
$tab-item-selected-bg: var(--tab-item-selected-bg);
$tab-item-selected-shadow: var(--tab-item-selected-shadow);
$tab-item-txt: var(--tab-item-txt);
$tab-item-selected-txt: var(--tab-item-selected-txt);
$title-loading-txt: var(--title-loading-txt);
$statement-item-odd-bg: var(--statement-item-odd-bg);
$statement-item-even-bg: var(--statement-item-even-bg);
$statement-title-txt: var(--statement-title-txt);
$statement-date-txt: var(--statement-date-txt);
$statement-amount-txt: var(--statement-amount-txt);
$statement-status-success-txt: var(--statement-status-success-txt);
$statement-status-progress-txt: var(--statement-status-progress-txt);
$statement-status-fail-txt: var(--statement-status-fail-txt);
$statement-separator-bg: var(--statement-separator-bg);
$report-button-bg: var(--report-button-bg);
$report-button-border: var(--report-button-border);
$report-button-shadow: var(--report-button-shadow);
$report-button-txt: var(--report-button-txt);
$statement-day-button-bg: var(--statement-day-button-bg);
$statement-day-button-txt: var(--statement-day-button-txt);
$pop-up-bg: var(--pop-up-bg);
$pop-up-txt: var(--pop-up-txt);
$pop-up-separator-bg: var(--pop-up-separator-bg);
$side-menu-wallet-bg: var(--side-menu-wallet-bg);
$side-menu-wallet-border: var(--side-menu-wallet-border);
$side-menu-wallet-shadow: var(--side-menu-wallet-shadow);
$side-menu-topup-bg: var(--side-menu-topup-bg);
$side-menu-topup-shadow: var(--side-menu-topup-shadow);
$side-menu-topup-border: var(--side-menu-topup-border);
$side-menu-withdraw-bg: var(--side-menu-withdraw-bg);
$side-menu-withdraw-shadow: var(--side-menu-withdraw-shadow);
$side-menu-withdraw-border: var(--side-menu-withdraw-border);
$side-menu-topup-text: var(--side-menu-topup-text);
$side-menu-withdraw-text: var(--side-menu-withdraw-text);
$sideMenuItem-bg: var(--sideMenuItem-bg);
$sideMenuItem-shadow: var(--sideMenuItem-shadow);
$sideMenuItem-border: var(--sideMenuItem-border);


// Member Fund Card Component 
    // Topup button
    $fundcard-topup-btn-txt-color: var(--fundcard-topup-btn-txt-color);
    $fundcard-topup-btn-box-shadow: var(--fundcard-topup-btn-box-shadow);
    $fundcard-topup-btn-box-border: var(--fundcard-topup-btn-box-border);
    $fundcard-topup-btn-background: var(--fundcard-topup-btn-background);

    // Withdraw button
    $fundcard-withdraw-btn-txt-color: var(--fundcard-withdraw-btn-txt-color);
    $fundcard-withdraw-btn-box-shadow: var(--fundcard-withdraw-btn-box-shadow);
    $fundcard-withdraw-btn-box-border: var(--fundcard-withdraw-btn-box-border);
    $fundcard-withdraw-btn-background: var(--fundcard-withdraw-btn-background);

    // Transaction List button
    $fundcard-rebate-btn-txt-color: var(--fundcard-rebate-btn-txt-color);
    $fundcard-rebate-btn-box-shadow: var(--fundcard-rebate-btn-box-shadow);
    $fundcard-rebate-btn-background: var(--fundcard-rebate-btn-background);


  //game flipped info
  $game-flipped-small-logo-shadow: var(--game-flipped-small-logo-shadow);
  $game-flipped-bg: var(--game-flipped-bg);
  $game-flipped-title-txt: var(--game-flipped-title-txt);
  $game-flipped-value-txt: var(--game-flipped-value-txt);
  $game-flipped-separator-bg: var(--game-flipped-separator-bg);
  $game-flipped-close-shadow: var(--game-flipped-close-shadow);
  $game-info-shadow: var(--game-info-shadow);

  //pagination
  $button-pagination-button-shadow: var(--button-pagination-button-shadow);
  $button-pagination-button-bg: var(--button-pagination-button-bg);
  $button-pagination-page-indicator-bg: var(--button-pagination-page-indicator-bg);
  $button-pagination-page-indicator-shadow: var(--button-pagination-page-indicator-shadow);
  $button-pagination-page-indicator-border: var(--button-pagination-page-indicator-border);
  $button-pagination-page-indicator-txt: var(--button-pagination-page-indicator-txt);

  //custom scroll bar
  $scrollbar-track: var(--scrollbar-track);
  $scrollbar-thumb: var(--scrollbar-thumb);

  //login component
  $login-component-register-btn: var(--login-component-register-btn);
  $login-component-register-border: var(--login-component-register-border);
  $login-component-login-btn: var(--login-component-login-btn);
  $login-component-login-border: var(--login-component-login-border);
  $login-component-register-txt: var(--login-component-register-txt);
  $login-component-login-txt: var(--login-component-login-txt);
  $login-component-line-btn: var(--login-component-line-btn);
  $login-component-line-txt: var(--login-component-line-txt);
  $login-component-register-shadow: var(--login-component-register-shadow);
  $login-component-login-shadow: var(--login-component-login-shadow);
  $login-component-line-shadow: var(--login-component-line-shadow);
  $login-component-line-border: var(--login-component-line-border);
  $login-component-line-img-bg: var(--login-component-line-img-bg);
  $login-component-separator: var(--login-component-separator);
  $login-component-separator-txt: var(--login-component-separator-txt);

  //intro download app component
  $introDownloadApp-background: var(--introDownloadApp-background);
  $introDownloadApp-btn-shadow: var(--introDownloadApp-btn-shadow);
  $introDownloadApp-btn: var(--introDownloadApp-btn);
  $introDownloadApp-btn-txt: var(--introDownloadApp-btn-txt);
  $introDownloadApp-description-txt: var(--introDownloadApp-description-txt);

  //intro all games component
  $introAllGames-play-btn: var(--introAllGames-play-btn);
  $introAllGames-play-btn-txt: var(--introAllGames-play-btn-txt);
  $introAllGames-play-btn-shadow: var(--introAllGames-play-btn-shadow);
  $introAllGames-play-btn-border: var(--introAllGames-play-btn-border);
  $introAllGames-title-txt: var(--introAllGames-title-txt);

  //intro promotion component
  $introPromotion-view-btn: var(--introPromotion-view-btn);
  $introPromotion-view-btn-txt: var(--introPromotion-view-btn-txt);
  $introPromotion-view-btn-shadow: var(--introPromotion-view-btn-shadow);
  $introPromotion-view-btn-border: var(--introPromotion-view-btn-border);
  $introPromotion-title-txt: var(--introPromotion-title-txt);
  $introPromotion-info-bg: var(--introPromotion-info-bg);
  $introPromotion-content-title: var(--introPromotion-content-title);
  $introPromotion-content-subTitle: var(--introPromotion-content-subTitle);
  $introPromotion-content-description: var(--introPromotion-content-description);
  $introPromotion-content-info: var(--introPromotion-content-info);
  $introPromotion-more-btn: var(--introPromotion-more-btn);
  $introPromotion-more-btn-shadow: var(--introPromotion-more-btn-shadow);
  $introPromotion-more-btn-border: var(--introPromotion-more-btn-border);
  $introPromotion-more-btn-txt: var(--introPromotion-more-btn-txt);

  //intro affiliate component
  $introAffiliate-more-btn: var(--introAffiliate-more-btn);
  $introAffiliate-more-btn-txt: var(--introAffiliate-more-btn-txt);
  $introAffiliate-more-btn-shadow: var(--introAffiliate-more-btn-shadow);
  $introAffiliate-more-btn-border: var(--introAffiliate-more-btn-border);
  $introAffiliateItem-title-txt: var(--introAffiliateItem-title-txt);
  $introAffiliateItem-title-shadow: var(--introAffiliateItem-title-shadow);
  $introAffiliateItem-desc-txt:var(--introAffiliateItem-desc-txt);
  $introAffiliateItem-btn-txt:var(--introAffiliateItem-btn-txt);
  $introAffiliateItem-btn:var(--introAffiliateItem-btn);
  $introAffiliateItem-btn-shadow:var(--introAffiliateItem-btn-shadow);
  $introAffiliateItem-btn-border:var(--introAffiliateItem-btn-border);
  $introAffiliateItem-separator: var(--introAffiliateItem-separator);

  //intro club component
  $introClub-more-btn: var(--introClub-more-btn);
  $introClub-more-btn-txt: var(--introClub-more-btn-txt);
  $introClub-more-btn-shadow: var(--introClub-more-btn-shadow);
  $introClub-more-btn-border: var(--introClub-more-btn-border);
  $introClub-title-txt: var(--introClub-title-txt);
  $introClubItem-title-txt: var(--introClubItem-title-txt);
  $introClubItem-title-shadow: var(--introClubItem-title-shadow);
  $introClubItem-desc-txt: var(--introClubItem-desc-txt);
  $introClubItem-desc-shadow: var(--introClubItem-desc-shadow);
  $introClubItem-join-btn: var(--introClubItem-join-btn);
  $introClubItem-join-btn-txt: var(--introClubItem-join-btn-txt);
  $introClubItem-join-btn-shadow: var(--introClubItem-join-btn-shadow);
  $introClubSubItem-title-txt: var(--introClubSubItem-title-txt);
  $introClubSubItem-title-shadow: var(--introClubSubItem-title-shadow);
  $introClubSubItem-btn-txt:var(--introClubSubItem-btn-txt);
  $introClubSubItem-btn:var(--introClubSubItem-btn);
  $introClubSubItem-btn-shadow:var(--introClubSubItem-btn-shadow);
  $introClubSubItem-btn-border:var(--introClubSubItem-btn-border);
  $introClubSubItem-separator: var(--introClubSubItem-separator);

  //intro page
  $intro-separator-bg: var(--intro-separator-bg);
  $intro-bottom-info-bg: var(--intro-bottom-info-bg);

  //fund card component
  $intro-fund-card-bg: var(--intro-fund-card-bg);
  $intro-fund-card-border: var(--intro-fund-card-border);
  $intro-fund-card-shadow: var(--intro-fund-card-shadow);
  $intro-fund-card-name: var(--intro-fund-card-name);
  $intro-fund-card-bal-title: var(--intro-fund-card-bal-title);
  $intro-fund-card-bal-value: var(--intro-fund-card-bal-value);
  $intro-fund-card-withdraw-bal-value: var(--intro-fund-card-withdraw-bal-value);
  //side menu fund card component
  $sideMenu-fund-card-name: var(--sideMenu-fund-card-name);
  $sideMenu-fund-card-bal-title: var(--sideMenu-fund-card-bal-title);
  $sideMenu-fund-card-bal-value: var(--sideMenu-fund-card-bal-value);
  $sideMenu-fund-card-withdraw-bal-value: var(--sideMenu-fund-card-withdraw-bal-value);
  //back nav component
  $backNav-title: var(--backNav-title);

  //auto deposit
  $autoDeposit-separator: var(--autoDeposit-separator);

  //games log
  $gamesLog-item-odd-bg: var(--gamesLog-item-odd-bg);
  $gamesLog-item-even-bg: var(--gamesLog-item-even-bg);
  $gamesLog-title-txt: var(--gamesLog-title-txt);
  $gamesLog-date-txt: var(--gamesLog-date-txt);
  $gamesLog-turnover-title: var(--gamesLog-turnover-title);
  $gamesLog-turnover-txt: var(--gamesLog-turnover-txt);
  $gamesLog-winlost-title: var(--gamesLog-winlost-title);
  $gamesLog-winlost-txt: var(--gamesLog-winlost-txt);
  $gamesLog-status-success-txt: var(--gamesLog-status-success-txt);
  $gamesLog-status-progress-txt: var(--gamesLog-status-progress-txt);
  $gamesLog-status-fail-txt: var(--gamesLog-status-fail-txt);
  $gamesLog-separator-bg: var(--gamesLog-separator-bg);
  $gamesLog-day-button-bg: var(--gamesLog-day-button-bg);
  $gamesLog-day-button-txt: var(--gamesLog-day-button-txt);

  //base text field
  $baseTextField-input: var(--baseTextField-input);
  $baseTextField-input-placeholder: var(--baseTextField-input-placeholder);
  $baseTextField-modal-input: var(--baseTextField-modal-input);
  $baseTextField-modal-input-placeholder: var(--baseTextField-modal-input-placeholder);

  //textfield
  $textField-title: var(--textField-title);
  $textField-separator: var(--textField-separator);
  $textField-modal-title: var(--textField-modal-title);
  $textField-modal-separator: var(--textField-modal-separator);
  $textField-err-msg: var(--textField-err-msg);

  //new bank modal
  $newBankModal-title: var(--newBankModal-title);
  $newBankModal-hint-txt: var(--newBankModal-hint-txt);
  $newBankModal-btn-add-bg: var(--newBankModal-btn-add-bg);
  $newBankModal-btn-add-txt: var(--newBankModal-btn-add-txt);

  //Pin Textfield
  $pinItem-bg: var(--pinItem-bg);
  $pinItem-txt: var(--pinItem-txt);
  $pinItem-normal-border: var(--pinItem-normal-border);
  $pinItem-err-border: var(--pinItem-err-border);
  $pinItem-focus-border: var(--pinItem-focus-border);
  $pinTextField-err-txt: var(--pinTextField-err-txt);

  //login Textfield
  $loginCommonTf-bg: var(--loginCommonTf-bg);
  $loginCommonTf-txt: var(--loginCommonTf-txt);
  $loginCommonTf-err-color: var(--loginCommonTf-err-color);
  $loginCommonTf-err-border: var(--loginCommonTf-err-border);
  $loginCommonTf-focus-border: var(--loginCommonTf-focus-border);
  $loginCommonTf-placeholder-txt: var(--loginCommonTf-placeholder-txt);

  //loginMain tab
  $loginMain-multiTab-bg: var(--loginMain-multiTab-bg);

  //remember component
  $remember-txt: var(--remember-txt);

  //login
  $login-forgot-pw-txt: var(--login-forgot-pw-txt);
  $login-separator: var(--login-separator);

  //third party login
  $thirdPartyLogin-border: var(--thirdPartyLogin-border);
  $thirdPartyLogin-bg: var(--thirdPartyLogin-bg);
  $thirdPartyLogin-shadow: var(--thirdPartyLogin-shadow);

  //ButtonComponent_v2
  $buttonComponent_v2-main-txt: var(--buttonComponent_v2-main-txt);
  $buttonComponent_v2-main-shadow: var(--buttonComponent_v2-main-shadow);
  $buttonComponent_v2-main-bg: var(--buttonComponent_v2-main-bg);
  $buttonComponent_v2-main-border: var(--buttonComponent_v2-main-border);
  $buttonComponent_v2-main-disabled-bg: var(--buttonComponent_v2-main-disabled-bg);
  $buttonComponent_v2-main-disabled-txt: var(--buttonComponent_v2-main-disabled-txt);
  $buttonComponent_v2-main-disabled-shadow: var(--buttonComponent_v2-main-disabled-shadow);

  //verify mobile
  $verifyMobile-title: var(--verifyMobile-title);
  $verifyMobile-otp-des: var(--verifyMobile-otp-des);
  $verifyMobile-otp-code: var(--verifyMobile-otp-code);
  $verifyMobile-otp-code-bg: var(--verifyMobile-otp-code-bg);
  $verifyMobile-resend-shadow: var(---verifyMobile-resend-shadow); // start from here
  $verifyMobile-resend-bg: var(--verifyMobile-resend-bg);
  $verifyMobile-resend-main-txt: var(--verifyMobile-resend-main-txt);
  $verifyMobile-resend-border: var(--verifyMobile-resend-border);
  $verifyMobile-resend-disabled-bg: var(--verifyMobile-resend-disabled-bg);
  $verifyMobile-resend-disabled-txt: var(--verifyMobile-resend-disabled-txt);
  $verifyMobile-resend-disabled-shadow: var(--verifyMobile-resend-disabled-shadow);

  //timer
  $timer-txt-main: var(--timer-txt-main);

  //login mobile tf
  $loginMobileTf-prefix-separator: var(--loginMobileTf-prefix-separator);

  //bank listing item
  $bankListingItem-bank-txt: var(--bankListingItem-bank-txt);
  $bankListingItem-separator: var(--bankListingItem-separator);

  //bank drop down border tf
  $bankDropdownBorderTf-border: var(--bankDropdownBorderTf-border);
  $bankDropdownBorderTf-bg: var(--bankDropdownBorderTf-bg);
  $bankDropdownBorderTf-placeholder-txt: var(--bankDropdownBorderTf-placeholder-txt);
  $bankDropdownBorderTf-value-txt: var(--bankDropdownBorderTf-value-txt);
  $bankDropdownBorderTf-popup-title-txt: var(--bankDropdownBorderTf-popup-title-txt);
  $bankDropdownBorderTf-popup-bg: var(--bankDropdownBorderTf-popup-bg);
  $bankDropdownBorderTf-popup-shadow: var(--bankDropdownBorderTf-popup-shadow);
  $bankDropdownBorderTf-popup-separator: var(--bankDropdownBorderTf-popup-separator);

  //register bank
  $registerBank-separator: var(--registerBank-separator);

  //change language txt
  $change-lang-item-txt: var(--change-lang-item-txt);

  //slide indicator
  $slideIndicator-bg: var(--slideIndicator-bg);
  $slideIndicator-active-bg: var(--slideIndicator-active-bg);

  //subHeader
  $subheader-title-txt: var(--subheader-title-txt);

  //brand about
  $brandAbout-separator: var(--brandAbout-separator);
  $brandAbout-brand-txt: var(--brandAbout-brand-txt);
  $brandAbout-info-txt: var(--brandAbout-info-txt);

  //license certification component
  $licenseCert-title: var(--licenseCert-title);

  //license certification authentic component
  $licenseCertAuth-title: var(--licenseCertAuth-title);

  //join community component
  $joinCommunity-title: var(--joinCommunity-title);

  //payment supported component
  $paymentSupported-title: var(--paymentSupported-title);

  //sitemap
  $siteMap-top-separator: var(--siteMap-top-separator);
  $siteMap-bottom-separator: var(--siteMap-bottom-separator);
  $siteMap-title-txt: var(--siteMap-title-txt);
  $siteMap-desc-txt: var(--siteMap-desc-txt);
  $siteMap-aboutUs-desc: var(--siteMap-aboutUs-desc);
  
  //copyright
  $copyright-txt: var(--copyright-txt);

  //modal bg
  $enterPin-modal-bg: var(--enterPin-modal-bg);
  $language-modal-bg: var(--language-modal-bg);
  $lineVerifiedMobile-modal-bg: var(--lineVerifiedMobile-modal-bg);
  $resetPin-modal-bg: var(--resetPin-modal-bg);
  $setPin-modal-bg: var(--setPin-modal-bg);
  $signIn-modal-bg: var(--signIn-modal-bg);
  $signUp-modal-bg: var(--signUp-modal-bg);
  $enterPin-modal-shadow: var(--enterPin-modal-shadow);
  $enterPin-modal-border: var(--enterPin-modal-border);
  $language-modal-shadow: var(--language-modal-shadow);
  $language-modal-border: var(--language-modal-border);
  $lineVerifiedMobile-modal-shadow: var(--lineVerifiedMobile-modal-shadow);
  $lineVerifiedMobile-modal-border: var(--lineVerifiedMobile-modal-border);
  $resetPin-modal-shadow: var(--resetPin-modal-shadow);
  $resetPin-modal-border: var(--resetPin-modal-border);
  $setPin-modal-shadow: var(--setPin-modal-shadow);
  $setPin-modal-border: var(--setPin-modal-border);
  $signIn-modal-shadow: var(--signIn-modal-shadow);
  $signIn-modal-border: var(--signIn-modal-border);
  $signUp-modal-shadow: var(--signUp-modal-shadow);
  $signUp-modal-border: var(--signUp-modal-border);

  //scrollbar
  $mission-popup-scrollbar-thumb: var(--mission-popup-scrollbar-thumb);
  $mission-popup-scrollbar-track: var(--mission-popup-scrollbar-track);
  $mission-popup-scrollbar-thumb-hover: var(--mission-popup-scrollbar-thumb-hover);
  $news-content-scrollbar-thumb: var(--news-content-scrollbar-thumb);
  $news-content-scrollbar-track: var(--news-content-scrollbar-track);
  $news-content-scrollbar-thumb-hover: var(--news-content-scrollbar-thumb-hover);

  //true wallet
  $trueWalletRegister-title: var(--trueWalletRegister-title);
  $trueWalletRegister-desc: var(--trueWalletRegister-desc);
  $trueWalletDeposit-transfer-text: var(--trueWalletDeposit-transfer-text);
  $trueWalletDeposit-acc-name-opt: var(--trueWalletDeposit-display-acc-name-opt);
  $trueWalletDeposit-acc-num-opt: var(--trueWalletDeposit-display-acc-num-opt);
  $trueWalletDeposit-enter-amt-text: var(--trueWalletDeposit-enter-amt-text);
  $trueWalletDeposit-min-amt-text: var(--trueWalletDeposit-min-amt-text);
  $trueWalletDeposit-min-amt-bold-text: var(--trueWalletDeposit-min-amt-bold-text);

  //amount selector text field
  $amountSelectorTf-input-text: var(--amountSelectorTf-input-text);
  $amountSelectorTf-input-bg: var(--amountSelectorTf-input-bg);
  $amountSelectorTf-input-border: var(--amountSelectorTf-input-border);
  $amountSelectorTf-input-border-err: var(--amountSelectorTf-input-border-err);
  $amountSelectorTf-selected-txt: var(--amountSelectorTf-selected-txt);
  $amountSelectorTf-selected-bg: var(--amountSelectorTf-selected-bg);
  $amountSelectorTf-selected-border: var(--amountSelectorTf-selected-border);
  $amountSelectorTf-selected-shadow: var(--amountSelectorTf-selected-shadow);
  $amountSelectorTf-opt-txt: var(--amountSelectorTf-opt-txt);
  $amountSelectorTf-opt-bg: var(--amountSelectorTf-opt-bg);
  $amountSelectorTf-opt-border: var(--amountSelectorTf-opt-border);
  $amountSelectorTf-opt-shadow: var(--amountSelectorTf-opt-shadow);
  $amountSelectorTf-err-msg: var(--amountSelectorTf-err-msg);

  //trueAccountDDtf
  $trueAccountDdTf-border: var(--trueAccountDdTf-border);
  $trueAccountDdTf-border-err: var(--trueAccountDdTf-border-err);
  $trueAccountDdTf-shadow: var(--trueAccountDdTf-shadow);
  $trueAccountDdTf-bg: var(--trueAccountDdTf-bg);
  $trueAccountDdTf-name: var(--trueAccountDdTf-name);
  $trueAccountDdTf-accNum: var(--trueAccountDdTf-accNum);
  $trueAccountDdTf-item-separator: var(--trueAccountDdTf-item-separator);
  $trueAccountDdTf-err-msg: var(--trueAccountDdTf-err-msg);
  $trueAccountDdTf-placeholder: var(--trueAccountDdTf-placeholder);
  $trueAccountDdTf-add-acc-txt: var(--trueAccountDdTf-add-acc-txt);
  $trueAccountDdTf-add-acc-shadow: var(--trueAccountDdTf-add-acc-shadow);
  $trueAccountDdTf-add-acc-bg: var(--trueAccountDdTf-add-acc-bg);
  $trueAccountDdTf-add-acc-border: var(--trueAccountDdTf-add-acc-border);
  $trueAccountDdTf-edit-acc-txt: var(--trueAccountDdTf-edit-acc-txt);
  $trueAccountDdTf-edit-acc-shadow: var(--trueAccountDdTf-edit-acc-shadow);
  $trueAccountDdTf-edit-acc-bg: var(--trueAccountDdTf-edit-acc-bg);
  $trueAccountDdTf-edit-acc-border: var(--trueAccountDdTf-edit-acc-border);
  $trueAccountDdTf-cancel-acc-txt: var(--trueAccountDdTf-cancel-acc-txt);
  $trueAccountDdTf-cancel-acc-shadow: var(--trueAccountDdTf-cancel-acc-shadow);
  $trueAccountDdTf-cancel-acc-bg: var(--trueAccountDdTf-cancel-acc-bg);
  $trueAccountDdTf-cancel-acc-border: var(--trueAccountDdTf-cancel-acc-border);
  $trueAccountDdTf-cfm-acc-txt: var(--trueAccountDdTf-cfm-acc-txt);
  $trueAccountDdTf-cfm-acc-shadow: var(--trueAccountDdTf-cfm-acc-shadow);
  $trueAccountDdTf-cfm-acc-bg: var(--trueAccountDdTf-cfm-acc-bg);
  $trueAccountDdTf-cfm-acc-border: var(--trueAccountDdTf-cfm-acc-border);

  //twalleteditTextfield
  $tWalletEditTf-bg: var(--tWalletEditTf-bg);
  $tWalletEditTf-border: var(--tWalletEditTf-border);
  $tWalletEditTf-shadow: var(--tWalletEditTf-shadow);
  $tWalletEditTf-txt: var(--tWalletEditTf-txt);
  $tWalletEditTf-err-color: var(--tWalletEditTf-err-color);
  $tWalletEditTf-err-border: var(--tWalletEditTf-err-border);
  $tWalletEditTf-placeholder-txt: var(--tWalletEditTf-placeholder-txt);

  //login prohibition
  $loginProhibitionConfig-title: var(--loginProhibitionConfig-title); 
  $loginProhibitionConfig-desc: var(--loginProhibitionConfig-desc); 

  //prohibition drop down
  $prohibitionDropDown-bg: var(--prohibitionDropDown-bg);
  $prohibitionDropDown-border: var(--prohibitionDropDown-border);
  $prohibitionDropDown-shadow: var(--prohibitionDropDown-shadow);
  $prohibitionDropDown-input-color: var(--prohibitionDropDown-input-color);
  $prohibitionDropDown-input-placeholder: var(--prohibitionDropDown-input-placeholder);

  //verifyGoogleAuthModal 
  $verifyGoogleAuthModal-bg: var(--verifyGoogleAuthModal-bg);
  $verifyGoogleAuthModal-shadow: var(--verifyGoogleAuthModal-shadow);
  $verifyGoogleAuthModal-border: var(--verifyGoogleAuthModal-border);
  $verifyGoogleAuthModal-title: var(--verifyGoogleAuthModal-title);
  $verifyGoogleAuthModal-sub-title: var(--verifyGoogleAuthModal-sub-title);

  //securityMain
  $securityMain-title: var(--securityMain-title);

  //SwitchButton
  $switchButton-bg: var(--switchButton-bg);
  $switchButton-shadow: var(--switchButton-shadow);
  $switchButton-border: var(--switchButton-border);
  $switchButton-bg-active: var(--switchButton-bg-active);
  $switchButton-thumb-bg: var(--switchButton-thumb-bg);
  $switchButton-thumb-shadow: var(--switchButton-thumb-shadow);
  $switchButton-thumb-border: var(--switchButton-thumb-border);

  //GoogleTwoFASwitchButton
  $googleTwoFASwitchButton-txt: var(--googleTwoFASwitchButton-txt);

  //googleAuth Pin Item
  $pinItem-focus-googleAuth-border: var(--pinItem-focus-googleAuth-border);
  $pinItem-googleAuth-bg: var(--pinItem-googleAuth-bg);
  $pinItem-googleAuth-shadow: var(--pinItem-googleAuth-shadow);
  $pinItem-googleAuth-border: var(--pinItem-googleAuth-border);
  $pinItem-googleAuth-input-txt: var(--pinItem-googleAuth-input-txt);

  //setup google auth
  $setupGoogleAuth-title: var(--setupGoogleAuth-title);
  $setupGoogleAuth-desc: var(--setupGoogleAuth-desc);
  $setupGoogleAuth-sub-desc: var(--setupGoogleAuth-sub-desc);
  
  //change Password modal
  $changePass-cancel-btn-background: var(--changePass-cancel-btn-background);
  $changePass-cancel-btn-border: var(--changePass-cancel-btn-border);

//#region =====> export
:export {
  mainBg: $main-bg;
  GameContainerBg: $game-container-bg;
  GameFilterBtnContainer: $game-filter-btn-container;
  GameFilterShadow: $game-filter-shadow;
  GameButtonCommonShadow: $game-button-common-shadow;
  AppGameUserInfoBackground: $app-game-user-info-background;
  AppGamePwInfoBackground: $app-game-pw-info-background;
  AppGameUserInfoTextColor: $app-game-user-info-text-color;
  AppGamePwInfoTextColor: $app-game-pw-info-text-color;
  FontSize16: $fontSize16;
  FontSize20: $fontSize20;
  MainTxt: $main-txt;
  AppGamePlayGameBackground: $app-game-play-game-button-background;
  MainBorder: $main-border;
  AppGamePlayGameBtnBorder: $app-game-play-game-button-border;
  AppGameWarningColor: $app-game-warning-color;
  AppGameAlertBackground: $app-game-alert-background;
  AppGamePlayGameText: $app-game-play-game-text;
  SubTxt: $sub-txt;
  BtnDisabledTxt: $btn-disabled-txt;
  BtnChangePassTxt: $btn-change-pass-txt;

  //new added 25 jul 2023
  GameFilterSelected: $game-filter-selected;
  GameSearchIconBg: $game-search-icon-bg;
  GameSearchIconShadow: $game-search-icon-shadow;
  GameSearchIconBorder: $game-search-icon-border;
}
//#endregion

